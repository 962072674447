import './timePicker.css';

const TimePicker = ({name, time, getTime, validation}) => {
    const pickDate = (e) => {
        getTime((time) => ({...time, [name]: e.target.value}));
    }
    return (
        <div className={`time-picker-wrapper ${!validation ? 'not-valid' : ''}`}>
            <label htmlFor={name} >Time <span>*</span></label>
            <input onChange={(e) => pickDate(e)} type='time' name={name} value={time ? time : ""}/>
        </div>
    )
}

export default TimePicker;