import { useContext, useEffect, useState } from 'react';
import '../../assets/css/Heatmap/heatmapModal.css';
import HeatmapModal from '../Modal/HeatmapModal/HeatmapModal';
import HeatmapActions from './Actions/HeatmapActions';
import Loader from './Loader/Loader';
import { AppContext } from '../../Context/Context';
import { clearLocalStorageItem, getLocalStorageItem, reloadApp } from '../../util/helper/helperFunctions';
import ProgressBar from './ProgressBar/ProgressBar';

const Heatmap = ({closeModal}) => {
    const {loaderText, setLoaderText} = useContext(AppContext);
    const [enableProgressBar, setEnableProgressBar] = useState(false);
    const {timeRangePopup, setTimeRangePopup} = useContext(AppContext);
    const {isHeatmapDataAvailable, setIsHeatmapDataAvailable} = useContext(AppContext);
    useEffect(() => {
        document.body.classList.add('prevent-scroll');
        return () => {
            setLoaderText('Loading Heatmap...');
            clearLocalStorageItem('emptyGridData');
        }
    }, []);

    const closeModalHandler = () => {
        closeModal();
        if(!isHeatmapDataAvailable) {
            setTimeRangePopup(true);
        }
    }

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('heatmap-modal-id')) {
            closeModalHandler();
            document.body.classList.remove('prevent-scroll');
            reloadApp();
        }
    })

    return (
        <div id='heatmap-modal-id' className="heatmap-modal-wrapper">
            <div className="heatmap-modal-container">
                <HeatmapActions closeModal={closeModalHandler}/>
                <Loader>
                    <HeatmapModal/>
                </Loader>
            </div>
        </div>
    )
}

export default Heatmap;