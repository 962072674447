import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../Context/Context';
import { toPng } from 'html-to-image';
import { Tooltip } from 'antd';

import '../../../assets/css/Heatmap/heatmapModal.css';
import closeBtn from '../../../assets/icons/close.png';
import screenshotBtn from '../../../assets/icons/screenshot.png';
import { getLocalStorageItem } from '../../../util/helper/helperFunctions';

const HeatmapActions = ({closeModal}) => {
    const {heatmapLoader, setHeatmapLoader} = useContext(AppContext);
    const {loaderText, setLoaderText} = useContext(AppContext);
    const {heatmapRef, setHeatmapRef} = useContext(AppContext);
    const [heatmapLocalRef, setHeatmapLocalRef] = useState(null);
    const {convertedHeatmapData, setConvertedHeatmapData} = useContext(AppContext);

    useEffect(() => {
        if(heatmapRef !== null) {
            setHeatmapLocalRef(heatmapRef);
        }
    }, [heatmapRef])

    const closeModalWrapper = () => {
        closeModal();
        setConvertedHeatmapData(null);
        document.body.classList.remove('prevent-scroll');
    }

    const takeScreenshot = () => {
        let locationCode = getLocalStorageItem('locationCode');
        setHeatmapLoader(true);
        setLoaderText('Saving Screenshot...');
        toPng(heatmapLocalRef.current, { cacheBust: false })
        .then((dataUrl) => {
            let link = document.createElement('a');
            link.download = `${locationCode}_Heatmap_Image.png`;
            link.href = dataUrl;
            link.click();
            setHeatmapLoader(false);
        })
        .catch((err) => {
            setLoaderText('Error on saving Screenshot...');
        })
    }

    return (
        <div className='floating-heatmap-options'>
            <div className='float-options-right-section'>
                {heatmapLocalRef !== null && !heatmapLoader? 
                    <Tooltip title='Take Screenshot'>
                        <div onClick={() => takeScreenshot()} className='camera-btn' id='camera-btn'>
                            <img src={screenshotBtn} alt='Camera button'/>
                        </div>
                    </Tooltip >
                : null}
                <div onClick={() => closeModalWrapper()} className='close-btn' id='close-btn'>
                    <img src={closeBtn} alt='close button'/>
                </div>
            </div>
        </div>
    )
}

export default HeatmapActions;