import '../../../assets/css/TimeRange/timeRangePopup.css';
import Button from '../../Reusables/Button/Button';
import Close from '../../../assets/icons/close.png';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../Context/Context';
import DatePicker from '../../Reusables/DatePicker/DatePicker';
import TimePicker from '../../Reusables/TimePicker/TimePicker';
import moment from 'moment';
import { setLocalStorageItem } from '../../../util/helper/helperFunctions';

const TimeRangePopup = () => {
    const {timeRangePopup, setTimeRangePopup} = useContext(AppContext);

    const {timeMachineTimeFrames, setTimeMachineTimeFrames} = useContext(AppContext);
    const {heatmapTimeFrames, setHeatmapTimeFrames} = useContext(AppContext);
    const {timeMachineModeIsEnabled, setTimeMachineModeIsEnabled} = useContext(AppContext);
    const {isTimeMachineDataAvailable, setIsTimeMachineDataAvailable} = useContext(AppContext);
    const {isHeatmapModeEnabled, setIsHeatmapModeEnabled} = useContext(AppContext);
    const {currentPageCount, setCurrentPageCount} = useContext(AppContext);
    const {timeMachineLoader, setTimeMachineLoader} = useContext(AppContext);
    const {enableHeatmap, setEnableHeatmap} = useContext(AppContext);
    const {convertedHeatmapData, setConvertedHeatmapData} = useContext(AppContext);
    
    const [timeFrame, setTimeFrame] = useState({
        fromDate: "",
        toDate: "",
        fromTime: "",
        toTime: ""
    })
    const [timeFrameValidation, setTimeFrameValidation] = useState({
        fromDate: true,
        toDate: true,
        fromTime: true,
        toTime: true
    })

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('time-range-wrapper-id')) {
            closeTimeRangePopup(false);
        }
    })
    
    useEffect(() => {
        if(timeMachineTimeFrames?.fromDate !== '') {
            setTimeFrame(timeMachineTimeFrames)
        }
        clearTimeFrame();
    }, [])

    const [timeFrameSelected, setTimeFrameSelected] = useState("");

    const enterTimeMachineMode = () => {
        setTimeMachineModeIsEnabled(true);
        localStorage.setItem('isTimeMachineModeEnabled', "true");
    }

    const enterHeatmapMode = () => {
        setIsHeatmapModeEnabled(true);
    }

    const clearTimeFrame = () => {
        setTimeFrame({
            fromDate: "",
            toDate: "",
            fromTime: "",
            toTime: ""
        });
        setTimeFrameSelected("");
        setMinAndMaxDateRange();
        setTimeRangeMinimumDate(timeFrame.fromDate);
        setTimeRangeMaximumDate(timeFrame.toDate);
        setTimeFrame((data) => ({...data, fromTime: "", toTime: ""}));
    }

    useEffect(() => {
        if(convertedHeatmapData == null) {
            clearTimeFrame();
        }
    }, [convertedHeatmapData])

    const applyTimeFrame = () => {
        let modalType = JSON.parse(localStorage.getItem('timeRangeType'));
        if(modalType === 'timeMachine') {
            setValidateTimeFrame();
            if(validateTimeFrames()) {
                setTimeMachineTimeFrames(timeFrame);
                setTimeRangePopup(!timeRangePopup);
                enterTimeMachineMode();
                setIsTimeMachineDataAvailable(true);
                localStorage.setItem('timeMachineDataAvailable', JSON.stringify(true));
                setLocalStorageItem('seekPosition', 0);
                setLocalStorageItem('totalSeekPosition', 1);
                setLocalStorageItem('timeMachineDataAvailable', true);
                setLocalStorageItem('currentPageTimeMachineData', {pageSize:0, data:['startData']});
                setCurrentPageCount(1);
                setLocalStorageItem('groupDataCount', 0);
                setLocalStorageItem('groupDataCurrentCount', 0)
                setTimeMachineLoader(true);
                closeTimeRangePopup();
            }
        } else {
            setValidateTimeFrame();
            if(validateTimeFrames()) {
                setHeatmapTimeFrames(timeFrame);
                setTimeRangePopup(!timeRangePopup);
                setLocalStorageItem('heatmapTotalPageCount', 0);
                setLocalStorageItem('heatmapCurrentPageCount', 1);
                setEnableHeatmap(true);
                enterHeatmapMode();
                closeTimeRangePopup();
            }
        }
    }

    const validateTimeFrames = () => {
        if(timeFrame.fromDate == undefined || timeFrame.toDate == undefined || timeFrame.fromTime == undefined || timeFrame.toTime == undefined || timeFrame.fromDate == "" || timeFrame.toDate == "" || timeFrame.fromTime == "" || timeFrame.toTime == "") {
            return false;
        } else {
            return true;
        }
    }

    const setValidateTimeFrame = () => {
        if(timeFrame.fromDate == "" || timeFrame.fromDate == undefined) {
            setTimeFrameValidation((validation) => ({...validation, fromDate: false}));
        } else {
            setTimeFrameValidation((validation) => ({...validation, fromDate: true}));
        } 
        if(timeFrame.toDate == "" || timeFrame.toDate == undefined) {
            setTimeFrameValidation((validation) => ({...validation, toDate: false}));
        } else {
            setTimeFrameValidation((validation) => ({...validation, toDate: true}));
        }
        if(timeFrame.fromTime == "" || timeFrame.fromTime == undefined) {
            setTimeFrameValidation((validation) => ({...validation, fromTime: false}));
        } else {
            setTimeFrameValidation((validation) => ({...validation, fromTime: true}));
        }
        if(timeFrame.toTime == "" || timeFrame.toTime == undefined) {
            setTimeFrameValidation((validation) => ({...validation, toTime: false}));
        } else {
            setTimeFrameValidation((validation) => ({...validation, toTime: true}));
        }
    }

    const getTodayDate = (date) => {
        return new Date(date).toISOString().split('T')[0];
    }

    const getPastDate = (date, pastDateCount) => {
        let todayDate = new Date(date);
        return new Date(todayDate.setDate(todayDate.getDate() + 1 - pastDateCount)).toISOString().split('T')[0];
    }

    const getEarlierMin = (date, timeEarlier) => {
        let newDate = moment().subtract(timeEarlier, 'm')._d;
        let pastTime = String(newDate).split(' ')[4];
        let pastHour = pastTime.split(':')[0];
        let pastMinute = pastTime.split(':')[1];
        return `${pastHour}:${pastMinute}`;
    }

    const getEarlierHr = (date, timeEarlier) => {
        let newDate = moment().subtract(timeEarlier, 'h')._d;
        let pastTime = String(newDate).split(' ')[4];
        let pastHour = pastTime.split(':')[0];
        let pastMinute = pastTime.split(':')[1];
        return `${pastHour}:${pastMinute}`;
    }

    const getCurrentTime = (date) => {
        let currentHour= String(date.getHours()).padStart(2, '0');
        let currentMinute = String(date.getMinutes()).padStart(2,"0");
        return `${currentHour}:${currentMinute}`;
    }

    const set15MinsTimeFrame = () => {
        let date = new Date();
        let dateToday = getTodayDate(date);

        let timeToday = getCurrentTime(date);
        setTimeFrame((date) => ({...date, fromDate: dateToday, toTime: timeToday}));

        let earlierTime = getEarlierMin(date, 15);
        setTimeFrame((date) => ({...date, toDate: dateToday, fromTime: earlierTime}));
        setTimeFrameSelected("15");
    }

    const set30MinsTimeFrame = () => {
        let date = new Date();
        let dateToday = getTodayDate(date);

        let timeToday = getCurrentTime(date);
        setTimeFrame((date) => ({...date, fromDate: dateToday, toTime: timeToday}));

        let earlierTime = getEarlierMin(date, 30);
        setTimeFrame((date) => ({...date, toDate: dateToday, fromTime: earlierTime}));
        setTimeFrameSelected("30");
    }

    const set1hrTimeFrame = () => {
        let date = new Date();
        let dateToday = getTodayDate(date);

        let timeToday = getCurrentTime(date);
        setTimeFrame((date) => ({...date, fromDate: dateToday, toTime: timeToday}));

        let earlierTime = getEarlierHr(date, 1);
        setTimeFrame((date) => ({...date, toDate: dateToday, fromTime: earlierTime}));
        setTimeFrameSelected("1");
    }

    const set3hrTimeFrame = () => {
        let date = new Date();
        let dateToday = getTodayDate(date);

        let timeToday = getCurrentTime(date);
        setTimeFrame((date) => ({...date, fromDate: dateToday, toTime: timeToday}));

        let earlierTime = getEarlierHr(date, 3);
        setTimeFrame((date) => ({...date, toDate: dateToday, fromTime: earlierTime}));
        setTimeFrameSelected("3");
    }

    const setTodayTimeFrame = () => {
        let date = new Date();

        let dateToday = getTodayDate(date);
        let timeToday = getCurrentTime(date);

        setTimeFrame((data) => ({...data, fromDate: dateToday, fromTime: "00:00", toTime: timeToday, toDate: dateToday}));
        setTimeFrameSelected("today");
    }

    const [timeRangeMinimumDate, setTimeRangeMinimumDate] = useState('');
    const [timeRangeMaximumDate, setTimeRangeMaximumDate] = useState('');

    const setMinAndMaxDateRange = () => {
        let date = new Date();
        let minPastDate = getPastDate(date, 5);
        let maxPastDate = getTodayDate(date);
        timeFrame.fromDate = minPastDate;
        timeFrame.toDate = maxPastDate;
        setTimeFrame(timeFrame);
    }
    
    useEffect(() => {
        setMinAndMaxDateRange();
    }, [])


    const closeTimeRangePopup = () => {
        setTimeRangePopup(!timeRangePopup);
    }

    useEffect(() => {
        if(timeFrame.fromDate !== '') {
            setTimeRangeMinimumDate(timeFrame.fromDate);
        }
        if(timeFrame.toDate !== '') {
            setTimeRangeMaximumDate(timeFrame.toDate);
        }
    }, [timeFrame.fromDate, timeFrame.toDate])

    document.addEventListener('click', (e) => {
        if(e.target == document.getElementById('time-range-wrapper-id')) {
            setTimeRangePopup(!timeRangePopup);
        }
    })

    return (
        <>
            {timeRangePopup ? (
            <div id='time-range-wrapper-id' className="time-range-wrapper">
                <div className="time-range-container">
                <div className="time-range-top-section">
                    <p>Time Range</p>
                    <img
                        onClick={() => closeTimeRangePopup()}
                        className="time-range-popup-close-btn"
                        src={Close}
                    />
                </div>
                <div className="time-range-middle-section">
                    <div className="picker-section">
                    <div className="time-range-left-panel">
                        From
                        <div className="time-range-pickers">
                        <DatePicker
                            validation={timeFrameValidation.fromDate}
                            date={timeFrame.fromDate}
                            getDate={setTimeFrame}
                            name="fromDate"
                            min={timeRangeMinimumDate}
                            max={timeRangeMaximumDate}
                        />
                        <TimePicker
                            validation={timeFrameValidation.fromTime}
                            time={timeFrame.fromTime}
                            getTime={setTimeFrame}
                            name="fromTime"
                        />
                        </div>
                    </div>
                    <div className="time-range-right-panel">
                        To
                        <div className="time-range-pickers">
                        <DatePicker
                            validation={timeFrameValidation.toDate}
                            date={timeFrame.toDate}
                            getDate={setTimeFrame}
                            name="toDate"
                            min={timeRangeMinimumDate}
                            max={timeRangeMaximumDate}
                        />
                        <TimePicker
                            validation={timeFrameValidation.toTime}
                            time={timeFrame.toTime}
                            getTime={setTimeFrame}
                            name="toTime"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="time-range-info">
                    <p>Please enter the time as per your system time format.</p>
                    </div>
                    <div className="time-frame-section">
                    <Button
                        onClick={() => set15MinsTimeFrame()}
                        placeholder="Last 15 mins"
                        btnClass={`time-frame-btn ${
                        timeFrameSelected == "15"
                            ? "time-frame-btn-selected"
                            : ""
                        }`}
                    ></Button>
                    <Button
                        onClick={() => set30MinsTimeFrame()}
                        placeholder="Last 30 mins"
                        btnClass={`time-frame-btn ${
                        timeFrameSelected == "30"
                            ? "time-frame-btn-selected"
                            : ""
                        }`}
                    ></Button>
                    <Button
                        onClick={() => set1hrTimeFrame()}
                        placeholder="Last 1 hr"
                        btnClass={`time-frame-btn ${
                        timeFrameSelected == "1"
                            ? "time-frame-btn-selected"
                            : ""
                        }`}
                    ></Button>
                    <Button
                        onClick={() => set3hrTimeFrame()}
                        placeholder="Last 3 hr"
                        btnClass={`time-frame-btn ${
                        timeFrameSelected == "3"
                            ? "time-frame-btn-selected"
                            : ""
                        }`}
                    ></Button>
                    <Button
                        onClick={() => setTodayTimeFrame()}
                        placeholder="Today"
                        btnClass={`time-frame-btn ${
                            timeFrameSelected == "today"
                                ? "time-frame-btn-selected"
                                : ""
                            }`}
                    ></Button>
                    </div>
                </div>
                <div className="time-range-bottom-section">
                    <Button
                        onClick={() => clearTimeFrame()}
                        placeholder="Reset"
                        btnClass="time-range-discard-btn time-range-btn"
                    ></Button>
                    <Button
                        onClick={() => applyTimeFrame()}
                        placeholder="Apply"
                        btnClass="time-range-confirm-btn time-range-btn"
                    ></Button>
                </div>
                </div>
            </div>
            ) : null}
        </>
    );
}

export default TimeRangePopup;