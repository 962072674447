export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: window.location.origin,
        authority: process.env.REACT_APP_AUTHORITY,
    },
    scopes: [
        "openid",
        "profile",
        "email",
        "User.Read",
        process.env.REACT_APP_SCOPE_API
    ],
    cache:{
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};