import "./alertStyles.css";
import closeButton from "../../../assets/icons/close.png";

import alertSuccessIcon from "../../../assets/icons/alertSuccessIcon.png";
import alertWarningIcon from "../../../assets/icons/alertWarningIcon.png";
import alertErrorIcon from "../../../assets/icons/alertErrorIcon.png";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/Context";

const AlertMessage = ({ heading, description, type }) => {
  const [messageObject, setMessageObject] = useState(null);
  const [message, setMessage] = useState({ heading: "", description: "" });

  const { alertObject, setAlertObject } = useContext(AppContext);

  useEffect(() => {
    if (
      type !== undefined &&
      heading.length !== 0 &&
      description.length !== 0
    ) {
      setMessage({ heading: heading, description: description });
      setMessageObject(type);
    }

    setTimeout(() => {
      closeHandler();
    }, 5000);
  }, [heading, description, type]);

  const closeHandler = () => {
    setAlertObject({
      type: "",
      payload: {},
    });
  };

  const renderMessageClassName = () => {
    switch (messageObject) {
      case "success":
        return "success-message";
      case "warning":
        return "warning-message";
      case "error":
        return "error-message";
      default:
        return "success-message";
    }
  };

  const renderIcon = () => {
    switch (messageObject) {
      case "success":
        return alertSuccessIcon;
      case "warning":
        return alertWarningIcon;
      case "error":
        return alertErrorIcon;
      default:
        return alertSuccessIcon;
    }
  };

  return (
    <div className="alert-message-wrapper">
      <div className={`alert-message-container ${renderMessageClassName()}`}>
        <div className="alert-message-icon-section">
          <img src={renderIcon()} />
        </div>
        <div className="alert-message-message-section">
          <span className="alert-message-title">{message.heading}</span>
          <span className="alert-message-description">
            {message.description}
          </span>
        </div>
        <div className="alert-message-close-section">
          <img src={closeButton} onClick={() => closeHandler()} />
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
