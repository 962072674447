import CARMap from "../../assets/images/plantImage/CAR.jpeg";
import ATLMap from "../../assets/images/plantImage/ATL.png";
import DETMap from "../../assets/images/plantImage/DET.png";
import ALAMap from "../../assets/images/plantImage/ALA.png";
import HAZMap from "../../assets/images/plantImage/HAZ.png";
import HOUMap from "../../assets/images/plantImage/HOU.png";
import LASMap from "../../assets/images/plantImage/LAS.png";
import MIAMap from "../../assets/images/plantImage/MIA.png";
import TACMap from "../../assets/images/plantImage/TAC.png";
import JAXMap from "../../assets/images/plantImage/JAX.png";
import LANMap from "../../assets/images/plantImage/LAN.png";
import ST3Map from "../../assets/images/plantImage/ST3.png";
import BALMap from "../../assets/images/plantImage/BAL.png";
import TEMMap from "../../assets/images/plantImage/TEM.png";
import KC2Map from "../../assets/images/plantImage/KC2.png";
import MORMap from "../../assets/images/plantImage/MOR.png";
import LOUMap from "../../assets/images/plantImage/LOU.png";
import BAYMap from "../../assets/images/plantImage/BAY.png";
import RCHMap from "../../assets/images/plantImage/RCH.png";
import COLMap from "../../assets/images/plantImage/COL.png";
import KNCMap from "../../assets/images/plantImage/KNC.png";
import BLMMap from "../../assets/images/plantImage/BLM.png";
import KENMap from "../../assets/images/plantImage/KEN.png";
import SANMap from "../../assets/images/plantImage/SAN.png";
import SL2Map from "../../assets/images/plantImage/SL2.png";
import MESMap from "../../assets/images/plantImage/MES.png";
import MISMap from "../../assets/images/plantImage/MIS.png";
import PITMap from "../../assets/images/plantImage/PIT.png";
import MTYMap from "../../assets/images/plantImage/MTY.png";
import OKCMap from "../../assets/images/plantImage/OKC.png";
import MXCMap from "../../assets/images/plantImage/MXC.png";

import ALALMap from "../../assets/images/htmlmaps/ALA.jpg";
import ATLLMap from "../../assets/images/htmlmaps/ATL.jpg";
import LASLMap from "../../assets/images/htmlmaps/LAS.jpg";
import HAZLMap from "../../assets/images/htmlmaps/HAZ.jpg";

import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { userDataObject } from "./constants";

export const statusColorRenderer = (status) => {
  switch (status) {
    case "Wait Qty Check":
      return "#008300";
    case "Scheduled":
      return "#4647FE";
    case "Wait Dock Cnfrm":
      return "#D2B623";
    case "Wait Start Cnfrm":
      return "#7C4807";
    case "Enqueued To Start":
      return "#FF88FF";
    case "Started":
      return "#8D8D8D";
    case "Staged":
      return "#FF8903";
    case "Suspended":
      return "#7B037F";
    case "Traffic":
      return "#D96E0B";
    case "Alarm":
      return "#EE3232";
    case "Removed":
      return "#000000";
    case "Charging":
      return "#83C4D9";
    case "Iddle":
      return "#008000";
    case "Idle":
      return "#008000";
    case "LGV In Manual":
      return "#9D4B4B";
    case "ActiveFull":
      return "#0000FF";
    case "ActiveEmpty":
      return "#008000";
    default:
      return "#000000";
  }
};

export const isTokenExpired = () => {
  let auth = JSON.parse(localStorage.getItem("isAuthExpiry"));
  if (!auth) {
    return true;
  }
  let expiryTime = new Date(auth.expiryTime);
  let currentTime = new Date();
  if (expiryTime < currentTime) {
    return true;
  }
  return false;
};

export const externalLinkNavigator = (link) => {
  const quickLinks = {
    logistics:
      "https://app.powerbi.com/groups/me/reports/64fa4bc6-35a9-48ea-bdf1-af49555a6d7f/ReportSectionc1d9f7cba778eee595ac?ctid=773bda68-87c6-401a-b9a4-3dfb1f92e189",
    performance:
      "http://pdc-whse-perf:8080/d/wWbpz254z/lgv_one_summary?orgId=1&refresh=1m&var-WHSE=CAR_LGV",
    shipment:
      "http://pdc-whse-perf:8080/d/G7En7Wp4z/shipment-progress?orgId=1&refresh=5s&var-WHSE=CAR_LGV",
    downtime:
      "http://pdc-whse-perf:8080/d/ESqcrJ-4z/lgv-alarm-summary?orgId=1&var-WHSE=CAR_LGV",
    pairing:
      "http://pdc-whse-perf:8080/d/Tdz-8g84k/active-mixed-pairing?orgId=1&refresh=5s",
    yardView: `/${getLocalStorageItem("locationCode")}/yard-view`,
    allocatedView: `/${getLocalStorageItem("locationCode")}/allocated-view`,
  };
  return quickLinks[link];
};

export const authExpirationNavigator = (errorApi) => {
  if (errorApi > 4) {
    localStorage.setItem("isAuthExpiry", JSON.stringify(true));
    reloadApp();
  }
};

export const dockDoorStatusColor = (status, mode) => {
  let simpleMode = {
    CanAllocate: "#767676",
    Allocated: "#767676",
    Released: "#767676",
    Started: "#767676",
    InTransit: "#767676",
    Completed: "#309218",
    New: "#FF5733",
    Parked: "#FF5733",
  };
  let dockDoorStatus = {
    CanAllocate: "#D90000",
    Allocated: "#767676",
    Released: "#AD9200",
    Started: "#0000D9",
    InTransit: "#0000D9",
    Completed: "#309218",
    New: "#FF5733",
    Parked: "#FF5733",
  };
  if (status.length !== 0) {
    return mode === "SIMPLE" ? simpleMode[status] : dockDoorStatus[status];
  } else {
    return "#ffffff";
  }
};

export const getAdjustedCoordinates = () => {
  let levels = [
    { start: 500, end: 800, level: { x: 56, y: 16 } },
    { start: 801, end: 1200, level: { x: 56, y: 16 } },
    { start: 1201, end: 1400, level: { x: 56, y: 16 } },
    { start: 1401, end: 1600, level: { x: 20, y: 17 } },
    { start: 1601, end: 1800, level: { x: 120, y: 19 } },
    { start: 1801, end: 2000, level: { x: 170, y: 22 } },
    { start: 2001, end: 2200, level: { x: 290, y: 24 } },
    { start: 2201, end: 2400, level: { x: 426, y: 26 } },
    { start: 2401, end: 2600, level: { x: 476, y: 28 } },
    { start: 2601, end: 2800, level: { x: 630, y: 26 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getAdjustedLineProductionCoordinates = () => {
  let levels = [
    { start: 500, end: 800, level: { x: 75, y: 170 } },
    { start: 801, end: 1200, level: { x: 240, y: 170 } },
    { start: 1201, end: 1400, level: { x: 75, y: 170 } },
    { start: 1401, end: 1600, level: { x: 60, y: 170 } },
    { start: 1601, end: 1800, level: { x: 60, y: 170 } },
    { start: 1801, end: 2000, level: { x: 110, y: 170 } },
    { start: 2001, end: 2200, level: { x: 230, y: 170 } },
    { start: 2201, end: 2400, level: { x: 610, y: 170 } },
    { start: 2401, end: 2600, level: { x: 650, y: 170 } },
    { start: 2601, end: 2800, level: { x: 550, y: 170 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getAdjustedDockDoorCoordinates = () => {
  let levels = [
    { start: 0, end: 200, level: { x: 0, y: 40 } },
    { start: 201, end: 500, level: { x: 0, y: 40 } },
    { start: 501, end: 800, level: { x: 0, y: 40 } },
    { start: 801, end: 1200, level: { x: 0, y: 40 } },
    { start: 1201, end: 1400, level: { x: 0, y: 40 } },
    { start: 1401, end: 1600, level: { x: 0, y: 40 } },
    { start: 1601, end: 1800, level: { x: 120, y: 40 } },
    { start: 1801, end: 2000, level: { x: 180, y: 40 } },
    { start: 2001, end: 2200, level: { x: 300, y: 40 } },
    { start: 2201, end: 2400, level: { x: 650, y: 40 } },
    { start: 2401, end: 2600, level: { x: 650, y: 40 } },
    { start: 2601, end: 2800, level: { x: 650, y: 40 } },
  ];
  let windowWidth = window.innerWidth;
  let matchingWindowLevel = levels.find(
    (level) => windowWidth > level.start && windowWidth < level.end
  );
  return matchingWindowLevel;
};

export const getScreenSize = () => {
  return window.innerWidth;
};

export const setDynamicZoomLevelToMap = (scaleLevel, mapType) => {
  switch (mapType) {
    case "indoor":
      switch (scaleLevel) {
        case "1.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.0)";
          break;
        }
        case "1.2": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.2) translate(120px, 50px)";
          break;
        }
        case "1.4": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.4) translate(210px, 90px)";
          break;
        }
        case "1.6": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.6) translate(265px, 120px)";
          break;
        }
        case "1.8": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.8) translate(325px, 135px)";
          break;
        }
        case "2.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.0) translate(360px, 155px)";
          break;
        }
        case "2.2": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.2) translate(380px, 175px)";
          break;
        }
        case "2.4": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.4) translate(400px, 195px)";
          break;
        }
        case "2.6": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.6) translate(420px, 215px)";
          break;
        }
        case "2.8": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(2.8) translate(440px, 235px)";
          break;
        }
        case "3.0": {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(3.0) translate(460px, 255px)";
          break;
        }
        default: {
          document.getElementById("indoor-map-id-reference").style.transform =
            "scale(1.0)";
          break;
        }
      }
      break;

    case "timeMachine":
      switch (scaleLevel) {
        case "1.0": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.0)";
          break;
        }
        case "1.2": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.2) translate(120px, 50px)";
          break;
        }
        case "1.4": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.4) translate(210px, 90px)";
          break;
        }
        case "1.6": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.6) translate(275px, 120px)";
          break;
        }
        case "1.8": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.8) translate(325px, 135px)";
          break;
        }
        case "2.0": {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(2.0) translate(360px, 155px)";
          break;
        }
        default: {
          document.getElementById(
            "time-machine-map-id-reference"
          ).style.transform = "scale(1.0)";
          break;
        }
      }
      break;

    default:
      break;
  }
};

export const isScreenBigger = () => {
  if (Math.round(((window.outerWidth - 10) / window.innerWidth) * 100) <= 50) {
    setLocalStorageItem("isScreenBigger", true);
    return true;
  }
  return false;
};

export const getHeatmapWeightage = (length) => {
  if (length < 1) {
    return { fillOpacity: "5%", fillColor: "white" };
  } else if (length <= 1 && length < 10) {
    return { fillOpacity: "10%", fillColor: "red" };
  } else if (length >= 10 && length < 15) {
    return { fillOpacity: "15%", fillColor: "red" };
  } else if (length >= 15 && length < 20) {
    return { fillOpacity: "25%", fillColor: "red" };
  } else if (length >= 25 && length < 30) {
    return { fillOpacity: "45%", fillColor: "red" };
  } else if (length >= 30) {
    return { fillOpacity: "65%", fillColor: "red" };
  }
};

// export const getResponsiveGridLatLng = () => {
//   let windowWidth = window.innerWidth;
//   console.log(" windowWidth", windowWidth);
//   if (windowWidth >= 720 && windowWidth <= 1020) {
//     return { x: 10, y: 0 };
//   }
//   if (windowWidth > 1021 && windowWidth <= 1240) {
//     return { x: 100, y: 0 };
//   }
//   if (windowWidth > 1241 && windowWidth <= 1690) {
//     return { x: 80, y: 0 };
//   }

//   if (windowWidth > 1690 && windowWidth < 1700) {
//     //80%
//     return { x: -40, y: 0 };
//   }
//   if (windowWidth >= 1700 && windowWidth <= 1820) {
//     //75%
//     return { x: 50, y: 0 }; // top-bottom, left-right
//   }
//   if (windowWidth >= 1821 && windowWidth <= 1920) {
//     //75%
//     return { x: -25, y: 0 }; // top-bottom, left-right
//   }
//   if (windowWidth >= 1921 && windowWidth <= 2050) {
//     //67%
//     return { x: -160, y: 0 }; // top-bottom, left-right
//   }
//   if (windowWidth >= 2051 && windowWidth <= 2581) {
//     return { x: -480, y: 0 };
//   }

//   if (windowWidth >= 2581 && windowWidth <= 2732) {
//     return { x: -200, y: 0 };
//   }
//   if (windowWidth >= 2733) {
//     return { x: -500, y: 0 };
//   }
// };

export const convertGridCoordinates = (mapObject) => {
  let convertedCoordinates = mapCoordinate(38000, 182000);
  let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
  let convertedLatLng =
    mapObject.map.target.layerPointToLatLng(convertedPoints);
  return convertedLatLng;
};

export const convertGridCoordinatesX = (mapObject) => {
  let convertedCoordinates = mapCoordinate(12500, 168000);
  let convertedPoints = L.point(convertedCoordinates.x, convertedCoordinates.y);
  let convertedLatLng =
    mapObject.map.target.layerPointToLatLng(convertedPoints);
  return convertedLatLng;
};

export const getGridCoordinates = (mapObject) => {
  // let bottomLeftConvertedCoordinates = mapCoordinate(800000, -85000);
  let bottomLeftConvertedCoordinates = mapCoordinate(38000, 182000);
  let bottomLeftConvertedPoints = L.point(
    bottomLeftConvertedCoordinates.x,
    bottomLeftConvertedCoordinates.y
  );
  let bottomLeftConvertedLatLng = mapObject.map.target.layerPointToLatLng(
    bottomLeftConvertedPoints
  );

  // let topRightConvertedCoordinates = mapCoordinate(-200000, 300000);
  let topRightConvertedCoordinates = mapCoordinate(12500, 168000);
  let topRightConvertedPoints = L.point(
    topRightConvertedCoordinates.x,
    topRightConvertedCoordinates.y
  );
  let topRightConvertedLatLng = mapObject.map.target.layerPointToLatLng(
    topRightConvertedPoints
  );

  return {
    bottomLeftConvertedLatLng,
    topRightConvertedLatLng,
  };
};

export const timeFrameFormatter = (timeMachineTimeFrames) => {
  let convertedFromDate = `${timeMachineTimeFrames?.fromDate}T${timeMachineTimeFrames?.fromTime}:00.000Z`;
  let convertedToDate = `${timeMachineTimeFrames?.toDate}T${timeMachineTimeFrames?.toTime}:00.000Z`;
  const { fromTimePlant, toTimePlant } = convertLocaleToPlantTimeZone(
    convertedFromDate,
    convertedToDate
  );
  return { fromTimePlant, toTimePlant };
};

export const apiHeadersFormatter = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type",
    },
  };
};

export const getLocalStorageItem = (objectName) => {
  if (localStorage.getItem(objectName) !== undefined) {
    return JSON.parse(localStorage.getItem(objectName));
  }
};

export const setLocalStorageItem = (objectName, value) => {
  localStorage.setItem(objectName, JSON.stringify(value));
};

export const clearLocalStorageItem = (objectName) => {
  localStorage.removeItem(objectName);
};

export const reloadApp = () => {
  window.location.reload();
};

export const timeStampMinuteToHourFormatter = (time) => {
  let totalTimeParts = time.split(":");
  if (totalTimeParts.length === 2) {
    return `${totalTimeParts[0]}:${totalTimeParts[1]}`;
  } else {
    return `${totalTimeParts[1]}:${totalTimeParts[2]}`;
  }
};

export const formatTime = (inputTime) => {
  const [hoursStr, minutesStr, secondsStr] = inputTime.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

//Plant Image
export const getPlantDetails = (plantCode, featureType) => {
  const plantDetails = {
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ATL: {
      image: ATLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 13000, lng: -35000 },
        rightTop: { lat: 510000, lng: 176000 },
      },
    },
    ALA: {
      image: ALAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    // LAYOUT_START_X	15000
    // LAYOUT_END_X	333000
    // LAYOUT_START_Y	0
    // LAYOUT_END_Y	143000
    HAZ: {
      image: HAZMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 680000, lng: 153000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 480000, lng: 153000 },
      },
    },
    LAS: {
      image: LASMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 140000, lng: -15500 },
        rightTop: { lat: 429000, lng: 152500 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 570000, lng: 160000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    KC2: {
      image: KC2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    MOR: {
      image: MORMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    LOU: {
      image: LOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    TEM: {
      image: TEMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BAY: {
      image: BAYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    COL: {
      image: COLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 340000, lng: 153000 },
      },
    },
    KNC: {
      image: KNCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    RCH: {
      image: RCHMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BLM: {
      image: BLMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    KEN: {
      image: KENMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    SAN: {
      image: SANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    SL2: {
      image: SL2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    MES: {
      image: MESMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    MIS: {
      image: MISMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -12000 },
        rightTop: { lat: 444000, lng: 179000 },
      },
    },
    PIT: {
      image: PITMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -70000 },
        rightTop: { lat: 510000, lng: 150000 },
      },
    },
    MTY: {
      image: MTYMap,
      bounds: {
        leftBottom: { lat: 36000, lng: -30000 },
        rightTop: { lat: 508000, lng: 185000 },
      },
    },
    OKC: {
      image: OKCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 13000 },
        rightTop: { lat: 510000, lng: 175000 },
      },
    },
    MXC: {
      image: MXCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -90000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
  };
  const plantHeatMapDetails = {
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 40000 },
        rightTop: { lat: 416000, lng: 158000 },
      },
    },
    ATL: {
      image: ATLMap,
      bounds: {
        leftBottom: { lat: 55000, lng: 45000 },
        rightTop: { lat: 450000, lng: 168000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 55000, lng: 45000 },
        rightTop: { lat: 450000, lng: 168000 },
      },
    },
    ALA: {
      image: ALAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 52000 },
        rightTop: { lat: 502000, lng: 178000 },
      },
    },
    HAZ: {
      image: HAZMap,
      bounds: {
        leftBottom: { lat: 48000, lng: 42000 },
        rightTop: { lat: 470000, lng: 170000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 60000, lng: 39000 },
        rightTop: { lat: 470000, lng: 180000 },
      },
    },
    LAS: {
      image: LASMap,
      bounds: {
        leftBottom: { lat: 39000, lng: 45000 },
        rightTop: { lat: 480000, lng: 178000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 22000 },
        rightTop: { lat: 485000, lng: 168000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 40000 },
        rightTop: { lat: 482000, lng: 178000 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 40000 },
        rightTop: { lat: 482000, lng: 170000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 40000, lng: 40000 },
        rightTop: { lat: 482000, lng: 172000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 42000, lng: 40000 },
        rightTop: { lat: 482000, lng: 172000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 45000 },
        rightTop: { lat: 485000, lng: 170000 },
      },
    },

    MOR: {
      image: MORMap,
      bounds: {
        leftBottom: { lat: 37000, lng: 32000 },
        rightTop: { lat: 480000, lng: 160000 },
      },
    },
    KC2: {
      image: KC2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 27000 },
        rightTop: { lat: 502000, lng: 173000 },
      },
    },
    TEM: {
      image: TEMMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 22000 },
        rightTop: { lat: 480000, lng: 160000 },
      },
    },
    BAY: {
      image: BAYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LOU: {
      image: LOUMap,
      bounds: {
        leftBottom: { lat: 60000, lng: 25000 },
        rightTop: { lat: 500000, lng: 190000 },
      },
    },
    COL: {
      image: COLMap,
      bounds: {
        leftBottom: { lat: 42000, lng: 40000 },
        rightTop: { lat: 480000, lng: 178000 },
      },
    },
    KNC: {
      image: KNCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 43000 },
        rightTop: { lat: 512000, lng: 168000 },
      },
    },
    RCH: {
      image: RCHMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 40000 },
        rightTop: { lat: 482000, lng: 178000 },
      },
    },
    BLM: {
      image: BLMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 40000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    KEN: {
      image: KENMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 24000 },
        rightTop: { lat: 512000, lng: 188000 },
      },
    },
    SAN: {
      image: SANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 30000 },
        rightTop: { lat: 512000, lng: 180000 },
      },
    },
    SL2: {
      image: SL2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 30000 },
        rightTop: { lat: 512000, lng: 180000 },
      },
    },
    MES: {
      image: MESMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 50000 },
        rightTop: { lat: 512000, lng: 190000 },
      },
    },
    MIS: {
      image: MISMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 32000 },
        rightTop: { lat: 512000, lng: 188000 },
      },
    },
    PIT: {
      image: PITMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 75000 },
        rightTop: { lat: 512000, lng: 231000 },
      },
    },
    MTY: {
      image: MTYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 35000 },
        rightTop: { lat: 512000, lng: 191000 },
      },
    },
    OKC: {
      image: OKCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 35000 },
        rightTop: { lat: 512000, lng: 191000 },
      },
    },
    MXC: {
      image: MXCMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 35000 },
        rightTop: { lat: 512000, lng: 201000 },
      },
    },
  };
  const overlayMapDetails = {
    CAR: {
      image: CARMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ATL: {
      image: ATLLMap,
      bounds: {
        leftBottom: { lat: 14000, lng: -18000 },
        rightTop: { lat: 512000, lng: 160000 },
      },
    },
    DET: {
      image: DETMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ALA: {
      image: ALALMap,
      bounds: {
        leftBottom: { lat: 14000, lng: -24000 },
        rightTop: { lat: 534000, lng: 170000 },
      },
    },
    HAZ: {
      image: HAZLMap,
      bounds: {
        leftBottom: { lat: 14000, lng: -22000 },
        rightTop: { lat: 510000, lng: 165000 },
      },
    },
    HOU: {
      image: HOUMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LAS: {
      image: LASLMap,
      bounds: {
        leftBottom: { lat: 17000, lng: -23000 },
        rightTop: { lat: 536000, lng: 160000 },
      },
    },
    MIA: {
      image: MIAMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    TAC: {
      image: TACMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    JAX: {
      image: JAXMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    LAN: {
      image: LANMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    ST3: {
      image: ST3Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    BAL: {
      image: BALMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    KC2: {
      image: KC2Map,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    TEM: {
      image: TEMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: 22000 },
        rightTop: { lat: 512000, lng: 178000 },
      },
    },
    BAY: {
      image: BAYMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    COL: {
      image: COLMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    RCH: {
      image: RCHMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    BLM: {
      image: BLMMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 153000 },
      },
    },
    KEN: {
      image: KENMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
    MIS: {
      image: MISMap,
      bounds: {
        leftBottom: { lat: 38000, lng: -13000 },
        rightTop: { lat: 510000, lng: 169000 },
      },
    },
  };
  if (featureType === "indoorMap") {
    return plantDetails[plantCode];
  } else if (featureType === "mapOverlay") {
    if (plantCode === "ATL" || "ALA") {
      return overlayMapDetails[plantCode];
    }
  } else {
    return plantHeatMapDetails[plantCode];
  }
};

export const convertLocaleToPlantTimeZone = (
  convertedFromDate,
  convertedToDate
) => {
  if (convertedFromDate && convertedToDate) {
    let fromDate = new Date(convertedFromDate);
    let toDate = new Date(convertedToDate);
    let timeZone = getLocalStorageItem("timeZone");

    const plantTimeZoneFromDate = fromDate.toLocaleString("en-US", {
      timeZone: timeZone,
      timeStyle: "medium",
      dateStyle: "medium",
      hourCycle: "h24",
    });
    const plantTimeZoneToDate = toDate.toLocaleString("en-US", {
      timeZone: timeZone,
      timeStyle: "medium",
      dateStyle: "medium",
      hourCycle: "h24",
    });

    const convert = (dateAndTime) => {
      return new Date(dateAndTime).toISOString();
    };

    let fromTimePlant = convert(plantTimeZoneFromDate);
    let toTimePlant = convert(plantTimeZoneToDate);

    return { fromTimePlant, toTimePlant };
  }
};

export const filterActivePlant = (plantData) => {
  return plantData.data.filter(
    (data) =>
      data.name === "Carolina" ||
      data.name === "Atlanta" ||
      data.name === "Detroit" ||
      data.name === "Alabama" ||
      data.name === "Hazleton" ||
      data.name === "Houston" ||
      data.name === "Las Vegas" ||
      data.name === "Miami" ||
      data.name === "Tacoma" ||
      data.name === "Jacksonville" ||
      data.name === "Lancaster" ||
      data.name === "Stockton 3" ||
      data.name === "Baltimore" ||
      data.name === "Kansas City 2" ||
      data.name === "Kansas City" ||
      data.name === "Morocco" ||
      data.name === "Temple" ||
      data.name === "Louisville" ||
      data.name === "Baytown" ||
      data.name === "Richmond" ||
      data.name === "Columbus" ||
      data.name === "Bloomfield" ||
      data.name === "San Antonio" ||
      data.name === "Salt Lake City 2" ||
      data.name === "Bloomfield" ||
      data.name === "Mesa" ||
      data.name === "Kenosha" ||
      data.name === "Mississippi" ||
      data.name === "Pittsburgh" ||
      data.name === "Monterrey" ||
      data.name === "Oklahoma City" ||
      data.name === "Mexico City"
  );
};

export const plantProductionLineCoordinateCorrection = () => {
  const coordinates = {
    CAR: { x: 40, y: 75 },
    ATL: { x: -215, y: -18 },
    DET: { x: -25, y: -2 },
    ALA: { x: 80, y: -380 },
    HAZ: { x: 250, y: -775 },
    HOU: { x: -130, y: -10 },
    LAS: { x: -250, y: -15 },
    MIA: { x: -75, y: 110 },
    TAC: { x: -425, y: -210 },
    JAX: { x: -20, y: 0 },
    LAN: { x: 10, y: -115 },
    ST3: { x: -290, y: -60 },
    BAL: { x: 720, y: -740 },
    KC2: { x: -140, y: -140 },
    MOR: { x: -80, y: -72 },
    TEM: { x: -280, y: 230 },
    LOU: { x: -95, y: -102 },
    KNC: { x: -215, y: -55 },
    BAY: { x: -450, y: 125 },
    BLM: { x: -172, y: -38 },
    RCH: { x: -195, y: -6 },
    COL: { x: -450, y: -140 },
    KEN: { x: -230, y: 20 },
    SAN: { x: -180, y: 260 },
    SL2: { x: 435, y: 310 },
    MES: { x: -25, y: -105 },
    MIS: { x: -320, y: -74 },
    PIT: { x: 710, y: -665 },
    MTY: { x: 350, y: 90 },
    OKC: { x: -165, y: 45 },
    MXC: { x: -350, y: -200 },
  };
  return coordinates[getLocalStorageItem("locationCode")];
};

export const plantBatteryStationObjCoordinateCorrection = () => {
  const coordinates = {
    CAR: { x: 50, y: -65 },
    ATL: { x: -215, y: -18 },
    DET: { x: -25, y: -2 },
    ALA: { x: 80, y: -380 },
    HAZ: { x: 250, y: -775 },
    HOU: { x: -130, y: -10 },
    LAS: { x: -250, y: -15 },
    MIA: { x: -75, y: 110 },
    TAC: { x: -425, y: -210 },
    JAX: { x: -20, y: 0 },
    LAN: { x: 10, y: -115 },
    ST3: { x: -290, y: -60 },
    BAL: { x: 720, y: -740 },
    KC2: { x: -140, y: -140 },
    MOR: { x: -80, y: -72 },
    TEM: { x: -280, y: 230 },
    LOU: { x: -95, y: -102 },
    KNC: { x: -215, y: -55 },
    BAY: { x: -450, y: 125 },
    BLM: { x: -172, y: -38 },
    RCH: { x: -195, y: -6 },
    COL: { x: -450, y: -140 },
    KEN: { x: -230, y: 20 },
    SAN: { x: -180, y: 260 },
    SL2: { x: 435, y: 310 },
    MES: { x: -25, y: -105 },
    MIS: { x: -320, y: -74 },
    PIT: { x: 710, y: -665 },
    MTY: { x: 350, y: 90 },
    OKC: { x: -165, y: 45 },
    MXC: { x: -350, y: -200 },
  };
  return coordinates[getLocalStorageItem("locationCode")];
};

export const eachProductionLineCoordinateCorrection = (index) => {
  const positions = {
    CAR: { 0: [0, -30], 1: [0, 15], 2: [0, 0], 3: [0, -35], 4: [0, -10] },
    ATL: { 0: [-10, 95], 1: [-10, 70], 2: [-10, 12] },
    DET: { 0: [-10, 100], 1: [-10, 180] },
    ALA: { 0: [-10, 100], 1: [-10, 220] },
    HAZ: {
      0: [60, -335],
      1: [60, -360],
      2: [-19, -255],
      3: [-19, -335],
      4: [15, -225],
      5: [0, -50],
      6: [0, -105],
      7: [0, -5],
    },
    HOU: { 0: [0, 45], 1: [0, 85], 2: [0, 110], 3: [-45, -7] },
    LAS: { 0: [0, -150], 1: [0, 50] },
    MIA: {
      0: [-25, -5],
      1: [0, 380],
      2: [0, 420],
      3: [-32, 500],
      4: [40, 640],
    },
    TAC: { 0: [0, -20], 1: [0, -40], 2: [105, -130] },
    JAX: {
      0: [10, 0],
      1: [10, 55],
      2: [5, 125],
      3: [25, 110],
      4: [25, 20],
    },
    LAN: { 0: [10, -90], 1: [10, -55], 2: [10, 20] },
    ST3: { 0: [0, 50], 1: [0, 0] },
    BAL: { 0: [-5, 815], 1: [-5, 30], 2: [5, 0] },
    KC2: { 0: [-20, -80], 1: [-20, 5] },
    MOR: {
      0: [-18, -85],
      1: [-18, -10],
      2: [-28, -10],
      3: [-28, 72],
      4: [-28, 100],
    },
    TEM: { 0: [0, 0], 1: [0, 80], 2: [80, 180] },
    LOU: {
      0: [0, 283],
      1: [0, 205],
      2: [0, 230],
      3: [0, 105],
      4: [0, 190],
      5: [0, 0],
    },
    BAY: {
      0: [0, -15],
      1: [0, 15],
    },
    RCH: { 0: [0, 70], 1: [0, 40], 2: [0, 0] },
    COL: {
      0: [0, -430],
      1: [0, -330],
    },
    KNC: {
      0: [0, 0],
      1: [5, 175],
      2: [-10, 340],
    },
    BLM: { 0: [0, 0], 1: [0, 40], 2: [0, 175] },
    KEN: { 0: [-20, 190], 1: [-20, 150], 2: [-10, 50], 3: [-10, 0] },
    SAN: { 0: [0, 55], 1: [0, 15], 2: [0, 0], 3: [-16, -10] },
    SL2: { 0: [0, 0], 1: [0, 460] },
    MES: { 0: [0, 0], 1: [0, 100] },
    MIS: { 0: [0, -60], 1: [0, -25] },
    PIT: { 0: [0, 0], 1: [0, 85] },
    MTY: { 0: [0, -60], 1: [0, 10] },
    OKC: { 0: [0, 0], 1: [-10, 80] },
    MXC: { 0: [-5, 40], 1: [-5, 0] },
  };
  return positions[getLocalStorageItem("locationCode")][index];
};

export const findDockDoorPosition = (plantConfig) => {
  let dockConfig = plantConfig?.indoorMap["dockDoors"];
  return dockConfig.position;

  // let dockDoorPosition = {
  //   CAR: "DOWN",
  //   ATL: "UP",
  //   DET: "UP",
  //   ALA: "UP",
  //   HAZ: "UP",
  //   HOU: "UP",
  //   LAS: "UP",
  //   MIA: "DOWN",
  //   TAC: "UP",
  //   JAX: "DOWN",
  //   LAN: "DOWN",
  //   ST3: "UP",
  //   BAL: "UP",
  //   KC2: "UP",
  //   MOR: "UP",
  //   TEM: "DOWN",
  //   LOU: "DOWN",
  //   BAY: "UP",
  //   RCH: "UP",
  //   COL: "UP",
  //   KNC: "UP",
  //   BLM: "UP",
  //   KEN: "UP",
  //   SAN: "DOWN",
  //   SL2: "DOWN",
  //   MES: "UP",
  //   MIS: "UP",
  //   PIT: "UP",
  //   MTY: "DOWN",
  //   OKC: "UP",
  //   MXC: "UP",
  // };
  // return dockDoorPosition[getLocalStorageItem("locationCode")];
};

export const lgvCoordinateCorrection = () => {
  let lgvCorrection = {
    CAR: { x: -120, y: 0 },
    ATL: { x: 0, y: 327 },
    DET: { x: 0, y: 510 },
    ALA: { x: -355, y: 300 },
    HAZ: { x: 30, y: 295 },
    HOU: { x: 25, y: 450 },
    LAS: { x: 35, y: 398 },
    MIA: { x: 0, y: 525 },
    TAC: { x: -240, y: 353 },
    JAX: { x: -85, y: 222 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -75, y: 298 },
    BAL: { x: -779, y: -890 },
    KC2: { x: -160, y: 295 },
    MOR: { x: -180, y: 210 },
    TEM: { x: -80, y: 50 },
    LOU: { x: -360, y: 370 },
    BAY: { x: 40, y: 800 },
    RCH: { x: -10, y: 220 },
    COL: { x: -203, y: 990 },
    KNC: { x: 30, y: 65 },
    BLM: { x: 63, y: -13 },
    KEN: { x: 165, y: 0 },
    SAN: { x: 80, y: 30 },
    SL2: { x: -240, y: -952 },
    MES: { x: -20, y: -68 },
    MIS: { x: -38, y: 125 },
    PIT: { x: -765, y: -1444 },
    MTY: { x: -115, y: 15 },
    OKC: { x: 160, y: 85 },
    MXC: { x: -20, y: 95 },
  };
  return lgvCorrection[getLocalStorageItem("locationCode")];
};

export const heatmapCoordinateCorrection = () => {
  let heatmapCorrection = {
    CAR: { x: 35, y: -35 },
    ATL: { x: 195, y: 280 },
    DET: { x: 170, y: 280 },
    ALA: { x: -255, y: 275 },
    HAZ: { x: 100, y: 265 },
    HOU: { x: 70, y: 450 },
    LAS: { x: 90, y: 366 },
    MIA: { x: 30, y: 505 },
    TAC: { x: -225, y: 333 },
    JAX: { x: -65, y: 202 },
    LAN: { x: -100, y: -40 },
    ST3: { x: -65, y: 278 },
    BAL: { x: -760, y: -945 },
    KC2: { x: -160, y: 260 },
    MOR: { x: -200, y: 100 },
    TEM: { x: -20, y: 490 },
    LOU: { x: -260, y: 350 },
    BAY: { x: 170, y: 810 },
    COL: { x: -200, y: 180 },
    RCH: { x: 100, y: 290 },
    KNC: { x: 125, y: 545 },
    BLM: { x: 70, y: 520 },
    SAN: { x: 280, y: 160 },
    SL2: { x: -180, y: -140 },
    KEN: { x: 70, y: 295 },
    MES: { x: 130, y: 290 },
    MIS: { x: 50, y: 540 },
    PIT: { x: -470, y: -1160 },
    MTY: { x: 50, y: -450 }, // x decrease T->B  y  decrease R->L
    OKC: { x: 180, y: 470 },
    MXC: { x: 120, y: 450 },
  };
  return heatmapCorrection[getLocalStorageItem("locationCode")];
};

export const destinationPointersCoordinateCorrection = (actionObject) => {
  let sourcePointersCorrection = {
    CAR: { x: -83, y: -30 },
    ATL: { x: 38, y: 300 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 265 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 266 },
    LAS: { x: 70, y: 368 },
    MIA: { x: 35, y: 498 },
    TAC: { x: -202, y: 325 },
    JAX: { x: -50, y: 190 },
    LAN: { x: -60, y: -46 },
    ST3: { x: -35, y: 270 },
    BAL: { x: -745, y: -920 },
    KC2: { x: -125, y: 270 },
    MOR: { x: -146, y: 180 },
    TEM: { x: -40, y: 19 },
    LOU: { x: -320, y: 345 },
    BAY: { x: 80, y: 776 },
    RCH: { x: 30, y: 195 },
    COL: { x: -167, y: 965 },
    KNC: { x: 70, y: 30 },
    BLM: { x: 105, y: -45 },
    KEN: { x: 200, y: -30 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -203, y: -975 },
    MES: { x: 17, y: -105 },
    MIS: { x: -90, y: 96 },
    PIT: { x: -730, y: -1475 },
    MTY: { x: -75, y: -15 },
    OKC: { x: 195, y: 55 },
    MXC: { x: 16, y: 72 },
  };
  let destinationPointersCorrection = {
    CAR: { x: -20, y: -35 },
    ATL: { x: 0, y: 300 },
    DET: { x: 0, y: 480 },
    ALA: { x: -350, y: 270 },
    HOU: { x: 20, y: 420 },
    HAZ: { x: 70, y: 250 },
    LAS: { x: 20, y: 370 },
    MIA: { x: 70, y: 500 },
    TAC: { x: -250, y: 320 },
    JAX: { x: -50, y: 195 },
    LAN: { x: -60, y: -40 },
    ST3: { x: -100, y: 265 },
    BAL: { x: -795, y: -925 },
    TEM: { x: -55, y: 22 },
    KC2: { x: -167, y: 268 },
    MOR: { x: -150, y: 175 },
    LOU: { x: -275, y: 350 },
    BAY: { x: 20, y: 770 },
    RCH: { x: -20, y: 190 },
    COL: { x: -230, y: 960 },
    KNC: { x: 65, y: 40 },
    BLM: { x: 100, y: -46 },
    KEN: { x: 150, y: -27 },
    SAN: { x: 135, y: 0 },
    SL2: { x: -130, y: -980 },
    MES: { x: 17, y: -100 },
    MIS: { x: -100, y: 90 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -30, y: -10 },
    OKC: { x: 180, y: 55 },
    MXC: { x: 0, y: 65 },
  };
  let otherDestinationPointersCorrection = {
    CAR: { x: -90, y: -30 },
    ATL: { x: 44, y: 298 },
    DET: { x: 50, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 375 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -40, y: -60 },
    ST3: { x: -100, y: 200 },
    BAL: { x: -855, y: -885 },
    TEM: { x: -45, y: 25 },
    KC2: { x: -127, y: 280 },
    MOR: { x: -145, y: 176 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 947 },
    KNC: { x: 65, y: 10 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -50 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 90 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -75, y: -15 },
    OKC: { x: 180, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let tslDestinationPointersCorrection = {
    CAR: { x: -90, y: -30 },
    ATL: { x: 37, y: 290 },
    DET: { x: 30, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 375 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -60, y: -40 },
    ST3: { x: -100, y: 200 },
    BAL: { x: -745, y: -910 },
    TEM: { x: -42, y: 55 },
    KC2: { x: -107, y: 260 },
    MOR: { x: -130, y: 268 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 65, y: 10 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let hindOffDestinationPointersCorrection = {
    CAR: { x: -80, y: -70 },
    ATL: { x: 110, y: 310 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 400 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 520 },
    TAC: { x: -200, y: 260 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -40, y: 360 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 65, y: 10 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };
  //G.W
  let awOffDestinationPointersCorrection = {
    CAR: { x: -90, y: -40 },
    ATL: { x: 37, y: 305 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 50, y: 500 },
    TAC: { x: -230, y: 400 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    TEM: { x: -45, y: 10 },
    ST3: { x: -40, y: 275 },
    BAL: { x: -745, y: -910 },
    KC2: { x: 330, y: -921 },
    MOR: { x: -146, y: 295 },
    LOU: { x: -320, y: 320 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 65, y: 10 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1465 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let hwDestinationPointersCorrection = {
    CAR: { x: -85, y: -10 },
    ATL: { x: 37, y: 282 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    TEM: { x: -45, y: 25 },
    ST3: { x: -40, y: 265 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 345 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let ceDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -202, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -65, y: -40 },
    ST3: { x: -40, y: 260 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 203 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -720, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let jwDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    ST3: { x: -30, y: 270 },
    LAN: { x: -100, y: -10 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let ssrDestination = {
    CAR: { x: -75, y: 15 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 80, y: 420 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -40, y: 265 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 540, y: 400 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let ewDestinationPointersCorrection = {
    CAR: { x: -85, y: -10 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: -200, y: 100 },
    HAZ: { x: 70, y: 259 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    ST3: { x: 20, y: 320 },
    LAN: { x: -40, y: -10 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -39, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: -100 },
    MOR: { x: -146, y: 170 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    SL2: { x: -200, y: -990 },
    KEN: { x: 200, y: -32 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let strDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 350 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 330 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -20 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let sorDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 80, y: 430 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -30, y: 290 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: 330, y: -530 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let iwDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 305 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 180 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -35, y: 270 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let battDestinationPointersCorrection = {
    CAR: { x: 100, y: -30 },
    ATL: { x: 110, y: 340 },
    DET: { x: 110, y: 340 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -250, y: 320 },
    JAX: { x: -50, y: 185 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let aeDestinations = {
    CAR: { x: -90, y: 8 },
    ATL: { x: 37, y: 310 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -210, y: 320 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 90 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let rdrkDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -45, y: 25 },
    ST3: { x: -45, y: 200 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -765, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let awDestinations = {
    CAR: { x: -80, y: -20 },
    ATL: { x: 37, y: 292 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 365 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -203, y: 420 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -35, y: 330 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -120, y: 260 },
    MOR: { x: -146, y: 160 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 100 },
    PIT: { x: -725, y: -1450 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let beDestinations = {
    CAR: { x: -80, y: -8 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 68, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 180 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -39, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let nsrDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 50, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 280 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let deDestinations = {
    CAR: { x: -85, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 270 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -40, y: -10 },
    ST3: { x: -35, y: 270 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 90 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -75, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let cwgldDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    MES: { x: 17, y: -100 },
    SAN: { x: 120, y: 0 },
    MIS: { x: -90, y: 120 },
    SL2: { x: -200, y: -990 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let srbeDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 460 },
    TAC: { x: -200, y: 260 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let bwdDestination = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 365 },
    MIA: { x: 35, y: 500 },
    ST3: { x: -40, y: 300 },
    TAC: { x: -205, y: 300 },
    LAN: { x: -65, y: -30 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let jedDestinationPointersCorrection = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -100, y: 200 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -100, y: 150 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let heDestinations = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 58, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    ST3: { x: -45, y: 260 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: 0, y: 0 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -75, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let rspDestination = {
    CAR: { x: -90, y: 20 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 485 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 40, y: 520 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -200, y: 310 },
    JAX: { x: -50, y: 185 },
    LAN: { x: -100, y: -10 },
    TEM: { x: -45, y: 25 },
    ST3: { x: -32, y: 290 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let cwDestinationPointersCorrection = {
    CAR: { x: -80, y: 0 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 260 },
    LAS: { x: 75, y: 370 },
    MIA: { x: 35, y: 510 },
    TAC: { x: -200, y: 350 },
    JAX: { x: -50, y: 185 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -35, y: 270 },
    BAL: { x: -745, y: -910 },
    TEM: { x: -42, y: 55 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 955 },
    KNC: { x: 70, y: 35 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -100, y: 120 },
    PIT: { x: -730, y: -1465 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 20, y: 80 },
  };

  let dwDestination = {
    CAR: { x: -85, y: 0 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    JAX: { x: -50, y: 185 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -37, y: 280 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -135, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1450 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let fwDestinationPointersCorrection = {
    CAR: { x: -85, y: -30 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 490 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 370 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -205, y: 380 },
    JAX: { x: -45, y: 185 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -35, y: 275 },
    TEM: { x: -39, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -117, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 35 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1480 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let geDestinationPointersCorrection = {
    CAR: { x: -85, y: -45 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 360 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -205, y: 380 },
    JAX: { x: -50, y: 185 },
    LAN: { x: 0, y: 0 },
    ST3: { x: 0, y: 0 },
    BAL: { x: -745, y: -910 },
    TEM: { x: -38, y: 55 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 80, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -75, y: -25 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let feDestinationPointersCorrection = {
    CAR: { x: -85, y: -80 },
    ATL: { x: 37, y: 302 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 75, y: 360 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: 100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -745, y: -910 },
    TEM: { x: -39, y: 55 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -150, y: 200 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 65, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -725, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let ieDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: 100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -745, y: -910 },
    TEM: { x: -45, y: 25 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 350 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 45 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -730, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let rwrkDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 50, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: 330, y: -530 },
    MOR: { x: -125, y: 215 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -50 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: 20 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -600, y: -1400 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let dgldDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 0, y: 0 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let l1DestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 320 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    TEM: { x: -45, y: 25 },
    BAL: { x: -745, y: -910 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let l3DestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 60, y: 360 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -745, y: -910 },
    TEM: { x: -42, y: 55 },
    KC2: { x: -120, y: 260 },
    MOR: { x: -138, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let dnDestination = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 70, y: 370 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -725, y: -990 },
    TEM: { x: -45, y: 25 },
    KC2: { x: -124, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -45, y: -10 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let eedDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 37, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 70, y: 370 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 30, y: -50 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -725, y: -990 },
    TEM: { x: -39, y: 35 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -85, y: -30 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let norDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 20, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 70, y: 370 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -725, y: -990 },
    TEM: { x: -42, y: 55 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 950 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  let keDestinationPointersCorrection = {
    CAR: { x: -83, y: -40 },
    ATL: { x: 20, y: 290 },
    DET: { x: 40, y: 480 },
    ALA: { x: -318, y: 260 },
    HOU: { x: 60, y: 420 },
    HAZ: { x: 70, y: 267 },
    LAS: { x: 70, y: 370 },
    MIA: { x: 35, y: 500 },
    TAC: { x: -205, y: 380 },
    LAN: { x: 0, y: 0 },
    ST3: { x: -100, y: 300 },
    JAX: { x: -50, y: 185 },
    BAL: { x: -725, y: -990 },
    TEM: { x: -42, y: 55 },
    KC2: { x: -112, y: 260 },
    MOR: { x: -146, y: 195 },
    LOU: { x: -320, y: 335 },
    BAY: { x: 70, y: 770 },
    RCH: { x: 28, y: 180 },
    COL: { x: -167, y: 960 },
    KNC: { x: 70, y: 25 },
    BLM: { x: 105, y: -53 },
    KEN: { x: 200, y: -75 },
    SAN: { x: 120, y: 0 },
    SL2: { x: -200, y: -990 },
    MES: { x: 17, y: -100 },
    MIS: { x: -90, y: 120 },
    PIT: { x: -735, y: -1470 },
    MTY: { x: -65, y: -20 },
    OKC: { x: 195, y: 60 },
    MXC: { x: 16, y: 80 },
  };

  if (actionObject === "lgv") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        destinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "batt") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        battDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "hind") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        hindOffDestinationPointersCorrection[
          getLocalStorageItem("locationCode")
        ],
    };
  } else if (actionObject === "aed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: aeDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "awd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: awDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "bed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: beDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "jwd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        jwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "jed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        jedDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ewd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        ewDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "rdrk") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        rdrkDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "nsrd") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: nsrDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "gw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        awOffDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "cwgld") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: cwgldDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "srbe") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: srbeDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "hw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        hwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "hed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: heDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "sr") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        strDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ce") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        ceDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "iw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        iwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "sor") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        sorDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "nor") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        norDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ded") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: deDestinations[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "bw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: bwdDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ssr") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: ssrDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "rsp") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: rspDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "dw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: dwDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "dn") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination: dnDestination[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "fw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        fwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "tsl") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        tslDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ge") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        geDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "cw") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        cwDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "fe") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        feDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ie") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        ieDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "rwrk") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        rwrkDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "dgld") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        dgldDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "l1") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        l1DestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "l3") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        l3DestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "eed") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        eedDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else if (actionObject === "ke") {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        keDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  } else {
    return {
      source: sourcePointersCorrection[getLocalStorageItem("locationCode")],
      destination:
        otherDestinationPointersCorrection[getLocalStorageItem("locationCode")],
    };
  }
};

export const expireAuthToken = () => {
  setLocalStorageItem("isAuthExpiry", true);
  localStorage.clear();
  reloadApp();
};

export const getAvailablePlant = (locationCode) => {
  const availablePlantList = [
    "CAR",
    "ATL",
    "DET",
    "ALA",
    "HAZ",
    "HOU",
    "LAS",
    "MIA",
    "TAC",
    "JAX",
    "LAN",
    "ST3",
    "BAL",
    "KC2",
    "MOR",
    "TEM",
    "LOU",
    "RCH",
    "BAY",
    "COL",
    "KNC",
    "BLM",
    "KEN",
    "SAN",
    "SL2",
    "MES",
    "MIS",
    "PIT",
    "MTY",
    "OKC",
    "MXC",
  ];
  if (availablePlantList.includes(locationCode)) {
    return true;
  } else {
    return false;
  }
};
/**
 * Up to Down
 * Increase the count, go down
 * Decrease the count, go up
 * Left to Right
 * Increase to count, go
 * Decrease the count, go
 */

export const getPlantCoordinates = (plant, renderObject) => {
  if (plant === "CAR") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 4500,
        rectTopRightX: 553500,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 453500,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 14500,
        rectTopRightX: 455000,
        rectTopRightY: 168000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 450000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "ATL") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 78000,
        rectBottomLeftY: 12000,
        rectTopRightX: 533000,
        rectTopRightY: 195000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: 32000, //T->B
        rectTopRightX: 472000, //R->L shrink
        rectTopRightY: 153500, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -1300,
        rectBottomLeftY: 2000,
        rectTopRightX: 384000,
        rectTopRightY: 125300,
      };
    } else {
      return {
        //map
        rectBottomLeftX: 47000, //15000
        rectBottomLeftY: 13000, //333000
        rectTopRightX: 523000, //0
        rectTopRightY: 171000, //143000
      };
    }
  } else if (plant === "DET") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 80000,
        rectBottomLeftY: 12000,
        rectTopRightX: 473000,
        rectTopRightY: 195000,
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 14000, // decreasing L->R
        rectBottomLeftY: -4500, // shrink B->T 1000
        rectTopRightX: 334700,
        rectTopRightY: 145500, // increase T->B 146000
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 127000, //R->L
        rectBottomLeftY: 36000, //T->B
        rectTopRightX: 449000, //R->L shrink
        rectTopRightY: 170000, // T->B shrink
      };
    } else {
      return {
        //map
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "ALA") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: -20000, //T->B
        rectTopRightX: 415000, //R->L shrink
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 105000, //R->L
        rectBottomLeftY: 38000, //T->B
        rectTopRightX: 410000, //R->L shrink
        rectTopRightY: 188500, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 42500,
        rectBottomLeftY: 108000,
        rectTopRightX: 339000,
        rectTopRightY: 267000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 20000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "HAZ") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 237000, //R->L
        rectBottomLeftY: 200000, //T->B
        rectTopRightX: 902000, //R->L shrink
        rectTopRightY: 450000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 207000, //R->L
        rectBottomLeftY: 270000, //T->B
        rectTopRightX: 642000, //R->L shrink
        rectTopRightY: 470000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 116000,
        rectBottomLeftY: 212500,
        rectTopRightX: 555000,
        rectTopRightY: 410000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
    // return {
    //   rectBottomLeftX: 116000,
    //   rectBottomLeftY: 214000,
    //   rectTopRightX: 555000,
    //   rectTopRightY: 410000,
    // };
  } else if (plant === "HOU") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 108000, //R->L
        rectBottomLeftY: -9000, //T->B
        rectTopRightX: 522000, //R->L shrink
        rectTopRightY: 131000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -2500,
        rectBottomLeftY: 0,
        rectTopRightX: 376900,
        rectTopRightY: 118000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 116500, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 495000, //R->L shrink
        rectTopRightY: 151000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "LAS") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 84000, //R->L
        rectBottomLeftY: -16000, //T->B
        rectTopRightX: 392000, //R->L shrink
        rectTopRightY: 152000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 19000,
        rectBottomLeftY: -53000,
        rectTopRightX: 317000,
        rectTopRightY: 210000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 370000, //R->L shrink
        rectTopRightY: 169000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "MIA") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: -5000, //T->B
        rectTopRightX: 380000, //R->L shrink
        rectTopRightY: 165000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 81000, //R->L
        rectBottomLeftY: 33000, //T->B
        rectTopRightX: 355000, //R->L shrink
        rectTopRightY: 190000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -25000,
        rectBottomLeftY: 136000,
        rectTopRightX: 250000,
        rectTopRightY: -25000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "TAC") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 90000, //R->L
        rectBottomLeftY: -30000, //T->B
        rectTopRightX: 315000, //R->L shrink
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 45000, //R->L
        rectBottomLeftY: 17500, //T->B
        rectTopRightX: 401000, //R->L shrink
        rectTopRightY: 167000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -40000,
        rectBottomLeftY: 55000,
        rectTopRightX: 310000,
        rectTopRightY: 208000,
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "JAX") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: -50000, //T->B
        rectTopRightX: 565000, //R->L shrink
        rectTopRightY: 170000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 70000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 465000, //R->L shrink
        rectTopRightY: 200000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 11000,
        rectBottomLeftY: 8500,
        rectTopRightX: 409000,
        rectTopRightY: 185000,
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "LAN") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: -18000, //R->L
        rectBottomLeftY: -75000, //T->B
        rectTopRightX: 568000, //R->L shrink
        rectTopRightY: 150000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -1500, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 525000, //R->L shrink
        rectTopRightY: 245000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "ST3") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 70000, //R->L
        rectBottomLeftY: -100000, //T->B
        rectTopRightX: 510500, //R->L shrink
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 80000, //R->L
        rectBottomLeftY: 20500, //T->B
        rectTopRightX: 462500, //R->L shrink
        rectTopRightY: 165000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 1000,
        rectBottomLeftY: 5000,
        rectTopRightX: 383000,
        rectTopRightY: 150000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BAL") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 70000, //R->L
        rectBottomLeftY: -145000, //T->B
        rectTopRightX: 470000, //R->L shrink BAL
        rectTopRightY: 100000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 93000, //R->L
        rectBottomLeftY: 1500, //T->B
        rectTopRightX: 463500, //R->L shrink BAL
        rectTopRightY: 169000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "KC2") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 91000, //R->L
        rectBottomLeftY: -58000, //T->B
        rectTopRightX: 470500, //R->L shrink KC2
        rectTopRightY: 140000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 91500, //R->L
        rectBottomLeftY: 1500, //T->B
        rectTopRightX: 459900, //R->L shrink BAL
        rectTopRightY: 169000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "MOR") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 63000, //R->L
        rectBottomLeftY: -90000, //T->B
        rectTopRightX: 510000, //R->L shrink BAL
        rectTopRightY: 134000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 78500, //R->L
        rectBottomLeftY: 1800, //T->B
        rectTopRightX: 499500, //R->L shrink BAL
        rectTopRightY: 168500, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 0, //R->L
        rectBottomLeftY: 0, //T->B
        rectTopRightX: 0, //R->L shrink
        rectTopRightY: 0, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 529700,
        rectTopRightY: 161000,
      };
    }
  } else if (plant === "TEM") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 85000, //R->L
        rectBottomLeftY: -90000, //T->B
        rectTopRightX: 520000, //R->L shrink BAL
        rectTopRightY: 120000, // T->B shrink
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -46800, //R->L
        rectBottomLeftY: -55500, //T->B
        rectTopRightX: 350500, //R->L shrink TEM
        rectTopRightY: 121000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 0, //R->L
        rectBottomLeftY: 0, //T->B
        rectTopRightX: 0, //R->L shrink
        rectTopRightY: 0, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 161000,
      };
    }
  } else if (plant === "LOU") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 82000,
        rectBottomLeftY: 15500,
        rectTopRightX: 463000,
        rectTopRightY: 148000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 92000, //R->L
        rectBottomLeftY: 13000, //T->B
        rectTopRightX: 445000, //R->L shrink LOU
        rectTopRightY: 148500, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BAY") {
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 100000,
        rectBottomLeftY: -15500,
        rectTopRightX: 433000,
        rectTopRightY: 170000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 93000, //R->L
        rectBottomLeftY: 500, //T->B
        rectTopRightX: 425000, //R->L shrink BAY
        rectTopRightY: 175000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -96000,
        rectBottomLeftY: -55800,
        rectTopRightX: 240000,
        rectTopRightY: 125000,
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "KNC") {
    //KNC PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: 5000,
        rectTopRightX: 403000,
        rectTopRightY: 218000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 14000, //R->L
        rectBottomLeftY: 55500, //T->B
        rectTopRightX: 300000, //R->L shrink
        rectTopRightY: 212000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "COL") {
    // current
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 82000,
        rectBottomLeftY: -40500,
        rectTopRightX: 343000,
        rectTopRightY: 120000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 303800, //R->L
        rectBottomLeftY: 13000, //T->B
        rectTopRightX: 675000, //R->L shrink RCH
        rectTopRightY: 150000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 34000,
        rectTopRightX: 425000,
        rectTopRightY: 176000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "RCH") {
    if (renderObject === "heatmap") {
      return {
        //RCH plant
        rectBottomLeftX: 91600,
        rectBottomLeftY: -7500,
        rectTopRightX: 508000,
        rectTopRightY: 148000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 61000, //R->L
        rectBottomLeftY: 24000, //T->B
        rectTopRightX: 462000, //R->L shrink RCH
        rectTopRightY: 159000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 0,
        rectBottomLeftY: -6000,
        rectTopRightX: 435000,
        rectTopRightY: 131000,
      };
    } else {
      return {
        rectBottomLeftX: 47000,
        rectBottomLeftY: 13000,
        rectTopRightX: 523000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "BLM") {
    //BLM PLANT CURRENT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 130000,
        rectBottomLeftY: 500,
        rectTopRightX: 430000,
        rectTopRightY: 168000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 14000, //R->L
        rectBottomLeftY: 56500, //T->B
        rectTopRightX: 317000, //R->L shrink BLM
        rectTopRightY: 190000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "KEN") {
    //KEN PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 74000,
        rectBottomLeftY: -2500,
        rectTopRightX: 415000,
        rectTopRightY: 138000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 1000, //R->L
        rectBottomLeftY: 67000, //T->B
        rectTopRightX: 342000, //R->L shrink KEN
        rectTopRightY: 170000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "SAN") {
    //SAN PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 57000,
        rectBottomLeftY: 35500,
        rectTopRightX: 505000,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 11000, //R->L
        rectBottomLeftY: 36500, //T->B
        rectTopRightX: 460000, //R->L shrink BLM
        rectTopRightY: 158000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 14000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "SL2") {
    //SL2 PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: 30500,
        rectTopRightX: 300000,
        rectTopRightY: 188000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -6500, //R->L
        rectBottomLeftY: 60000, //T->B
        rectTopRightX: 189500, //R->L shrink SL2
        rectTopRightY: 192000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 16000,
        rectTopRightX: 583000,
        rectTopRightY: 171000,
      };
    }
  } else if (plant === "MES") {
    //MES PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: 30500,
        rectTopRightX: 490000,
        rectTopRightY: 215000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 14000, //R->L
        rectBottomLeftY: 58500, //T->B
        rectTopRightX: 387000, //R->L shrink BLM
        rectTopRightY: 197000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: 29000,
        rectBottomLeftY: 30000,
        rectTopRightX: 405000,
        rectTopRightY: 173000,
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "MIS") {
    //MIS PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 120000,
        rectBottomLeftY: 13500,
        rectTopRightX: 450000,
        rectTopRightY: 228000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 27000, //R->L
        rectBottomLeftY: 50000, //T->B
        rectTopRightX: 410000, //R->L shrink MIS
        rectTopRightY: 220000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else if (renderObject === "block" || renderObject === "rack") {
      return {
        rectBottomLeftX: -8500,
        rectBottomLeftY: 16500,
        rectTopRightX: 380000,
        rectTopRightY: 197200,
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "PIT") {
    //PIT PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 65000,
        rectBottomLeftY: 0,
        rectTopRightX: 409000,
        rectTopRightY: 225000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: -12000, //R->L
        rectBottomLeftY: 56000, //T->B
        rectTopRightX: 328000, //R->L shrink PIT
        rectTopRightY: 220000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 10000,
        rectTopRightX: 590000,
        rectTopRightY: 231000,
      };
    }
  } else if (plant === "MTY") {
    //MTY PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 117000,
        rectBottomLeftY: 15500,
        rectTopRightX: 462000,
        rectTopRightY: 215000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 220000, //R->L
        rectBottomLeftY: 46000, //T->B
        rectTopRightX: 561500, //R->L shrink PIT
        rectTopRightY: 172000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "OKC") {
    //OKC PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 115000,
        rectBottomLeftY: 14500,
        rectTopRightX: 450000,
        rectTopRightY: 208000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 19000, //R->L
        rectBottomLeftY: 67700, //T->B
        rectTopRightX: 362500, //R->L shrink OKC
        rectTopRightY: 239500, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: 15000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  } else if (plant === "MXC") {
    //MIS PLANT
    if (renderObject === "heatmap") {
      return {
        rectBottomLeftX: 110000,
        rectBottomLeftY: -20500,
        rectTopRightX: 440000,
        rectTopRightY: 268000,
      };
    } else if (renderObject === "lgv") {
      return {
        rectBottomLeftX: 28000, //R->L
        rectBottomLeftY: 66000, //T->B
        rectTopRightX: 351000, //R->L shrink MXC
        rectTopRightY: 262000, // T->B shrink
      };
    } else if (renderObject === "destinations") {
      return {
        rectBottomLeftX: 88000, //R->L
        rectBottomLeftY: -42500, //T->B
        rectTopRightX: -292000, //R->L shrink
        rectTopRightY: -450000, // T->B shrink
      };
    } else {
      return {
        rectBottomLeftX: 50000,
        rectBottomLeftY: -42000,
        rectTopRightX: 583000,
        rectTopRightY: 191000,
      };
    }
  }
};

export const getBottomTopCoordinates = () => {
  // return {
  //     rectBottomLeftXNew: 0,
  //     rectBottomLeftYNew: 594.453125,
  //     rectTopRightXNew: 1446,
  //     rectTopRightYNew: 5.453125
  // }
  return {
    rectBottomLeftXNew: 0,
    rectBottomLeftYNew: 500,
    rectTopRightXNew: 1446,
    rectTopRightYNew: 5.453125,
  };
};

export const isKafkaDataAvailable = () => {
  let plantAvailable = {
    CAR: false, //Not Available
    ATL: false,
    DET: false,
    ALA: false,
    HAZ: false,
    HOU: false,
    LAS: false,
    MIA: false,
    TAC: false,
    JAX: false,
    LAN: false,
    ST3: false,
    BAL: false,
    KC2: false,
    MOR: false,
    TEM: false,
    LOU: false,
    BAY: false,
    COL: false,
    KNC: false,
    BLM: false,
    KEN: false,
    SAN: false,
    SL2: false,
    MES: false,
    MIS: false,
    OKC: false,
    MXC: false,
  };
  return plantAvailable[getLocalStorageItem("locationCode")];
};

export const getPlantStates = (shortName) => {
  let plantList = {
    CAR: "Carolina",
    ATL: "Atlanta",
    DET: "Detroit",
    ALA: "Alabama",
    HAZ: "Hazleton",
    HOU: "Houston",
    LAS: "Las Vegas",
    MIA: "Miami",
    TAC: "Tacoma",
    JAX: "Jacksonville",
    LAN: "Lancaster",
    ST3: "Stockton 3",
    BAL: "Baltimore",
    KC2: "Kansas City 2",
    MOR: "Morocco",
    TEM: "Temple",
    LOU: "Louisville",
    BAY: "Baytown",
    RCH: "Richmond",
    COL: "Columbus",
    KNC: "Kansas City",
    BLM: "Bloomfield",
    KEN: "Kenosha",
    SAN: "San Antonio",
    SL2: "Salt Lake City 2",
    MES: "Mesa",
    MIS: "Mississippi",
    PIT: "Pittsburgh",
    MTY: "Monterrey",
    OKC: "Oklahoma City",
    MXC: "Mexico City",
  };
  return plantList[shortName];
};

export const zoomInHotPress = (keyPosition) => {
  document.getElementById(
    "indoor-map-id-reference"
  ).style.transform = `scale(1.6) translate(${keyPosition.y}px, ${keyPosition.x}px)`;
};

export const zoomOutHotPress = () => {};

// export const getBlockStoragePalletColor = () => {
//   const blockColorPallets = {
//     empty: "#B9B9B9",
//     occupiedSingle: "#42C721",
//     occupiedDouble: "#4133E1",
//     blockedDisabled: "#DDA0DD",
//     error: "#BF2E3A",
//   };
// };
export const filterDuplicateProductionLine = (lineData) => {
  if (lineData && lineData !== "Error connecting to datasource!") {
    const filteredObjects = lineData.reduce((accumulator, currentObject) => {
      const existingObject = accumulator.find(
        (obj) =>
          obj.LINE_NAME === currentObject.LINE_NAME &&
          obj.LINE === currentObject.LINE
      );

      if (
        !existingObject ||
        (currentObject.STATUS === "Finishing" &&
          existingObject.STATUS !== "Finishing")
      ) {
        return [
          ...accumulator.filter(
            (obj) =>
              !(
                obj.LINE_NAME === currentObject.LINE_NAME &&
                obj.LINE === currentObject.LINE
              )
          ),
          currentObject,
        ];
      }

      return accumulator;
    }, []);
    return filteredObjects;
  }
};

export const lgvNameRenderer = (vehicleNumber) => {
  if (vehicleNumber.includes("LGV")) {
    return vehicleNumber.split("V")[1];
  } else {
    return vehicleNumber;
  }
};

export const removeLgvs = () => {
  let lgvLayer = document.querySelectorAll("span.lgv-icon-style");
  for (let i = 0; i < lgvLayer.length; i++) {
    lgvLayer[i].remove();
  }
};

export const removeExistingProductionLines = () => {
  let productionLayerHolder = document.querySelectorAll(
    "div.line-prod-container"
  );
  productionLayerHolder.forEach((layer) => {
    layer.remove();
  });
};

export const removeDockDoorLayer = () => {
  let dockDoorLayer = document.querySelectorAll("div.dock-door");
  for (let i = 0; i < dockDoorLayer.length; i++) {
    dockDoorLayer[i].remove();
  }
};

export const trafficLgvDataGrouping = (trafficData) => {
  let trafficObject = {};
  trafficData.forEach((data, index) => {
    let objectName = data.LGV_ID;
    if (!trafficObject[objectName]) {
      trafficObject[objectName] = [];
    }
    trafficObject[objectName].push(data);
  });
  return trafficObject;
};

export const mapCoordinate = (
  coordinateX,
  coordinateY,
  renderObject,
  bounds
) => {
  let locationCode = getLocalStorageItem("locationCode");
  if (locationCode) {
    let startX;
    let startY;
    let endX;
    let endY;

    if (bounds !== undefined) {
      startX = bounds.startX;
      startY = bounds.startY;
      endX = bounds.endX;
      endY = bounds.endY;
    } else {
      const { rectBottomLeftX, rectBottomLeftY, rectTopRightX, rectTopRightY } =
        getPlantCoordinates(locationCode, renderObject);
      startX = rectBottomLeftX;
      startY = rectBottomLeftY;
      endX = rectTopRightX;
      endY = rectTopRightY;
    }

    const {
      rectBottomLeftXNew,
      rectBottomLeftYNew,
      rectTopRightXNew,
      rectTopRightYNew,
    } = getBottomTopCoordinates();

    let ratioX = (coordinateX - startX) / (endX - startX);
    let ratioY = (coordinateY - startY) / (endY - startY);

    let translatedX =
      rectBottomLeftXNew + ratioX * (rectTopRightXNew - rectBottomLeftXNew);
    let translatedY =
      rectBottomLeftYNew + ratioY * (rectTopRightYNew - rectBottomLeftYNew);

    return {
      x: translatedX,
      y: translatedY,
    };
  }
};

export const convertReactComponentToHtmlIcon = (
  reactComponent,
  className,
  iconSize,
  iconAnchor
) => {
  const reactToHtml = ReactDOMServer.renderToString(reactComponent);
  return L.divIcon({
    className: className,
    iconSize: iconSize,
    iconAnchor: iconAnchor ? iconAnchor : null,
    html: reactToHtml,
  });
};

export const convertReactComponentToStringHtml = (reactComponent) => {
  return ReactDOMServer.renderToString(reactComponent);
};

export const convertTimestampIntoHHMM = (timeStamp) => {
  if (!timeStamp) return "";
  let date = timeStamp.split("T");
  let time = date[1].split("Z");
  return `${date[0]} ${time[0]}`.split(":").slice(0, 2).join(":");
};

//Combine Kafka LGV data and Table LGV data
export const combineKafkaSocketAndLGVDetailsSocket = (
  lgvDetails,
  kafkaDetails
) => {
  let filteredData = kafkaDetails.filter(
    (lgv) => lgv.latitude !== "undefined" && lgv.longitude !== "undefined"
  );
  if (filteredData !== "NO_DATA" || filteredData !== undefined) {
    lgvDetails.forEach((lgv) => {
      filteredData.forEach((kafkaLgv) => {
        let lgvNumber = kafkaLgv.vehicleNumber.split("V")[1];
        if (lgv.vehicleNumber === lgvNumber) {
          let currentPos = {
            lat: kafkaLgv.latitude,
            lng: kafkaLgv.longitude,
          };
          lgv.currentPos = currentPos;
        }
      });
    });
    return lgvDetails;
  } else {
    return lgvDetails;
  }
};

export const checkIsLocationCodeValid = (locationCode, locationCodeLocal) => {
  if (locationCode !== "" || locationCodeLocal !== null) {
    return true;
  } else {
    return false;
  }
};

export const checkIsTimeMachineEnabled = () => {
  if (getLocalStorageItem("isTimeMachineModeEnabled")) {
    return true;
  } else {
    return false;
  }
};

export const blockRackLocationExceptionList = () => {
  const plantList = {
    CAR: {
      rotation: false,
      highPriorityList: [
        "A.E.029",
        "C.E.021",
        "C.E.011",
        "I.E.005",
        "F.E.021",
        "F.E.011",
      ],
      asileList: [],
      asileLng: 0,
      lowPriorityList: ["B.E.029", "C.E.029"],
      commonList: ["D.E.021", "D.E.011", "G.E.001", "G.E.005", "A.E.011"],
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    DET: {
      rotation: true,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: 1000,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    ATL: {
      rotation: false,
      highPriorityList: ["D.E.019"],
      lowPriorityList: ["D.W.019"],
      commonList: ["E.W.020", "E.W.021"],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 40,
      lowPriorityLng: 43,
      commonLng: -50,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    ALA: {
      rotation: true,
      highPriorityList: ["G.E.042"],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 70,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    HAZ: {
      rotation: false,
      highPriorityList: ["B.E.031", "D.E.031"],
      lowPriorityList: ["F.E.042"],
      commonList: ["K.S.019"],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 30,
      lowPriorityLng: 40,
      commonLng: 40,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    LAS: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    HOU: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      commonList: ["B.W.004", "B.W.005", "B.W.008", "B.W.009"],
      highPriorityLng: 2015,
      lowPriorityLng: -45,
      commonLng: 82,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    JAX: {
      rotation: false,
      highPriorityList: ["J.W.039"],
      lowPriorityList: ["C.E.035", "C.E.047", "J.E.041", "J.E.063", "J.E.051"],
      commonList: ["C.E.024"],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 90,
      lowPriorityLng: -10,
      commonLng: -53,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    BAY: {
      rotation: false,
      highPriorityList: [
        "A.E.076",
        "A.E.064",
        "A.E.052",
        "C.E.070",
        "C.E.058",
        "C.E.046",
        "D.E.041",
        "E.E.031",
        "D.E.058",
        "D.E.070",
      ],
      lowPriorityList: ["B.E.058", "B.E.046", "D.E.047", "B.E.070"],
      commonList: ["F.E.031", "F.E.037", "G.E.037", "G.E.031"],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: -5,
      lowPriorityLng: 8,
      commonLng: -28,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    MIA: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    SL2: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    ST3: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    //New set of plant to verify Vertical Racks
    TAC: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    COL: {
      rotation: false,
      highPriorityList: [
        "F.E.001",
        "F.E.002",
        "F.E.003",
        "F.E.004",
        "F.E.005",
        "F.E.006",
        "F.E.007",
        "F.E.008",
        "F.E.009",
        "F.E.010",
        "F.E.011",
        "F.E.012",
        "F.E.013",
        "F.E.014",
        "F.E.015",
        "F.E.016",
        "H.E.017",
        "H.E.018",
        "H.E.019",
      ],
      lowPriorityList: [
        "D.E.001",
        "D.E.002",
        "D.E.003",
        "D.E.004",
        "D.E.005",
        "D.E.006",
        "D.E.007",
        "D.E.008",
        "D.E.009",
        "D.E.010",
        "D.E.011",
        "D.E.012",
        "D.E.013",
        "D.E.014",
        "D.E.015",
        "D.E.016",
        "J.E.017",
        "J.E.018",
        "J.E.019",
        "J.E.020",
        "J.E.021",
        "J.E.022",
        "J.E.023",
        "J.E.024",
        "J.E.025",
        "J.E.026",
        "J.E.027",
        "J.E.028",
        "J.E.029",
        "J.E.030",
        "J.E.031",
        "J.E.032",
        "J.E.033",
        "J.E.034",
        "E.E.001",
        "E.E.002",
        "E.E.003",
        "E.E.004",
        "E.E.005",
        "E.E.006",
        "E.E.007",
        "E.E.008",
        "E.E.009",
        "E.E.010",
        "E.E.011",
        "E.E.012",
        "E.E.013",
        "E.E.014",
        "E.E.015",
        "E.E.016",
      ],
      commonList: [
        "K.E.017",
        "K.E.018",
        "K.E.019",
        "K.E.020",
        "K.E.021",
        "K.E.022",
        "K.E.023",
        "K.E.024",
        "K.E.025",
        "K.E.026",
        "K.E.027",
        "K.E.028",
        "K.E.029",
        "K.E.030",
        "K.E.031",
        "K.E.032",
        "K.E.033",
        "K.E.034",
        "I.E.017",
        "I.E.018",
        "I.E.019",
        "I.E.020",
        "I.E.021",
        "I.E.022",
        "I.E.023",
        "I.E.024",
        "I.E.025",
        "I.E.026",
        "I.E.027",
        "I.E.028",
        "I.E.029",
        "I.E.030",
        "I.E.031",
        "I.E.032",
        "I.E.033",
        "I.E.034",
        "C.E.007",
      ],
      asileList: [
        "H.W.017",
        "H.W.018",
        "H.W.019",
        "H.W.020",
        "H.W.021",
        "H.W.022",
        "H.W.023",
        "H.W.024",
        "H.W.025",
        "H.W.026",
        "H.W.027",
        "H.W.028",
        "H.W.029",
        "H.W.030",
        "H.W.031",
        "H.W.032",
        "H.W.033",
        "H.W.034",
      ],
      prodLocList: ["C.E.008", "C.E.009", "C.E.010", "C.E.011", "C.E.012"],
      highPriorityLng: -15,
      lowPriorityLng: -15,
      commonLng: -75,
      asileLng: 15,
      prodLocLng: -92,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    RCH: {
      rotation: false,
      highPriorityList: [
        "A.E.001",
        "A.E.002",
        "A.E.003",
        "A.E.004",
        "A.E.005",
        "A.E.006",
        "A.E.007",
        "A.E.008",
        "A.E.009",
        "A.E.010",
        "A.E.011",
        "A.E.012",
        "A.E.013",
        "A.E.014",
        "A.E.015",
        "A.E.016",
        "A.E.017",
        "A.E.018",
        "A.E.019",
        "A.E.020",
        "A.E.021",
        "A.E.022",
        "A.E.023",
        "A.E.024",
        "A.E.025",
        "A.E.026",
        "A.E.027",
        "A.E.028",
        "A.E.029",
        "A.E.030",
      ],
      lowPriorityList: [
        "B.W.001",
        "B.W.002",
        "B.W.003",
        "B.W.004",
        "B.W.005",
        "B.W.006",
        "B.W.007",
        "B.W.008",
        "B.W.009",
        "B.W.010",
        "B.W.011",
        "B.W.012",
        "B.W.013",
        "B.W.014",
        "B.W.015",
        "B.W.016",
        "B.W.017",
        "B.W.018",
        "B.W.019",
        "B.W.020",
        "B.W.021",
        "B.W.022",
        "B.W.023",
        "B.W.024",
        "B.W.025",
        "B.W.026",
        "B.W.027",
        "B.W.028",
        "B.W.029",
        "B.W.030",
      ],
      commonList: ["B.E.001", "G.E.034"],
      asileList: ["C.W.001"],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: -105,
      lowPriorityLng: -15,
      commonLng: -90,
      eastLocationLng: 35,
      westLocationLng: 0,
    },
    MES: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
    MIS: {
      rotation: false,
      highPriorityList: [],
      lowPriorityList: [],
      commonList: [],
      asileList: [],
      asileLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      prodLocList: [],
      prodLocLng: 0,
      highPriorityLng: 15,
      lowPriorityLng: -45,
      commonLng: -10,
      eastLocationLng: 35,
      westLocationLng: 35,
    },
  };
  return plantList[getLocalStorageItem("locationCode")];
};

export const qualityStatusBadgeRenderer = (status) => {
  switch (status.toLowerCase()) {
    case "available":
      return "available-pallet";
    case "hold":
      return "hold-pallet";
    case "manuallabel":
      return "manual-pallet";
    case "scrap":
      return "scrap-pallet";
    case "shipped":
      return "shipped-pallet";
    case "removed":
      return "removed-pallet";
    case "empsale":
      return "empsale-pallet";
    case "nonconform":
      return "non-conform-pallet";
    case "received":
      return "received-pallet";
    case "quality":
      return "quality-pallet";
    case "destruction":
      return "destruction-pallet";
    case "consumed":
      return "consumed-pallet";
    case "error":
      return "error-pallet";
    case "incubation":
      return "incubation-pallet";
    default:
      return "null-pallet";
  }
};

const rotatePalletTextBasedOnRotation = (angle) => {
  if (angle == -90) {
    return "pallet-rotate-negative-90";
  }
  if (angle == 90) {
    return "pallet-rotate-90";
  }
  return "";
};

export const formatTimestamp = (rawTimeStamp) => {
  return rawTimeStamp.split(" ").splice(0, 4).reverse().splice(0, 3).join(" ");
};

export const isBlockRackNonLgvEnabled = () => {
  const enabledPlants = [
    //Group 1
    "CAR",
    "ATL",
    "HOU",
    "JAX",
    "BAY",
    //Group 2
    "TAC",
    "COL",
    "RCH",
    "MES",
    "MIS",
    //Group 3
    "SAN",
    "MTY",
    "OKC",
    "SL2",
    "MIA",
    //Group 4
    "BLM",
    "KEN",
    "HAZ",
    "LAN",
    "PIT",
    "ST3",
    // "LAS",
    "ALA",
    "KNC",
  ];
  if (enabledPlants.includes(String(getLocalStorageItem("locationCode")))) {
    return true;
  } else {
    return false;
  }
};

export const getNonLgvPosition = () => {
  const plantsList = {
    CAR: [1630, 423],
    ATL: [50, 215],
    ALA: [1365, 110],
    HAZ: [495, 420],
    DET: [40, 163],
    LAS: [1630, 423],
    HOU: [140, 220],
    MIA: [1630, 423],
    BAY: [1370, 118],
    JAX: [1130, 53],
    SL2: [0, 0],
    ST3: [0, 0],
    //New set of plant to verify Vertical Racks
    TAC: [490, 165],
    COL: [0, 0],
    RCH: [20, 193],
    MES: [0, 0],
    MIS: [0, 0],
  };
  return plantsList[getLocalStorageItem("locationCode")];
};

export const isVerticalNonLgv = () => {
  const enabledPlants = ["ATL", "HAZ", "DET", "HOU", "JAX", "RCH", "TAC"];
  return enabledPlants.indexOf(getLocalStorageItem("locationCode")) > -1;
};

export const getBlockRackEmptyPositions = (blockRackList) => {
  let emptyCount = 0;
  blockRackList.forEach((item) => {
    if (blockRackTableDataValidation(item.lpn) === "null") {
      emptyCount += 1;
    }
  });
  return emptyCount;
};

export const blockRackTableDataValidation = (value) => {
  if (value) {
    if (value !== null && value.length !== 0) {
      return value;
    } else {
      return "null";
    }
  } else {
    return "null";
  }
};

/**
 *
 * @param {*} type singleStack or doubleStack
 * @param {*} location information about current pallet
 * @returns color code
 *
 */
export const getPalletColors = (type, location) => {
  //pallet level

  if (type == "block") {
    if (location?.storageDisabled === 1 || location?.retrievalDisabled === 1) {
      return "pink";
    }
    if (
      location?.positionRetrievalBlocked === "Yes" ||
      location?.positionRetrievalDisabled === "Yes"
    ) {
      return "red";
    }
    if (
      location?.positionRetrievalBlocked == "No" &&
      location?.positionRetrievalDisabled == "No"
    ) {
      if (location?.count === 0) {
        return "grey";
      } else if (location?.count < location?.totalCount) {
        return "green";
      } else if (location?.count == location?.totalCount) {
        return "blue";
      }
    }
  } else {
    if (location?.storageDisabled === 1 || location?.retrievalDisabled === 1) {
      return "pink";
    }
    if (
      location?.positionRetrievalBlocked === "Yes" ||
      location?.positionRetrievalDisabled === "Yes"
    ) {
      return "red";
    }

    if (
      location?.positionRetrievalBlocked == "No" &&
      location?.positionRetrievalDisabled == "No"
    ) {
      if (location?.count === 0) {
        return "grey";
      } else return "green";
    }
  }
};

export const onDragging = (event) => {
  if (document.body.classList.contains("no-drag")) {
    return;
  }
  event.preventDefault();

  event.currentTarget.classList.add("active-shipment-status-container-on-drag");
};

export const onDraggingEnd = (event) => {
  event.preventDefault();
  if (document.body.classList.contains("no-drag")) {
    return;
  }
  event.currentTarget.classList.remove(
    "active-shipment-status-container-on-drag"
  );
};

export const replaceDotWithDash = (str) => {
  return str.replaceAll(".", "-");
};

export const checkIsPalletPositionValid = (totalPalletList, pallet, index) => {
  //Current Pallet Data
  let palletData;
  if (pallet[1]) {
    palletData = pallet[1][0];
  } else if (pallet[2]) {
    palletData = pallet[2][0];
  }

  if (index === 0) {
    if (palletData.palletPosition !== 1) {
      return false;
    }
  } else {
    let palletGroup;
    //Get the previous Pallet data
    if (totalPalletList[index - 1][1]) {
      palletGroup = totalPalletList[index - 1][1][0];
    } else if (totalPalletList[index - 1][2]) {
      palletGroup = totalPalletList[index - 1][2][0];
    }
    //Check current pallet position is n + 1th pallet position
    if (palletGroup.palletPosition + 1 === palletData.palletPosition) {
      return false;
    } else {
      return true;
    }
  }
};

export const preFillPalletPositionIfEmpty = (renderList) => {
  if (renderList) {
    const palletPositions = renderList.map((item) => {
      if (item[1]) {
        return item[1][0].palletPosition;
      } else if (item[2]) {
        return item[2][0].palletPosition;
      }
    });

    // Check is the list is reversed
    const isAscending =
      palletPositions[0] < palletPositions[palletPositions.length - 1];

    // Find the missing pallet position
    let missingPalletPosition;
    for (let i = 1; i <= Math.max(...palletPositions); i++) {
      if (!palletPositions.includes(i)) {
        missingPalletPosition = i;
        break;
      }
    }

    // Find the correct index to insert the missing pallet position
    let insertIndex;
    if (isAscending) {
      for (let i = 0; i < palletPositions.length; i++) {
        if (palletPositions[i] > missingPalletPosition) {
          insertIndex = i;
          break;
        }
      }
    } else {
      for (let i = 0; i < palletPositions.length; i++) {
        if (palletPositions[i] < missingPalletPosition) {
          insertIndex = i;
          break;
        }
      }
    }

    // Fill the missing pallet
    const newData = {};
    let inserted = false;
    let j = 0;
    for (let i = 0; i < palletPositions.length; i++) {
      if (i === insertIndex) {
        newData[j] = {
          palletPosition: missingPalletPosition,
          palletStatus: "empty",
        };
        j++;
        inserted = true;
      }
      newData[j] = renderList[i];
      j++;
    }
    // Do not add the new data to the end if the list is in reverse order
    if (isAscending && !inserted) {
      newData[j] = {
        palletPosition: missingPalletPosition,
        palletStatus: "empty",
      };
    }

    return newData;
  }
};

export const renderSinglePalletLocation = (pallet, index, blocksCount) => {
  if (pallet.palletStatus) {
    return renderDoubleEmptyPalletLocation(index);
  } else {
    return (
      <div key={index} className="pallet-row">
        {pallet[1] && pallet[1].length !== 0 ? (
          <div
            className={`pallet pallet-single ${getPalletColors(
              "singleStack",
              pallet[1][0]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                -pallet[1][0].angle
              )}`}
            >
              {pallet[1][0].palletLevel}
            </p>
          </div>
        ) : null}
        {pallet[2] && pallet[2].length !== 0 ? (
          <div
            className={`pallet pallet-single ${getPalletColors(
              "singleStack",
              pallet[2][0]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                -pallet[2][0].angle
              )}`}
            >
              {pallet[2][0].palletLevel}
            </p>
          </div>
        ) : null}
        {pallet[3] && pallet[3].length !== 0 ? (
          <div
            className={`pallet pallet-single ${getPalletColors(
              "singleStack",
              pallet[3][0]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                -pallet[3][0].angle
              )}`}
            >
              {pallet[3][0].palletLevel}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
};

export const renderDoublePalletLocation = (pallet, index, blocksCount) => {
  if (pallet.palletStatus) {
    return renderSingleEmptyRowPalletLocation(index);
  } else {
    return (
      <div key={index} className="pallet-col">
        {pallet[1] && pallet[1].length !== 0 && (
          <div
            className={`pallet pallet-double ${getPalletColors(
              "block",
              blocksCount[pallet[1][0].location][pallet[1][0].row][
                pallet[1][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[1][0].location][pallet[1][0].row][
                  pallet[1][0].palletPosition
                ].angle
              )}`}
            >
              {
                blocksCount[pallet[1][0].location][pallet[1][0].row][
                  pallet[1][0].palletPosition
                ].count
              }
            </p>
          </div>
        )}
        {pallet[2] && pallet[2].length !== 0 && (
          <div
            className={`pallet pallet-double ${getPalletColors(
              "block",
              blocksCount[pallet[2][0].location][pallet[2][0].row][
                pallet[2][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[2][0].location][pallet[2][0].row][
                  pallet[2][0].palletPosition
                ].angle
              )}`}
            >
              {
                blocksCount[pallet[2][0].location][pallet[2][0].row][
                  pallet[2][0].palletPosition
                ].count
              }
            </p>
          </div>
        )}
        {pallet[3] && pallet[3].length !== 0 && (
          <div
            className={`pallet pallet-double ${getPalletColors(
              "block",
              blocksCount[pallet[3][0].location][pallet[3][0].row][
                pallet[3][0].palletPosition
              ]
            )}`}
          >
            <p
              className={`${rotatePalletTextBasedOnRotation(
                blocksCount[pallet[3][0].location][pallet[3][0].row][
                  pallet[3][0].palletPosition
                ].angle
              )}`}
            >
              {
                blocksCount[pallet[3][0].location][pallet[3][0].row][
                  pallet[3][0].palletPosition
                ].count
              }
            </p>
          </div>
        )}
      </div>
    );
  }
};

export const countBlocksUsingLPNs = (socketData) => {
  let finalObj = socketData.reduce(function (acc, currentObj, i) {
    let locationName = currentObj.location;
    let cRow = currentObj.row;
    let cPalletPosition = currentObj.palletPosition;

    if (!(locationName in acc)) {
      acc[locationName] = {
        row: {},
      };
    }

    if (!acc[locationName][cRow]) acc[locationName][cRow] = {};

    if (!acc[locationName][cRow][cPalletPosition]) {
      acc[locationName][cRow][cPalletPosition] = {
        count: 0,
        totalCount: 0,
      };
    }

    if (currentObj.lpn) {
      acc[locationName][cRow][cPalletPosition]["count"] =
        acc[locationName][cRow][cPalletPosition]["count"] + 1;
    }

    acc[locationName][cRow][cPalletPosition]["totalCount"] =
      acc[locationName][cRow][cPalletPosition]["totalCount"] + 1;

    acc[locationName][cRow][cPalletPosition] = {
      ...acc[locationName][cRow][cPalletPosition],
      ...currentObj,
    };
    return acc;
  }, {});
  return finalObj;
};

export const countRacksUsingLPNs = (socketData) => {
  let finalObj = socketData.reduce(function (acc, currentObj, i) {
    let locationName = currentObj.location;
    locationName = locationName.split(".").slice(0, -1).join(".");
    let cRow = currentObj.row;
    let cPalletPosition = currentObj.palletPosition;

    if (!(locationName in acc)) {
      acc[locationName] = {
        row: {},
      };
    }

    if (!acc[locationName][cRow]) acc[locationName][cRow] = {};

    if (!acc[locationName][cRow][cPalletPosition]) {
      acc[locationName][cRow][cPalletPosition] = {
        count: 0,
        totalCount: 0,
      };
    }

    if (currentObj.lpn) {
      acc[locationName][cRow][cPalletPosition]["count"] =
        acc[locationName][cRow][cPalletPosition]["count"] + 1;
    }

    acc[locationName][cRow][cPalletPosition]["totalCount"] =
      acc[locationName][cRow][cPalletPosition]["totalCount"] + 1;

    acc = { ...acc, ...currentObj };
    return acc;
  }, {});
  return finalObj;
};

export const renderDoublePalletLocationWithReverseOrder = (
  pallet,
  index,
  blocksCount
) => {
  let firstChildOfPallet = Object.values(pallet)[0];
  let secondChildOfPallet = Object.values(pallet)[1];
  return (
    <div
      key={index}
      className={`${
        firstChildOfPallet && firstChildOfPallet[0]?.locationOrientation === "E"
          ? "pallet-col-reverse"
          : "pallet-col"
      }`}
    >
      {firstChildOfPallet && firstChildOfPallet[0] && (
        <div
          className={`pallet pallet-double ${getPalletColors(
            "block",
            blocksCount[firstChildOfPallet[0]?.location]?.[
              firstChildOfPallet[0]?.row
            ]?.[firstChildOfPallet[0].palletPosition]
          )}
        `}
        >
          <p
            className={`${rotatePalletTextBasedOnRotation(
              blocksCount[firstChildOfPallet[0]?.location]?.[
                firstChildOfPallet[0]?.row
              ]?.[firstChildOfPallet[0]?.palletPosition]?.angle
            )}`}
          >
            {blocksCount[firstChildOfPallet[0]?.location]?.[
              firstChildOfPallet[0]?.row
            ]?.[firstChildOfPallet[0]?.palletPosition]?.count || 0}
          </p>
        </div>
      )}
      {secondChildOfPallet !== "empty" && secondChildOfPallet?.[0] && (
        <div
          className={`pallet pallet-double ${getPalletColors(
            "block",
            blocksCount[secondChildOfPallet[0]?.location]?.[
              secondChildOfPallet[0]?.row
            ]?.[secondChildOfPallet[0].palletPosition]
          )}
          `}
        >
          <p
            className={`${rotatePalletTextBasedOnRotation(
              blocksCount[secondChildOfPallet[0]?.location]?.[
                secondChildOfPallet[0]?.row
              ]?.[secondChildOfPallet[0]?.palletPosition]?.angle
            )}`}
          >
            {blocksCount[secondChildOfPallet[0]?.location]?.[
              secondChildOfPallet[0]?.row
            ]?.[secondChildOfPallet[0]?.palletPosition]?.count || 0}
          </p>
        </div>
      )}
      {/* {pallet[3] && pallet[3][0] && (
        <div
          className={`pallet pallet-single ${index} ${getPalletColors(
            "doubleStack",
            pallet[3][0]
          )}
          `}
        >
          {pallet[3][0]?.palletLevel}
        </div>
      )} */}
    </div>
  );
};

export const shouldRotatePallet = (angle, rackType) => {
  switch (rackType) {
    case "RACK_2":
      switch (angle) {
        case 90:
          return false;
        case -90:
          return false;
        case 180:
          return true;
        case 0:
          return true;
      }
    case "RACK":
      switch (angle) {
        case 90:
          return true;
        case -90:
          return true;
        case 180:
          return false;
        case 0:
          return false;
      }
  }
};

export const getPalletPositionOrRowCount = (totalPallets) => {
  let palletPositionGroup = {};
  totalPallets.forEach((pallet, index) => {
    if (!palletPositionGroup[pallet.palletPosition]) {
      palletPositionGroup[pallet.palletPosition] = [];
      palletPositionGroup[pallet.palletPosition].push(pallet);
    } else {
      palletPositionGroup[pallet.palletPosition].push(pallet);
    }
  });
  return palletPositionGroup;
};

export const getRackType = (rackData) => {
  let firstPalletOfLocation = rackData.at(0);
  if (firstPalletOfLocation) {
    const { rackType } = firstPalletOfLocation;
    if (rackType) {
      return rackType;
    } else {
      return null;
    }
  }
};

export const groupRackWithRowAsKey = (rackData) => {
  let rackGroupWithRow = {};
  rackData.forEach((pallet) => {
    if (!rackGroupWithRow[pallet.row]) {
      rackGroupWithRow[pallet.row] = [];
    }
    rackGroupWithRow[pallet.row].push(pallet);
  });
  return rackGroupWithRow;
};

const groupRackWithPalletPositionAsKey = (rackData) => {
  let rackGroupWithPalletPosition = {};
  rackData.forEach((pallet) => {
    if (!rackGroupWithPalletPosition[pallet.palletPosition]) {
      rackGroupWithPalletPosition[pallet.palletPosition] = [];
    }
    rackGroupWithPalletPosition[pallet.palletPosition].push(pallet);
  });
  return rackGroupWithPalletPosition;
};

export const getMetaDataOfRackLocation = (rackData) => {
  if (Object.values(rackData)[0]) {
    let firstPallet = Object.values(rackData)[0];
    if (firstPallet) {
      return firstPallet;
    } else {
      return null;
    }
  }
};

export const getRacksCounByPallet = (
  sectionSingle,
  palletPosition,
  racksCount,
  row
) => {
  let locationName = sectionSingle.location.split(".").slice(0, -1).join(".");
  return racksCount[locationName][row]?.[palletPosition]?.count || 0;
};

export const renderRackPallet = (renderList, racksCount) => {
  //Total Data
  let rackFullData = [renderList[0][0]];
  //Rack Type of this location
  let rackTypeOfThisLocation = getRackType(renderList[0]);
  //Rack Meta Data
  let locationMetaData = getMetaDataOfRackLocation(renderList[0]);
  //Rotation Angle of this Location
  let isPalletRotated = shouldRotatePallet(
    locationMetaData.angle,
    locationMetaData.rackType
  );

  switch (rackTypeOfThisLocation) {
    case "RACK":
      //Single Deep Racks
      let singleRackGroupedData = groupRackWithRowAsKey(renderList[0]);

      //Available Rows in the Single Deep Rack location
      let firstRowOfPallet = Object.values(singleRackGroupedData)[0];
      let secondRowOfPallet = Object.values(singleRackGroupedData)[1];
      let thirdRowOfPallet = Object.values(singleRackGroupedData)[2];

      return (
        <div className="pallets-wrapper-col">
          <div
            className={`${
              locationMetaData.locationOrientation === "E"
                ? "pallet-col-reverse"
                : "pallet-col"
            }`}
          >
            <div
              className={`pallet pallet-rack-single ${getPalletColors(
                "singleStack",
                locationMetaData
              )} ${!isPalletRotated ? "rotate-rack" : ""}`}
            >
              {firstRowOfPallet ? (
                <div className="double-deep-rack-size">
                  <p
                    className={`pallet-text-size ${
                      !isPalletRotated ? "rotate-text-double-deep-rack" : ""
                    }`}
                  >
                    {getRacksCounByPallet(locationMetaData, 1, racksCount, 1)}
                  </p>
                </div>
              ) : null}
              {secondRowOfPallet ? (
                <div className="double-deep-rack-size">
                  <p
                    className={`pallet-text-size ${
                      !isPalletRotated ? "rotate-text-double-deep-rack" : ""
                    }`}
                  >
                    {getRacksCounByPallet(locationMetaData, 1, racksCount, 2)}
                  </p>
                </div>
              ) : null}

              {thirdRowOfPallet ? (
                <div className="double-deep-rack-size three-pallet">
                  <p
                    className={`pallet-text-size ${
                      !isPalletRotated ? "rotate-text-double-deep-rack" : ""
                    }`}
                  >
                    {getRacksCounByPallet(locationMetaData, 1, racksCount, 3)}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    case "RACK_2":
      //Double Deep Racks
      let doubleRackGroupedData = groupRackWithPalletPositionAsKey(
        renderList[0]
      );

      //Available Pallet Position in the Double Deep Rack Location
      let firstPositionOfPallet = Object.values(doubleRackGroupedData)[0];
      let secondPositionOfPallet = Object.values(doubleRackGroupedData)[1];
      let thirdPositionOfPallet = Object.values(doubleRackGroupedData)[2];
      return (
        <div className="pallets-wrapper-col">
          <div
            className={`${
              locationMetaData.locationOrientation === "E"
                ? "pallet-col-reverse"
                : "pallet-col"
            }`}
          >
            <div
              className={`pallet pallet-rack-single ${getPalletColors(
                "singleStack",
                locationMetaData
              )} ${!isPalletRotated ? "rotate-rack" : ""}`}
            >
              {firstPositionOfPallet ? (
                <div className="double-deep-rack-size">
                  <p
                    className={`pallet-text-size ${
                      !isPalletRotated ? "rotate-text-double-deep-rack" : ""
                    }`}
                  >
                    {getRacksCounByPallet(locationMetaData, 1, racksCount, 1)}
                  </p>
                </div>
              ) : null}
              {secondPositionOfPallet ? (
                <div className="double-deep-rack-size">
                  <p
                    className={`pallet-text-size ${
                      !isPalletRotated ? "rotate-text-double-deep-rack" : ""
                    }`}
                  >
                    {getRacksCounByPallet(locationMetaData, 2, racksCount, 1)}
                  </p>
                </div>
              ) : null}
              {thirdPositionOfPallet ? (
                <div className="double-deep-rack-size three-pallet">
                  <p
                    className={`pallet-text-size ${
                      !isPalletRotated ? "rotate-text-double-deep-rack" : ""
                    }`}
                  >
                    {getRacksCounByPallet(locationMetaData, 3, racksCount, 1)}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
  }
};

export const renderPreFilledSingleBlockPallet = (
  renderList,
  isLocationVertical,
  blocksCount
) => {
  //Get the PreFilled Empty Pallet List
  let preFilledRenderList = preFillPalletPositionIfEmpty(renderList);

  return (
    preFilledRenderList && (
      <div
        className={`pallets-wrapper-row 
      ${isLocationVertical ? "rotate-rack" : ""}
    `}
      >
        {Object.values(preFilledRenderList).map((pallet, index) => {
          if (pallet) {
            return renderDoublePalletLocationWithReverseOrder(
              pallet,
              index,
              blocksCount
            );
          }
        })}
      </div>
    )
  );
};

/**
 *
 * NOTE:
 * !!!!!!!-For Margin Offset of Single Stack Pallet, providing value in multiples of 5 is recommended---!!!!!!!
 * @returns
 */
export const renderPreFilledDoubleBlockPallet = (
  sectionsData,
  isLocationVertical,
  locationMetaData,
  blocksCount,
  marker
) => {
  let singleStackMarkerOffset = marker.singleStack.offset;
  const { x, y } = singleStackMarkerOffset;
  return (
    <div
      className={`pallets-wrapper-col ${
        isLocationVertical ? "rotate-rack" : ""
      }`}
    >
      {sectionsData.map((sectionDouble, index) => {
        if (sectionDouble) {
          let totalDoubleRowsOfLocation = locationMetaData.doubleRows;
          let reversedArray = sectionDouble.reduce(
            (acc, item) => [item].concat(acc),
            []
          );
          //PreFill reversedArray data here
          let preFilledReversedPalletList = preFillPalletPositionIfEmpty(
            reversedArray,
            index
          );
          if (locationMetaData.positionOrientation == "N") {
            if (index < locationMetaData.pbcRows) {
              return (
                reversedArray && (
                  <span className="single-pallet-wrapper">
                    <div
                      key={index}
                      className={`${
                        sectionDouble[0][1][0].locationOrientation === "E"
                          ? `pallets-wrapper-single-row-reverse`
                          : `pallets-wrapper-single-row`
                      } ${replaceDotWithDash(locationMetaData.location)}`}
                    >
                      {Object.values(reversedArray).map((pallet, index) => {
                        //Render Single Pallet
                        return renderSinglePalletLocation(
                          pallet,
                          index,
                          blocksCount
                        );
                      })}
                    </div>
                  </span>
                )
              );
            }
            return (
              reversedArray && (
                <div
                  key={index}
                  className={`${
                    sectionDouble[0][1]?.[0].locationOrientation === "E" ||
                    sectionDouble[0][2]?.[0].locationOrientation === "E"
                      ? "pallets-wrapper-row-reverse"
                      : "pallets-wrapper-row"
                  }`}
                >
                  {Object.values(reversedArray).map((pallet, index) => {
                    //Render Double Pallet
                    return renderDoublePalletLocation(
                      pallet,
                      index,
                      blocksCount
                    );
                  })}
                </div>
              )
            );
          }
          if (index + 1 > totalDoubleRowsOfLocation) {
            return (
              reversedArray && (
                <span className="single-pallet-wrapper">
                  <div
                    key={index}
                    className={`${
                      sectionDouble[0][1][0].locationOrientation === "E"
                        ? `pallets-wrapper-single-row-reverse`
                        : `pallets-wrapper-single-row`
                    } ${replaceDotWithDash(locationMetaData.location)}`}
                  >
                    {Object.values(reversedArray).map((pallet, index) => {
                      //Render Single Pallet
                      return renderSinglePalletLocation(
                        pallet,
                        index,
                        blocksCount
                      );
                    })}
                  </div>
                </span>
              )
            );
          } else {
            return (
              reversedArray && (
                <div
                  key={index}
                  className={`${
                    sectionDouble[0][1]?.[0].locationOrientation === "E" ||
                    sectionDouble[0][2]?.[0].locationOrientation === "E"
                      ? "pallets-wrapper-row-reverse"
                      : "pallets-wrapper-row"
                  }`}
                >
                  {Object.values(reversedArray).map((pallet, index) => {
                    //Render Double Pallet
                    return renderDoublePalletLocation(
                      pallet,
                      index,
                      blocksCount
                    );
                  })}
                </div>
              )
            );
          }
        }
      })}
    </div>
  );
};

export const getBlockRackDataWithLocationOrientation = (
  locationOrientation,
  sectionsData,
  type
) => {
  switch (locationOrientation) {
    case "S":
    case "W":
      switch (type) {
        case "block":
          return sectionsData?.[0];
        case "rack":
          return sectionsData;
      }
      break;

    case "N":
    case "E":
      switch (type) {
        case "block":
          //Reversed Pallets
          return sectionsData?.[0].reduce(
            (acc, item) => [item].concat(acc),
            []
          );
        case "rack":
          return sectionsData;
      }
      break;
  }
};

export const getLocationOrientationOfStorage = (type, sectionsData) => {
  let firstArrayItem = sectionsData[0][0];
  let firstChildOfPallet = Object.values(firstArrayItem)[0];
  switch (type) {
    case "block":
      if (firstArrayItem && firstChildOfPallet) {
        if (firstChildOfPallet.locationOrientation !== undefined) {
          return firstChildOfPallet.locationOrientation;
        } else {
          return firstChildOfPallet[0].locationOrientation;
        }
      }
    case "rack":
      return firstArrayItem.locationOrientation;
  }
};

export const renderDoubleEmptyPalletLocation = (index) => {
  return (
    <div key={index} className="pallet-col">
      <div className={`pallet pallet-double empty-pallet`}>
        <p>0</p>
      </div>
      <div className={`pallet pallet-double empty-pallet`}>
        <p>0</p>
      </div>
    </div>
  );
};

export const renderSingleEmptyRowPalletLocation = (index) => {
  return (
    <div key={index} className="pallet-col">
      <div className={`pallet pallet-single empty-pallet`}>
        <p>0</p>
      </div>
      <div className={`pallet pallet-single empty-pallet`}>
        <p>0</p>
      </div>
    </div>
  );
};

export const renderDoubleEmptyColumnPalletLocation = (sectionSingle, index) => {
  return (
    <div
      key={index}
      className={`${
        sectionSingle[1][0].locationOrientation === "E"
          ? "pallet-col-reverse"
          : "pallet-col"
      }`}
    >
      <div className={`pallet pallet-single empty-pallet`}>
        {sectionSingle[1][0].palletLevel}
      </div>
      {sectionSingle[2] && sectionSingle[2][0] && (
        <div className={`pallet pallet-single empty-pallet`}>
          {sectionSingle[2][0].palletLevel}
        </div>
      )}
    </div>
  );
};

export const findIsLocationVertical = (renderList) => {
  let angle;

  if (renderList?.[0]) {
    if (renderList[0][1] && renderList[0][1][0]) {
      angle = renderList[0][1][0].angle;
    } else if (renderList[0][2] && renderList[0][2][0]) {
      angle = renderList[0][2][0].angle;
    }
  }
  if (angle == 90 || angle == -90) {
    return true;
  }
};

/**
 *
 * @param {*} locationName
 * @returns formatedName
 * 1. Split the string into array
 * 2. Slice the array from 0  to length - 1 index
 * 3. Join the result and return
 */

export const formatName = (locationName) => {
  const splittedString = locationName.split(".");
  const result = splittedString.slice(0, splittedString.length - 1);
  return result.join(".");
};

export const getBlockRackName = (modalType, locationName) => {
  if (modalType) {
    let splittedName = locationName.split(".");
    return splittedName.slice(0, splittedName.length - 1).join(".");
  } else {
    return locationName;
  }
};

export const getLocationGroupOffset = (convertedValue, offsetObject) => {
  if (convertedValue && offsetObject) {
    //Offset Object
    let offsetCoordinates = {
      x: convertedValue.lat + offsetObject.x,
      y: convertedValue.lng + offsetObject.y,
    };
    //Offset Array
    let offsetCoordinateArray = [offsetCoordinates.x, offsetCoordinates.y];
    return offsetCoordinateArray;
  }
};

export const getDestinationPointerOffset = (convertedValue, offsetObject) => {
  if ((convertedValue, offsetObject)) {
    let sourceOffset = [
      convertedValue.currentPos.lat +
        offsetObject.coordinateCorrection.source.x,
      convertedValue.currentPos.lng +
        offsetObject.coordinateCorrection.source.y,
    ];

    let destinationOffset = [
      convertedValue.destinationCurrentPos.lat + offsetObject.value.x,
      convertedValue.destinationCurrentPos.lng + offsetObject.value.y,
    ];

    return [sourceOffset, destinationOffset];
  }
};

export const getPlantImage = () => {
  const plantImages = {
    CAR: CARMap,
    ATL: ATLMap,
    DET: DETMap,
    ALA: ALAMap,
    HAZ: HAZMap,
    HOU: HOUMap,
    LAS: LASMap,
    MIA: MIAMap,
    TAC: TACMap,
    JAX: JAXMap,
    LAN: LANMap,
    ST3: ST3Map,
    BAL: BALMap,
    KC2: KC2Map,
    MOR: MORMap,
    TEM: TEMMap,
    LOU: LOUMap,
    BAY: BAYMap,
    RCH: RCHMap,
    COL: COLMap,
    KNC: KNCMap,
    BLM: BLMMap,
    KEN: KENMap,
    SAN: SANMap,
    SL2: SL2Map,
    MES: MESMap,
    MIS: MISMap,
    PIT: PITMap,
    MTY: MTYMap,
    OKC: OKCMap,
    MXC: MXCMap,
  };
  return plantImages[getLocalStorageItem("locationCode")];
};

export const getPlantConfig = (objectRequired) => {
  const plantsConfig = {
    CAR: {
      // lgvs: {
      //   marker: {
      //     size: [15, 15],
      //   },
      //   bounds: {
      //     startX: 47000,
      //     startY: 14500,
      //     endX: 453500,
      //     endY: 168000,
      //   },
      //   groupOffset: { x: -120, y: 0 },
      // },
      // mapBg: {
      //   img: CARMap,
      //   coordinates: {
      //     startX: 38000,
      //     startY: -12000,
      //     endX: 510000,
      //     endY: 153000,
      //   },
      //   bounds: {
      //     startX: 47000,
      //     startY: 13000,
      //     endX: 450000,
      //     endY: 171000,
      //   },
      //   bgColor: "#ffffff",
      // },
      // rackStorage: {
      //   bounds: {
      //     startX: 47000,
      //     startY: 14500,
      //     endX: 455000,
      //     endY: 168000,
      //   },
      //   groupOffset: {
      //     x: -68,
      //     y: -35,
      //   },
      //   eachItemOffset: {},
      // },
      // blockStorage: {
      //   marker: {
      //     size: {
      //       width: "4.7px",
      //       height: "2.8px",
      //     },
      //   },
      //   bounds: {
      //     startX: 47000,
      //     startY: 14500,
      //     endX: 455000,
      //     endY: 168000,
      //   },
      //   eachItemOffset: {
      //     "A.E.021": {
      //       x: -70,
      //       y: -25,
      //     },
      //     "A.E.018": {
      //       x: -68,
      //       y: -25,
      //     },
      //     "A.E.015": {
      //       x: -68,
      //       y: -25,
      //     },
      //     "C.E.021": {
      //       x: -58,
      //       y: -17,
      //     },
      //     "C.E.011": {
      //       x: -65,
      //       y: -12,
      //     },
      //     "I.E.005": {
      //       x: -60,
      //       y: -10,
      //     },
      //     "F.E.021": {
      //       x: -60,
      //       y: -10,
      //     },
      //     "E.E.021": {
      //       x: -60,
      //       y: -15,
      //     },
      //     "F.E.011": {
      //       x: -60,
      //       y: -10,
      //     },
      //     "B.E.029": {
      //       x: -68,
      //       y: -20,
      //     },
      //     "C.E.029": {
      //       x: -68,
      //       y: -20,
      //     },
      //     "B.E.021": {
      //       x: -60,
      //       y: -15,
      //     },
      //     "D.E.021": {
      //       x: -57,
      //       y: -15,
      //     },
      //     "D.E.011": {
      //       x: -68,
      //       y: -10,
      //     },
      //     "G.E.001": {
      //       x: -68,
      //       y: -10,
      //     },
      //     "G.E.005": {
      //       x: -55,
      //       y: -15,
      //     },
      //     "H.W.005": {
      //       x: -55,
      //       y: -30,
      //     },
      //     "H.E.005": {
      //       x: -55,
      //       y: -20,
      //     },
      //     "A.E.011": {
      //       x: -66,
      //       y: -19,
      //     },
      //     "B.W.011": {
      //       x: -68,
      //       y: -35,
      //     },
      //     "C.W.021": {
      //       x: -58,
      //       y: -25,
      //     },
      //     "D.W.011": {
      //       x: -60,
      //       y: -32,
      //     },
      //     "E.E.011": {
      //       x: -65,
      //       y: -20,
      //     },
      //     "D.E.011": {
      //       x: -65,
      //       y: -20,
      //     },
      //     "F.W.011": {
      //       x: -65,
      //       y: -30,
      //     },
      //     "E.W.011": {
      //       x: -65,
      //       y: -27,
      //     },
      //     "E.W.022": {
      //       x: -65,
      //       y: -27,
      //     },
      //     "D.W.021": {
      //       x: -58,
      //       y: -34,
      //     },
      //     "A.W.001": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.002": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.003": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.004": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.005": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.006": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.007": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.008": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.009": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.010": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.011": {
      //       x: -65,
      //       y: -29,
      //     },
      //     "A.W.013": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.014": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.015": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.016": {
      //       x: -68,
      //       y: -37,
      //     },
      //     "A.W.017": {
      //       x: -68,
      //       y: -37,
      //     },
      //   },
      //   eastLocationOffset: {
      //     x: -68,
      //     y: -22.5,
      //   },
      //   westLocationOffset: {
      //     x: -68,
      //     y: -39,
      //   },
      //   groupOffset: {
      //     x: -68,
      //     y: 40,
      //   },
      // },
      // nonLgvStorage: {
      //   marker: {
      //     size: [22, 50],
      //     rotation: true,
      //   },
      //   coordinate: {
      //     x: 1630,
      //     y: 423,
      //   },
      // },
      // productionLines: {
      //   bounds: {
      //     startX: 38000,
      //     startY: -12000,
      //     endX: 510000,
      //     endY: 153000,
      //   },
      //   groupOffset: {
      //     x: 40,
      //     y: 15,
      //   },
      //   eachItemOffset: {
      //     L1: {
      //       x: 0,
      //       y: -30,
      //     },
      //     L2: {
      //       x: 0,
      //       y: 15,
      //     },
      //     L3: {
      //       x: 0,
      //       y: 0,
      //     },
      //     L4: {
      //       x: 0,
      //       y: -35,
      //     },
      //     L5: {
      //       x: 0,
      //       y: -10,
      //     },
      //   },
      // },
      // destinationPointers: {
      //   bounds: {
      //     startX: 47000,
      //     startY: 13000,
      //     endX: 450000,
      //     endY: 171000,
      //   },
      //   coordinateCorrection: {
      //     source: {
      //       x: -83,
      //       y: -30,
      //     },
      //     destination: {
      //       x: -20,
      //       y: -35,
      //     },
      //   },
      //   destinationCorrection: {
      //     STAGE: {
      //       x: -90,
      //       y: -30,
      //     },
      //     LINES: {
      //       x: -90,
      //       y: -30,
      //     },
      //     TSL: {
      //       x: -90,
      //       y: -30,
      //     },
      //     HNDOFF: {
      //       x: -80,
      //       y: -70,
      //     },
      //     "G.W": {
      //       x: -90,
      //       y: -40,
      //     },
      //     "H.W": {
      //       x: -85,
      //       y: -10,
      //     },
      //     "C.E": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "J.W": {
      //       x: -90,
      //       y: 20,
      //     },
      //     SSR: {
      //       x: -75,
      //       y: 15,
      //     },
      //     "E.W": {
      //       x: -85,
      //       y: -10,
      //     },
      //     STR: {
      //       x: -90,
      //       y: 20,
      //     },
      //     SOR: {
      //       x: -90,
      //       y: 20,
      //     },
      //     "I.W": {
      //       x: -90,
      //       y: 20,
      //     },
      //     BATT: {
      //       x: 100,
      //       y: -30,
      //     },
      //     "A.E": {
      //       x: -90,
      //       y: 8,
      //     },
      //     "RDR.K": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "A.W": {
      //       x: -80,
      //       y: -20,
      //     },
      //     "B.E": {
      //       x: -80,
      //       y: -8,
      //     },
      //     NSR: {
      //       x: -90,
      //       y: 20,
      //     },
      //     "D.E": {
      //       x: -85,
      //       y: -40,
      //     },
      //     "C.W.GLD": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "SR.B.E": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "B.W": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "J.E": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "H.E": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "RSR.P.N": {
      //       x: -90,
      //       y: 20,
      //     },
      //     "C.W": {
      //       x: -80,
      //       y: 0,
      //     },
      //     "D.W": {
      //       x: -85,
      //       y: 0,
      //     },
      //     "F.W": {
      //       x: -85,
      //       y: -30,
      //     },
      //     "G.E": {
      //       x: -85,
      //       y: -45,
      //     },
      //     "F.E": {
      //       x: -85,
      //       y: -80,
      //     },
      //     "I.E": {
      //       x: -83,
      //       y: -40,
      //     },
      //     "RWRK.DRP": {
      //       x: -83,
      //       y: -40,
      //     },
      //     "D.GLD": {
      //       x: -83,
      //       y: -40,
      //     },
      //     L1: {
      //       x: -83,
      //       y: -40,
      //     },
      //     L3: {
      //       x: -83,
      //       y: -40,
      //     },
      //     "D.N": {
      //       x: -83,
      //       y: -40,
      //     },
      //     "E.D": {
      //       x: -83,
      //       y: -40,
      //     },
      //     NOR: {
      //       x: -83,
      //       y: -40,
      //     },
      //     KE: {
      //       x: -83,
      //       y: -40,
      //     },
      //     OTHERS: {
      //       x: -90,
      //       y: -30,
      //     },
      //   },
      // },
      // dockDoors: {
      //   bounds: {
      //     startX: 47000,
      //     startY: 13000,
      //     endX: 450000,
      //     endY: 171000,
      //   },
      //   marker: {
      //     size: [15, 75],
      //   },
      //   groupOffset: {
      //     x: -45,
      //     y: 70,
      //   },
      //   position: "DOWN",
      // },
      // timeMachine: {
      //   isEnabled: false,
      //   coordinates: {
      //     startX: 38000,
      //     startY: -12000,
      //     endX: 510000,
      //     endY: 153000,
      //   },
      //   bounds: {
      //     startX: 47000,
      //     startY: 13000,
      //     endX: 450000,
      //     endY: 171000,
      //   },
      // },
      // heatmap: {
      //   mapBg: {
      //     img: CARMap,
      //     bounds: {
      //       startX: 47000,
      //       startY: 4500,
      //       endX: 553500,
      //       endY: 188000,
      //     },
      //     coordinates: {
      //       startX: 40000,
      //       startY: 40000,
      //       endX: 416000,
      //       endY: 158000,
      //     },
      //   },
      //   bounds: {
      //     startX: 47000,
      //     startY: 4500,
      //     endX: 553500,
      //     endY: 188000,
      //   },
      //   groupOffset: { x: 35, y: -35 },
      //   horizontalBoxCount: 57,
      //   verticalBoxCount: 25,
      //   box: { x: 27, y: 27 },
      // },
    },
    ATL: {
      rackStorage: {
        bounds: {
          startX: -1300,
          startY: 2000,
          endX: 384000,
          endY: 125300,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {},
      },
      blockStorage: {
        marker: {
          size: {
            width: "5.0px",
            height: "4.7px",
          },
        },
        bounds: {
          startX: -1300,
          startY: 1500,
          endX: 384000,
          endY: 124300,
        },
        eachItemOffset: {
          "D.E.019": {
            x: -68,
            y: 40,
          },
          "D.W.019": {
            x: -68,
            y: -37,
          },
          "E.W.020": {
            x: -68,
            y: -37,
          },
          "E.W.021": {
            x: -68,
            y: -40,
          },
          "H.E.001": {
            x: -68,
            y: -24,
          },
          "H.E.002": {
            x: -68,
            y: -24,
          },
          "H.E.003": {
            x: -68,
            y: -24,
          },
          "H.E.004": {
            x: -68,
            y: -24,
          },
          "H.E.005": {
            x: -68,
            y: -24,
          },
          "H.E.006": {
            x: -68,
            y: -24,
          },
          "H.E.007": {
            x: -68,
            y: -24,
          },
          "H.E.008": {
            x: -68,
            y: -24,
          },
          "H.E.009": {
            x: -68,
            y: -24,
          },
          "H.E.010": {
            x: -68,
            y: -24,
          },
          "H.E.011": {
            x: -68,
            y: -24,
          },
          "H.E.012": {
            x: -68,
            y: -24,
          },
          "H.E.013": {
            x: -68,
            y: -24,
          },
          "H.E.014": {
            x: -68,
            y: -24,
          },
          "H.E.015": {
            x: -68,
            y: -24,
          },
          "H.E.016": {
            x: -68,
            y: -24,
          },
          "H.E.017": {
            x: -68,
            y: -24,
          },
          "H.E.018": {
            x: -68,
            y: -24,
          },
          "H.E.019": {
            x: -68,
            y: -24,
          },
          "H.E.020": {
            x: -68,
            y: -24,
          },
          "H.E.021": {
            x: -68,
            y: -24,
          },
          "H.E.022": {
            x: -68,
            y: -24,
          },
          //IW
          "I.W.001": {
            x: -68,
            y: -39,
          },
          "I.W.002": {
            x: -68,
            y: -39,
          },
          "I.W.003": {
            x: -68,
            y: -39,
          },
          "I.W.004": {
            x: -68,
            y: -39,
          },
          "I.W.005": {
            x: -68,
            y: -39,
          },
          "I.W.006": {
            x: -68,
            y: -39,
          },
          "I.W.007": {
            x: -68,
            y: -39,
          },
          "I.W.008": {
            x: -68,
            y: -39,
          },
          "I.W.009": {
            x: -68,
            y: -39,
          },
          "I.W.010": {
            x: -68,
            y: -39,
          },
          "I.W.011": {
            x: -68,
            y: -39,
          },
          "I.W.012": {
            x: -68,
            y: -39,
          },
          "I.W.013": {
            x: -68,
            y: -39,
          },
          "I.W.014": {
            x: -68,
            y: -39,
          },
          "I.W.015": {
            x: -68,
            y: -39,
          },
          "I.W.016": {
            x: -68,
            y: -39,
          },
          //BW
          "B.W.001": {
            x: -68,
            y: -35,
          },
          "B.W.002": {
            x: -68,
            y: -35,
          },
          "B.W.003": {
            x: -68,
            y: -35,
          },
          "B.W.004": {
            x: -68,
            y: -35,
          },
          "B.W.005": {
            x: -68,
            y: -35,
          },
          "B.W.006": {
            x: -68,
            y: -35,
          },
          "B.W.007": {
            x: -68,
            y: -35,
          },
          "B.W.008": {
            x: -68,
            y: -35,
          },
          "B.W.009": {
            x: -68,
            y: -35,
          },
          "B.W.010": {
            x: -68,
            y: -35,
          },
          "B.W.011": {
            x: -68,
            y: -35,
          },
          "B.W.012": {
            x: -68,
            y: -35,
          },
          "B.W.013": {
            x: -68,
            y: -35,
          },
          "B.W.014": {
            x: -68,
            y: -35,
          },
          "B.W.015": {
            x: -68,
            y: -35,
          },
          "B.W.016": {
            x: -68,
            y: -35,
          },
          "B.W.017": {
            x: -68,
            y: -35,
          },
          "B.W.018": {
            x: -68,
            y: -35,
          },
          "B.W.019": {
            x: -68,
            y: -35,
          },
          "B.W.020": {
            x: -68,
            y: -35,
          },
          "B.W.021": {
            x: -68,
            y: -35,
          },
          //CW
          "C.W.001": {
            x: -68,
            y: -35,
          },
          "C.W.002": {
            x: -68,
            y: -35,
          },
          "C.W.003": {
            x: -68,
            y: -35,
          },
          "C.W.004": {
            x: -68,
            y: -35,
          },
          "C.W.005": {
            x: -68,
            y: -35,
          },
          "C.W.006": {
            x: -68,
            y: -35,
          },
          "C.W.007": {
            x: -68,
            y: -35,
          },
          "C.W.008": {
            x: -68,
            y: -35,
          },
          "C.W.009": {
            x: -68,
            y: -35,
          },
          "C.W.010": {
            x: -68,
            y: -35,
          },
          "C.W.011": {
            x: -68,
            y: -35,
          },
          "C.W.012": {
            x: -68,
            y: -35,
          },
          "C.W.013": {
            x: -68,
            y: -35,
          },
          "C.W.014": {
            x: -68,
            y: -35,
          },
          "C.W.015": {
            x: -68,
            y: -35,
          },
          "C.W.016": {
            x: -68,
            y: -35,
          },
          "C.W.017": {
            x: -68,
            y: -35,
          },
          "C.W.018": {
            x: -68,
            y: -35,
          },
          "C.W.019": {
            x: -68,
            y: -35,
          },
          "C.W.020": {
            x: -68,
            y: -35,
          },
          "C.W.021": {
            x: -68,
            y: -35,
          },
          //AW
          "A.W.001": {
            x: -68,
            y: -35,
          },
          "A.W.002": {
            x: -68,
            y: -35,
          },
          "A.W.003": {
            x: -68,
            y: -35,
          },
          "A.W.004": {
            x: -68,
            y: -35,
          },
          "A.W.005": {
            x: -68,
            y: -35,
          },
          "A.W.006": {
            x: -68,
            y: -35,
          },
          "A.W.007": {
            x: -68,
            y: -35,
          },
          "A.W.008": {
            x: -68,
            y: -35,
          },
          "A.W.009": {
            x: -68,
            y: -35,
          },
          "A.W.010": {
            x: -68,
            y: -35,
          },
          "A.W.011": {
            x: -68,
            y: -35,
          },
          "A.W.012": {
            x: -68,
            y: -35,
          },
          "A.W.013": {
            x: -68,
            y: -35,
          },
          "A.W.014": {
            x: -68,
            y: -35,
          },
          "A.W.015": {
            x: -68,
            y: -35,
          },
          //KW
          "K.W.001": {
            x: -68,
            y: -35,
          },
          "K.W.002": {
            x: -68,
            y: -35,
          },
          "K.W.003": {
            x: -68,
            y: -35,
          },
          "K.W.004": {
            x: -68,
            y: -35,
          },
          "K.W.005": {
            x: -68,
            y: -35,
          },
          "K.W.006": {
            x: -68,
            y: -35,
          },
          "K.W.007": {
            x: -68,
            y: -35,
          },
          "K.W.008": {
            x: -68,
            y: -35,
          },
          "J.E.009": {
            x: -67,
            y: -22,
          },
          "K.W.009": {
            x: -67,
            y: -35,
          },
          "K.W.010": {
            x: -68,
            y: -35,
          },
          "K.W.011": {
            x: -68,
            y: -35,
          },
          "K.W.012": {
            x: -68,
            y: -35,
          },
          "K.W.013": {
            x: -68,
            y: -35,
          },
          //LW
          "L.W.001": {
            x: -68,
            y: -35,
          },
          "L.W.002": {
            x: -68,
            y: -35,
          },
          "L.W.003": {
            x: -68,
            y: -35,
          },
          "L.W.004": {
            x: -68,
            y: -35,
          },
          "L.W.005": {
            x: -68,
            y: -35,
          },
          "L.W.006": {
            x: -68,
            y: -35,
          },
          "L.W.007": {
            x: -68,
            y: -35,
          },
          "L.W.008": {
            x: -68,
            y: -35,
          },
          "L.W.009": {
            x: -67,
            y: -35,
          },
          "K.E.009": {
            x: -67,
            y: -22,
          },
          "L.W.010": {
            x: -68,
            y: -35,
          },
          "L.W.011": {
            x: -68,
            y: -35,
          },
          "L.W.012": {
            x: -68,
            y: -35,
          },
          "L.W.013": {
            x: -68,
            y: -35,
          },
          //LE
          "L.E.001": {
            x: -68,
            y: -23,
          },
          "L.E.002": {
            x: -68,
            y: -23,
          },
          "L.E.003": {
            x: -68,
            y: -23,
          },
          "L.E.004": {
            x: -68,
            y: -23,
          },
          "L.E.005": {
            x: -68,
            y: -23,
          },
          "L.E.006": {
            x: -68,
            y: -23,
          },
          "L.E.007": {
            x: -68,
            y: -23,
          },
          "L.E.008": {
            x: -68,
            y: -23,
          },
          "L.E.009": {
            x: -67,
            y: -23,
          },
          "L.E.010": {
            x: -68,
            y: -23,
          },
          "L.E.011": {
            x: -68,
            y: -23,
          },
          "L.E.012": {
            x: -68,
            y: -23,
          },
          "L.E.013": {
            x: -68,
            y: -23,
          },
        },
        eastLocationOffset: {
          x: -68,
          y: -22,
        },
        westLocationOffset: {
          x: -68,
          y: -37,
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 50,
          y: 215,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -215,
          y: -18,
        },
        eachItemOffset: {
          L1: {
            x: -10,
            y: 95,
          },
          L2: {
            x: -10,
            y: 70,
          },
          L3: {
            x: -10,
            y: 12,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        coordinateCorrection: {
          source: {
            x: 38,
            y: 300,
          },
          destination: {
            x: 0,
            y: 300,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 37,
            y: 290,
          },
          HNDOFF: {
            x: 110,
            y: 310,
          },
          "G.W": {
            x: -90,
            y: -40,
          },
          "H.W": {
            x: 37,
            y: 282,
          },
          "C.E": {
            x: 37,
            y: 290,
          },
          "J.W": {
            x: 37,
            y: 290,
          },
          SSR: {
            x: 37,
            y: 290,
          },
          "E.W": {
            x: 37,
            y: 290,
          },
          STR: {
            x: 37,
            y: 290,
          },
          SOR: {
            x: 37,
            y: 290,
          },
          "I.W": {
            x: 37,
            y: 305,
          },
          BATT: {
            x: 110,
            y: 340,
          },
          "A.E": {
            x: 37,
            y: 310,
          },
          "RDR.K": {
            x: 37,
            y: 290,
          },
          "A.W": {
            x: 37,
            y: 292,
          },
          "B.E": {
            x: 37,
            y: 290,
          },
          NSR: {
            x: 50,
            y: 290,
          },
          "D.E": {
            x: 37,
            y: 290,
          },
          "C.W.GLD": {
            x: 37,
            y: 290,
          },
          "SR.B.E": {
            x: 37,
            y: 290,
          },
          "B.W": {
            x: 37,
            y: 290,
          },
          "J.E": {
            x: 37,
            y: 290,
          },
          "H.E": {
            x: 37,
            y: 290,
          },
          "RSR.P.N": {
            x: 37,
            y: 290,
          },
          "C.W": {
            x: 37,
            y: 290,
          },
          CW: {
            x: 37,
            y: 290,
          },
          "D.W": {
            x: 37,
            y: 290,
          },
          "F.W": {
            x: 37,
            y: 290,
          },
          "G.E": {
            x: 37,
            y: 290,
          },
          "F.E": {
            x: 37,
            y: 302,
          },
          "I.E": {
            x: 37,
            y: 290,
          },
          "RWRK.DRP": {
            x: 50,
            y: 290,
          },
          "D.GLD": {
            x: 37,
            y: 290,
          },
          L1: {
            x: 37,
            y: 290,
          },
          L3: {
            x: 37,
            y: 290,
          },
          "D.N": {
            x: 37,
            y: 290,
          },
          "E.D": {
            x: 37,
            y: 290,
          },
          NOR: {
            x: 20,
            y: 290,
          },
          KE: {
            x: 20,
            y: 290,
          },
          OTHERS: {
            x: 44,
            y: 298,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 98900,
          startY: 13000,
          endX: 485500,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 340,
          y: -35,
        },
        position: "UP",
      },
    },
    HOU: {
      rackStorage: {
        bounds: {
          startX: -2500,
          startY: 1600,
          endX: 376900,
          endY: 116000,
        },
        groupOffset: { x: -68, y: -35 },
        eachItemOffset: {
          "SR.E.039.01": { x: -68, y: -25 },
          "SR.E.040.01": { x: -68, y: -25 },
          "SR.E.041.01": { x: -68, y: -25 },
          "SR.E.042.01": { x: -68, y: -25 },
          "SR.E.043.01": { x: -68, y: -25 },
          "SR.E.044.01": { x: -68, y: -25 },
          "SR.E.045.01": { x: -68, y: -25 },
          "SR.E.046.01": { x: -68, y: -25 },
          "SR.E.047.01": { x: -68, y: -25 },
          "SR.E.048.01": { x: -68, y: -25 },
          "SR.E.049.01": { x: -68, y: -25 },
          "SR.E.050.01": { x: -68, y: -25 },
          "SR.E.051.01": { x: -68, y: -25 },
          "SR.E.052.01": { x: -68, y: -25 },

          "SR.E.001.01": { x: -68, y: -25 },
          "SR.E.002.01": { x: -68, y: -25 },
          "SR.E.003.01": { x: -68, y: -25 },
          "SR.E.004.01": { x: -68, y: -25 },
          "SR.E.005.01": { x: -68, y: -25 },
          "SR.E.006.01": { x: -68, y: -25 },
          "SR.E.007.01": { x: -68, y: -25 },
          "SR.E.008.01": { x: -68, y: -25 },
          "SR.E.009.01": { x: -68, y: -25 },
          "SR.E.010.01": { x: -68, y: -25 },
          "SR.E.011.01": { x: -68, y: -25 },
          "SR.E.012.01": { x: -68, y: -25 },
          "SR.E.013.01": { x: -68, y: -25 },
          "SR.E.014.01": { x: -68, y: -25 },
          "SR.E.015.01": { x: -68, y: -25 },
          "SR.E.016.01": { x: -68, y: -25 },
          "SR.E.017.01": { x: -68, y: -25 },
          "SR.E.018.01": { x: -68, y: -25 },

          "SR.E.019.01": { x: -56, y: -25 },
          "SR.E.020.01": { x: -59, y: -25 },
          "SR.E.021.01": { x: -60, y: -25 },
          "SR.E.022.01": { x: -60, y: -25 },
          "SR.E.023.01": { x: -60, y: -25 },
          "SR.E.024.01": { x: -60, y: -25 },
          "SR.E.025.01": { x: -60, y: -25 },
          "SR.E.026.01": { x: -60, y: -25 },
          "SR.E.027.01": { x: -60, y: -25 },
          "SR.E.028.01": { x: -60, y: -25 },
          "SR.E.029.01": { x: -60, y: -25 },
          "SR.E.030.01": { x: -76, y: -25 },
          "SR.E.031.01": { x: -76, y: -25 },
          "SR.E.032.01": { x: -76, y: -25 },
          "SR.E.033.01": { x: -76, y: -25 },
          "SR.E.034.01": { x: -76, y: -25 },
          "SR.E.035.01": { x: -76, y: -25 },
          "SR.E.036.01": { x: -76, y: -25 },
          "SR.E.037.01": { x: -76, y: -25 },

          "SSR.032.01": { x: -60, y: -35 },
          "SSR.031.01": { x: -60, y: -35 },
          "SSR.029.01": { x: -60, y: -35 },
          "SSR.027.01": { x: -60, y: -35 },
          "SSR.025.01": { x: -60, y: -35 },
          "SSR.023.01": { x: -60, y: -35 },
          "SSR.019.01": { x: -60, y: -35 },
          "SSR.015.01": { x: -60, y: -35 },
          "SSR.013.01": { x: -60, y: -35 },
          "SSR.011.01": { x: -60, y: -35 },
          "SSR.009.01": { x: -60, y: -35 },
          "SSR.007.01": { x: -60, y: -35 },
          "SSR.006.01": { x: -60, y: -35 },
          "SSR.004.01": { x: -60, y: -35 },

          "SOR.054.03": { x: -60, y: -35 },
          "SOR.053.03": { x: -60, y: -35 },
          "SOR.051.03": { x: -60, y: -35 },
          "SOR.050.03": { x: -60, y: -35 },
          "SOR.047.03": { x: -60, y: -35 },
          "SOR.046.03": { x: -60, y: -35 },
          "SOR.044.03": { x: -60, y: -35 },
          "SOR.043.03": { x: -60, y: -35 },
          "SOR.040.03": { x: -60, y: -35 },
          "SOR.039.03": { x: -60, y: -35 },
          "SOR.037.03": { x: -60, y: -35 },
          "SOR.036.03": { x: -60, y: -35 },
          "SOR.034.03": { x: -60, y: -35 },
          "SOR.033.03": { x: -60, y: -35 },
          "SOR.030.03": { x: -60, y: -35 },
          "SOR.029.03": { x: -60, y: -35 },
          "SOR.026.03": { x: -60, y: -35 },
          "SOR.025.03": { x: -60, y: -35 },
          "SOR.023.03": { x: -60, y: -35 },
          "SOR.022.03": { x: -60, y: -35 },
          "SOR.019.03": { x: -60, y: -35 },
          "SOR.018.03": { x: -60, y: -35 },
          "SOR.016.03": { x: -60, y: -35 },
          "SOR.015.03": { x: -60, y: -35 },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "5px",
            height: "4.9px",
          },
        },
        bounds: {
          startX: -2500,
          startY: 0,
          endX: 376900,
          endY: 118000,
        },
        eachItemOffset: {
          "A.W.000": { x: -61, y: -41 },
          "A.W.001": { x: -61, y: -41 },
          "A.W.002": { x: -61, y: -41 },
          "A.W.003": { x: -61, y: -41 },
          "A.W.004": { x: -62, y: -43 },
          "A.W.005": { x: -62, y: -43 },
          "A.W.006": { x: -62, y: -43 },
          "A.W.007": { x: -62, y: -43 },
          "A.W.008": { x: -63, y: -41 },
          "A.W.009": { x: -63, y: -41 },

          "A.E.001": { x: -61, y: -28 },
          "A.E.002": { x: -61, y: -28 },
          "A.E.003": { x: -61, y: -28 },
          "A.E.004": { x: -61, y: -28 },
          "A.E.005": { x: -62, y: -28 },
          "A.E.006": { x: -62, y: -28 },
          "A.E.007": { x: -63, y: -28 },
          "A.E.008": { x: -63, y: -28 },
          "A.E.009": { x: -63, y: -28 },

          "A.E.010": { x: -63, y: -28 },
          "A.E.011": { x: -64, y: -28 },
          "A.E.012": { x: -64, y: -28 },
          "A.E.013": { x: -65, y: -28 },

          "B.W.001": { x: -61, y: -38 },
          "B.W.002": { x: -61, y: -38 },
          "B.W.003": { x: -61, y: -38 },
          "B.W.004": { x: -61, y: -40 },
          "B.W.005": { x: -62, y: -40 },
          "B.W.006": { x: -62, y: -38 },
          "B.W.007": { x: -63, y: -38 },
          "B.W.008": { x: -64, y: -40 },
          "B.W.009": { x: -64, y: -40 },

          "B.W.010": { x: -63, y: -38 },
          "B.W.011": { x: -64, y: -38 },
          "B.W.012": { x: -64, y: -38 },
          "B.W.013": { x: -65, y: -38 },

          "B.E.001": { x: -61, y: -28 },
          "B.E.002": { x: -61, y: -28 },
          "B.E.003": { x: -61, y: -28 },
          "B.E.004": { x: -61, y: -28 },
          "B.E.005": { x: -62, y: -28 },
          "B.E.006": { x: -62, y: -28 },
          "B.E.007": { x: -63, y: -28 },
          "B.E.008": { x: -63, y: -28 },
          "B.E.009": { x: -63, y: -28 },
          "B.E.010": { x: -63, y: -28 },
          "B.E.011": { x: -64, y: -28 },
          "B.E.012": { x: -64, y: -28 },
          "B.E.013": { x: -65, y: -28 },

          "C.W.001": { x: -61, y: -38 },
          "C.W.002": { x: -61, y: -38 },
          "C.W.003": { x: -61, y: -38 },
          "C.W.004": { x: -61, y: -38 },
          "C.W.005": { x: -62, y: -38 },
          "C.W.006": { x: -62, y: -38 },
          "C.W.007": { x: -63, y: -38 },
          "C.W.008": { x: -63, y: -38 },
          "C.W.009": { x: -63, y: -38 },
          "C.W.010": { x: -63, y: -38 },
          "C.W.011": { x: -64, y: -38 },
          "C.W.012": { x: -64, y: -38 },
          "C.W.013": { x: -65, y: -38 },

          "C.E.001": { x: -61, y: -24 },
          "C.E.002": { x: -61, y: -24 },
          "C.E.003": { x: -61, y: -24 },
          "C.E.004": { x: -61, y: -24 },
          "C.E.005": { x: -62, y: -26 },
          "C.E.006": { x: -62, y: -26 },
          "C.E.007": { x: -63, y: -26 },
          "C.E.008": { x: -63, y: -26 },
          "C.E.009": { x: -63, y: -26 },
          "C.E.010": { x: -64, y: -24 },
          "C.E.011": { x: -64, y: -24 },
          "C.E.012": { x: -64, y: -24 },

          "D.W.001": { x: -60, y: -38 },
          "D.W.002": { x: -61, y: -38 },
          "D.W.003": { x: -60, y: -38 },

          "D.W.004": { x: -61, y: -38 },
          "D.W.005": { x: -62, y: -38 },
          "D.W.006": { x: -62, y: -38 },
          "D.W.007": { x: -63, y: -38 },
          "D.W.008": { x: -63, y: -38 },
          "D.W.009": { x: -63, y: -38 },
          "D.W.010": { x: -68, y: -36 },

          "D.E.001": { x: -61, y: -23 },
          "D.E.002": { x: -61, y: -23 },
          "D.E.003": { x: -61, y: -23 },
          "D.E.004": { x: -61, y: -20 },
          "D.GLD.005": { x: -61, y: -20 },
          "D.GLD.006": { x: -62, y: -20 },
          "D.GLD.007": { x: -63, y: -20 },
          "D.GLD.008": { x: -63, y: -20 },
          "D.GLD.009": { x: -63, y: -20 },
          "D.GLD.010": { x: -63, y: -20 },
          "D.GLD.011": { x: -63, y: -20 },
          "D.GLD.012": { x: -63, y: -20 },

          "E.W.004": { x: -61, y: -35 },
          "E.W.005": { x: -61, y: -35 },
          "E.W.006": { x: -61, y: -35 },
          "E.W.008": { x: -63, y: -35 },
          "E.W.009": { x: -63, y: -35 },
          "E.W.010": { x: -63, y: -35 },
          "E.W.011": { x: -62, y: -35 },
          "E.W.012": { x: -62, y: -35 },

          "E.E.007": { x: -64, y: -22 },
          "E.E.008": { x: -63, y: -22 },
          "E.E.009": { x: -63, y: -22 },
          "E.EMP.010": { x: -63, y: -22 },
          "E.EMP.011": { x: -63, y: -22 },
          "E.EMP.012": { x: -63, y: -22 },
          "E.E.013": { x: -64, y: -22 },
          "E.E.014": { x: -64, y: -22 },
          "E.E.015": { x: -65, y: -22 },

          "H.E.000": { x: -67, y: -23 },
          "H.E.001": { x: -67, y: -23 },
          "H.E.002": { x: -68, y: -23 },
          "H.E.003": { x: -68, y: -23 },
          "H.E.004": { x: -68, y: -23 },
          "H.E.005": { x: -69, y: -23 },
          "H.E.006": { x: -68, y: -23 },
          "H.E.007": { x: -69, y: -23 },
          "H.E.008": { x: -70, y: -23 },
          "H.E.009": { x: -70, y: -23 },
          "H.E.010": { x: -70, y: -23 },
          "H.E.011": { x: -70, y: -23 },
          "H.E.012": { x: -69, y: -23 },
          "H.E.013": { x: -69, y: -23 },
          "H.E.014": { x: -70, y: -23 },
          "H.E.015": { x: -70, y: -23 },
          "H.E.016": { x: -71, y: -23 },
          "H.E.017": { x: -71, y: -23 },

          "G.E.000": { x: -66, y: -23 },
          "G.E.001": { x: -67, y: -23 },
          "G.E.002": { x: -68, y: -23 },
          "G.E.003": { x: -68, y: -23 },
          "G.E.004": { x: -68, y: -23 },
          "G.E.005": { x: -69, y: -23 },
          "G.E.006": { x: -68, y: -23 },
          "G.E.007": { x: -69, y: -23 },
          "G.E.008": { x: -70, y: -23 },
          "G.E.009": { x: -70, y: -23 },
          "G.E.010": { x: -70, y: -23 },
          "G.E.011": { x: -70, y: -23 },
          "G.E.012": { x: -69, y: -23 },
          "G.E.013": { x: -69, y: -23 },
          "G.E.014": { x: -70, y: -23 },
          "G.E.015": { x: -70, y: -23 },
          "G.E.016": { x: -71, y: -23 },
          "G.E.017": { x: -71, y: -23 },

          "H.W.000": { x: -66, y: -35 },
          "H.W.001": { x: -67, y: -35 },
          "H.W.002": { x: -68, y: -35 },
          "H.W.003": { x: -68, y: -35 },
          "H.W.004": { x: -68, y: -35 },
          "H.W.005": { x: -69, y: -35 },
          "H.W.006": { x: -68, y: -35 },
          "H.W.007": { x: -68, y: -35 },
          "H.W.008": { x: -69, y: -35 },
          "H.W.009": { x: -70, y: -35 },
          "H.W.010": { x: -70, y: -35 },
          "H.W.011": { x: -70, y: -35 },
          "H.W.012": { x: -69, y: -35 },
          "H.W.013": { x: -69, y: -35 },
          "H.W.014": { x: -70, y: -35 },
          "H.W.015": { x: -70, y: -35 },
          "H.W.016": { x: -71, y: -35 },
          "H.W.017": { x: -71, y: -35 },

          "G.W.000": { x: -66, y: -38 },
          "G.W.001": { x: -67, y: -38 },
          "G.W.002": { x: -68, y: -38 },
          "G.W.003": { x: -68, y: -38 },
          "G.W.004": { x: -68, y: -38 },
          "G.W.005": { x: -69, y: -38 },
          "G.W.006": { x: -68, y: -38 },
          "G.W.007": { x: -68, y: -38 },
          "G.W.008": { x: -69, y: -38 },
          "G.W.009": { x: -70, y: -38 },
          "G.W.010": { x: -70, y: -38 },
          "G.W.011": { x: -70, y: -38 },
          "G.W.012": { x: -69, y: -38 },
          "G.W.013": { x: -69, y: -38 },
          "G.W.014": { x: -70, y: -38 },
          "G.W.015": { x: -70, y: -38 },
          "G.W.016": { x: -71, y: -38 },
          "G.W.017": { x: -71, y: -38 },
        },
        eastLocationOffset: { x: -68, y: -20 },
        westLocationOffset: { x: -68, y: -35 },
        groupOffset: { x: -68, y: 40 },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 140,
          y: 220,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -130,
          y: -10,
        },
        eachItemOffset: {
          L1: { x: 0, y: 45 },
          L2: { x: 0, y: 85 },
          L3: { x: 0, y: 90 },
          L4: { x: -45, y: -7 },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        coordinateCorrection: {
          source: {
            x: 60,
            y: 420,
          },
          destination: {
            x: 20,
            y: 420,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 60,
            y: 420,
          },
          HNDOFF: {
            x: 60,
            y: 400,
          },
          "G.W": {
            x: -90,
            y: -40,
          },
          "H.W": {
            x: 60,
            y: 420,
          },
          "C.E": {
            x: 60,
            y: 420,
          },
          "J.W": {
            x: 60,
            y: 420,
          },
          SSR: {
            x: 60,
            y: 420,
          },
          "E.W": {
            x: -200,
            y: 100,
          },
          STR: {
            x: 60,
            y: 350,
          },
          SOR: {
            x: 80,
            y: 430,
          },
          "I.W": {
            x: 40,
            y: 520,
          },
          BATT: {
            x: 40,
            y: 520,
          },
          "A.E": {
            x: 40,
            y: 520,
          },
          "RDR.K": {
            x: 40,
            y: 520,
          },
          "A.W": {
            x: 60,
            y: 420,
          },
          "B.E": {
            x: 60,
            y: 420,
          },
          NSR: {
            x: 40,
            y: 520,
          },
          "D.E": {
            x: 60,
            y: 420,
          },
          "C.W.GLD": {
            x: 40,
            y: 520,
          },
          "SR.B.E": {
            x: 40,
            y: 520,
          },
          "B.W": {
            x: 60,
            y: 420,
          },
          "J.E": {
            x: 40,
            y: 520,
          },
          "H.E": {
            x: 58,
            y: 420,
          },
          "RSR.P.N": {
            x: 40,
            y: 520,
          },
          "C.W": {
            x: 60,
            y: 420,
          },
          CW: {
            x: 60,
            y: 420,
          },
          "D.W": {
            x: 60,
            y: 420,
          },
          "F.W": {
            x: 60,
            y: 420,
          },
          "G.E": {
            x: 60,
            y: 420,
          },
          "F.E": {
            x: 60,
            y: 420,
          },
          "I.E": {
            x: 60,
            y: 420,
          },
          "RWRK.DRP": {
            x: 60,
            y: 420,
          },
          "D.GLD": {
            x: 60,
            y: 420,
          },
          L1: {
            x: 60,
            y: 420,
          },
          L3: {
            x: 60,
            y: 420,
          },
          "D.N": {
            x: 60,
            y: 420,
          },
          "E.D": {
            x: 60,
            y: 420,
          },
          NOR: {
            x: 60,
            y: 420,
          },
          KE: {
            x: 60,
            y: 420,
          },
          OTHERS: {
            x: 60,
            y: 420,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 44300,
          startY: 13000,
          endX: 419300,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 130,
          y: -47,
        },
        position: "UP",
      },
    },
    JAX: {
      rackStorage: {
        bounds: {
          startX: 11000,
          startY: 8500,
          endX: 409000,
          endY: 186500,
        },
        groupOffset: { x: -68, y: -35 },
        eachItemOffset: {
          "RDR.K.S.002.L.01": { x: -68, y: -42 },
          "RDR.K.S.002.R.01": { x: -68, y: -39 },

          "RDR.K.S.003.L.01": { x: -68, y: -42 },
          "RDR.K.S.003.R.01": { x: -68, y: -39 },

          "RDR.K.S.004.L.01": { x: -68, y: -42 },
          "RDR.K.S.004.R.01": { x: -68, y: -39 },

          "RDR.K.S.005.L.01": { x: -68, y: -42 },
          "RDR.K.S.005.R.01": { x: -68, y: -39 },

          "RDR.K.S.006.L.01": { x: -68, y: -42 },
          "RDR.K.S.006.R.01": { x: -68, y: -39 },

          "RDR.K.S.007.L.01": { x: -68, y: -42 },
          "RDR.K.S.007.R.01": { x: -68, y: -39 },

          "RDR.K.S.013.L.01": { x: -68, y: -42 },
          "RDR.K.S.013.R.01": { x: -68, y: -40 },

          "RDR.K.S.014.L.01": { x: -68, y: -42 },
          "RDR.K.S.014.R.01": { x: -68, y: -40 },

          "RDR.K.S.015.L.01": { x: -68, y: -42 },
          "RDR.K.S.015.R.01": { x: -68, y: -40 },

          "RDR.K.S.016.L.01": { x: -68, y: -42 },
          "RDR.K.S.016.R.01": { x: -68, y: -40 },

          "RDR.K.S.017.L.01": { x: -68, y: -42 },
          "RDR.K.S.017.R.01": { x: -68, y: -40 },

          "RDR.K.S.018.L.01": { x: -68, y: -42 },
          "RDR.K.S.018.R.01": { x: -68, y: -40 },

          "RDR.K.S.019.L.01": { x: -68, y: -42 },
          "RDR.K.S.019.R.01": { x: -68, y: -40 },

          "RDR.K.S.020.L.01": { x: -68, y: -42 },
          "RDR.K.S.020.R.01": { x: -68, y: -40 },

          "RDR.K.S.021.L.01": { x: -68, y: -42 },
          "RDR.K.S.021.R.01": { x: -68, y: -40 },

          "RDR.K.S.024.L.01": { x: -68, y: -40 },
          "RDR.K.S.024.R.01": { x: -68, y: -38 },

          "RDR.K.S.025.L.01": { x: -68, y: -40 },
          "RDR.K.S.025.R.01": { x: -68, y: -38 },

          "RDR.K.S.026.L.01": { x: -68, y: -40 },
          "RDR.K.S.026.R.01": { x: -68, y: -38 },

          "RDR.K.S.027.L.01": { x: -68, y: -40 },
          "RDR.K.S.027.R.01": { x: -68, y: -38 },

          "RDR.K.S.028.L.01": { x: -68, y: -40 },
          "RDR.K.S.028.R.01": { x: -68, y: -38 },

          "RDR.K.S.029.L.01": { x: -68, y: -40 },
          "RDR.K.S.029.R.01": { x: -68, y: -38 },

          "RDR.K.S.030.L.01": { x: -68, y: -40 },
          "RDR.K.S.030.R.01": { x: -68, y: -38 },

          "RDR.K.S.031.L.01": { x: -68, y: -40 },
          "RDR.K.S.031.R.01": { x: -68, y: -38 },

          "RDR.K.S.032.L.01": { x: -68, y: -40 },
          "RDR.K.S.032.R.01": { x: -68, y: -38 },

          "RDR.K.S.033.L.01": { x: -68, y: -40 },
          "RDR.K.S.033.R.01": { x: -68, y: -38 },

          "RDR.K.S.034.L.01": { x: -68, y: -40 },
          "RDR.K.S.034.R.01": { x: -68, y: -38 },

          "RDR.K.S.035.L.01": { x: -68, y: -40 },
          "RDR.K.S.035.R.01": { x: -68, y: -38 },

          "RDR.K.S.036.L.01": { x: -68, y: -70 },
          "RDR.K.S.036.R.01": { x: -68, y: -68 },

          "RDR.K.S.037.L.01": { x: -68, y: -70 },
          "RDR.K.S.037.R.01": { x: -68, y: -68 },

          "RDR.K.S.038.L.01": { x: -68, y: -70 },
          "RDR.K.S.038.R.01": { x: -68, y: -68 },

          "RDR.K.S.039.L.01": { x: -68, y: -70 },
          "RDR.K.S.039.R.01": { x: -68, y: -68 },

          "RDR.K.S.040.L.01": { x: -68, y: -70 },
          "RDR.K.S.040.R.01": { x: -68, y: -68 },

          "RDR.K.S.041.L.01": { x: -68, y: -70 },
          "RDR.K.S.041.R.01": { x: -68, y: -68 },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "4.8px",
            height: "2.9px",
          },
        },
        bounds: {
          startX: 11000,
          startY: 8500,
          endX: 409000,
          endY: 185000,
        },
        eachItemOffset: {
          "J.W.039": {
            x: -68,
            y: -35,
          },
          "C.E.035": {
            x: -68,
            y: -10,
          },
          "C.E.047": {
            x: -68,
            y: -10,
          },
          "J.E.041": {
            x: -68,
            y: -10,
          },
          "J.E.063": {
            x: -68,
            y: -10,
          },
          "J.E.051": {
            x: -68,
            y: -10,
          },
          "C.E.024": {
            x: -68,
            y: -53,
          },
          "D.E.063": {
            x: -63,
            y: -21,
          },
          "D.E.075": {
            x: -63,
            y: -21,
          },
          "D.E.051": {
            x: -63,
            y: -21,
          },
          "E.W.018": {
            x: -63,
            y: -37,
          },
          "E.E.070": {
            x: -63,
            y: -20,
          },
          "E.E.058": {
            x: -63,
            y: -20,
          },
          "E.E.046": {
            x: -63,
            y: -20,
          },
          "F.E.058": {
            x: -63,
            y: -20,
          },
          "F.E.070": {
            x: -63,
            y: -20,
          },
          "G.E.057": {
            x: -63,
            y: -20,
          },
          "G.E.070": {
            x: -73,
            y: -20,
          },
          "I.W.019": {
            x: -64,
            y: -30,
          },
          "I.E.059": {
            x: -64,
            y: -20,
          },
          "I.E.071": {
            x: -62,
            y: -20,
          },
          "J.W.019": {
            x: -64,
            y: -35,
          },
          "F.W.018": {
            x: -63,
            y: -37,
          },
          "F.W.030": {
            x: -63,
            y: -37,
          },
          "E.W.006": {
            x: -63,
            y: -37,
          },
        },
        eastLocationOffset: {
          x: -68,
          y: -21,
        },
        westLocationOffset: {
          x: -68,
          y: -37,
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 1130,
          y: 53,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -20,
          y: 0,
        },
        eachItemOffset: {
          L1: {
            x: 10,
            y: 0,
          },
          L2: {
            x: 10,
            y: 55,
          },
          L3: {
            x: 5,
            y: 125,
          },
          L4: {
            x: 25,
            y: 110,
          },
          L4WIP: {
            x: 25,
            y: 20,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -50,
            y: 190,
          },
          destination: {
            x: -50,
            y: 195,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -50,
            y: 185,
          },
          HNDOFF: {
            x: -50,
            y: 185,
          },
          "G.W": {
            x: -90,
            y: -40,
          },
          "H.W": {
            x: -50,
            y: 185,
          },
          "C.E": {
            x: -50,
            y: 185,
          },
          "J.W": {
            x: -50,
            y: 185,
          },
          SSR: {
            x: -50,
            y: 185,
          },
          "E.W": {
            x: -50,
            y: 185,
          },
          STR: {
            x: -50,
            y: 185,
          },
          SOR: {
            x: -50,
            y: 185,
          },
          "I.W": {
            x: -50,
            y: 180,
          },
          BATT: {
            x: -50,
            y: 185,
          },
          "A.E": {
            x: -50,
            y: 185,
          },
          "RDR.K": {
            x: -50,
            y: 185,
          },
          "A.W": {
            x: -50,
            y: 185,
          },
          "B.E": {
            x: -50,
            y: 185,
          },
          NSR: {
            x: -50,
            y: 185,
          },
          "D.E": {
            x: -50,
            y: 185,
          },
          "C.W.GLD": {
            x: -50,
            y: 185,
          },
          "SR.B.E": {
            x: -50,
            y: 185,
          },
          "B.W": {
            x: -50,
            y: 185,
          },
          "J.E": {
            x: -50,
            y: 185,
          },
          "H.E": {
            x: -50,
            y: 185,
          },
          "RSR.P.N": {
            x: -50,
            y: 185,
          },
          "C.W": {
            x: -50,
            y: 185,
          },
          CW: {
            x: -50,
            y: 185,
          },
          "D.W": {
            x: -50,
            y: 185,
          },
          "F.W": {
            x: -45,
            y: 185,
          },
          "G.E": {
            x: -50,
            y: 185,
          },
          "F.E": {
            x: -50,
            y: 185,
          },
          "I.E": {
            x: -50,
            y: 185,
          },
          "RWRK.DRP": {
            x: -50,
            y: 185,
          },
          "D.GLD": {
            x: -50,
            y: 185,
          },
          L1: {
            x: -50,
            y: 185,
          },
          L3: {
            x: -50,
            y: 185,
          },
          "D.N": {
            x: -50,
            y: 185,
          },
          "E.D": {
            x: -50,
            y: 185,
          },
          NOR: {
            x: -50,
            y: 185,
          },
          KE: {
            x: -50,
            y: 185,
          },
          OTHERS: {
            x: -50,
            y: 185,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 40000,
          startY: 13000,
          endX: 434000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 70,
          y: 71,
        },
        position: "DOWN",
      },
    },
    BAY: {
      rackStorage: {
        bounds: {
          startX: -96000,
          startY: -55800,
          endX: 240000,
          endY: 125000,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {},
      },
      blockStorage: {
        marker: {
          size: {
            width: "5.6px",
            height: "2.9px",
          },
        },
        bounds: {
          startX: -96000,
          startY: -55800,
          endX: 240000,
          endY: 125000,
        },
        eachItemOffset: {
          "A.E.076": {
            x: -68,
            y: -30,
          },
          "A.E.064": {
            x: -68,
            y: -30,
          },
          "A.E.052": {
            x: -68,
            y: -30,
          },
          "C.E.070": {
            x: -68,
            y: -30,
          },
          "C.E.058": {
            x: -68,
            y: -30,
          },
          "C.E.046": {
            x: -68,
            y: -30,
          },
          "E.E.031": {
            x: -68,
            y: -30,
          },
          "D.E.041": {
            x: -68,
            y: -30,
          },
          "D.E.058": {
            x: -68,
            y: -30,
          },
          "D.E.070": {
            x: -68,
            y: -30,
          },
          "D.E.047": {
            x: -68,
            y: -30,
          },
          "B.E.058": {
            x: -65,
            y: -32,
          },
          "B.E.046": {
            x: -68,
            y: -32,
          },
          "B.E.070": {
            x: -65,
            y: -32,
          },
          "F.E.031": {
            x: -68,
            y: -30,
          },
          "F.E.037": {
            x: -68,
            y: -30,
          },
          "G.E.037": {
            x: -68,
            y: -28,
          },
          "G.E.031": {
            x: -68,
            y: -28,
          },
          "D.W.030": {
            x: -68,
            y: -59,
          },
          "D.W.018": {
            x: -68,
            y: -59,
          },
          "D.W.006": {
            x: -68,
            y: -59,
          },
        },
        eastLocationOffset: {
          x: -68,
          y: -37,
        },
        westLocationOffset: {
          x: -68,
          y: -54,
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [22, 50],
          rotation: true,
        },
        coordinate: {
          x: 1370,
          y: 118,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -450,
          y: 125,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: -15,
          },
          L2: {
            x: 0,
            y: 15,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 80,
            y: 776,
          },
          destination: {
            x: 20,
            y: 770,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 80,
            y: 770,
          },
          HNDOFF: {
            x: 80,
            y: 770,
          },
          "G.W": {
            x: -90,
            y: -40,
          },
          "H.W": {
            x: 80,
            y: 770,
          },
          "C.E": {
            x: 80,
            y: 770,
          },
          "J.W": {
            x: 80,
            y: 770,
          },
          SSR: {
            x: 80,
            y: 770,
          },
          "E.W": {
            x: 80,
            y: 770,
          },
          STR: {
            x: 80,
            y: 770,
          },
          SOR: {
            x: 80,
            y: 770,
          },
          "I.W": {
            x: 80,
            y: 770,
          },
          BATT: {
            x: 80,
            y: 770,
          },
          "A.E": {
            x: 80,
            y: 770,
          },
          "RDR.K": {
            x: 80,
            y: 770,
          },
          "A.W": {
            x: 80,
            y: 770,
          },
          "B.E": {
            x: 80,
            y: 770,
          },
          NSR: {
            x: 80,
            y: 770,
          },
          "D.E": {
            x: 80,
            y: 770,
          },
          "C.W.GLD": {
            x: 80,
            y: 770,
          },
          "SR.B.E": {
            x: 80,
            y: 770,
          },
          "B.W": {
            x: 80,
            y: 770,
          },
          "J.E": {
            x: 80,
            y: 770,
          },
          "H.E": {
            x: 80,
            y: 770,
          },
          "RSR.P.N": {
            x: 80,
            y: 770,
          },
          "C.W": {
            x: 80,
            y: 770,
          },
          CW: {
            x: 80,
            y: 770,
          },
          "D.W": {
            x: 80,
            y: 770,
          },
          "F.W": {
            x: 80,
            y: 770,
          },
          "G.E": {
            x: 80,
            y: 770,
          },
          "F.E": {
            x: 70,
            y: 770,
          },
          "I.E": {
            x: 70,
            y: 770,
          },
          "RWRK.DRP": {
            x: 70,
            y: 770,
          },
          "D.GLD": {
            x: 70,
            y: 770,
          },
          L1: {
            x: 70,
            y: 770,
          },
          L3: {
            x: 70,
            y: 770,
          },
          "D.N": {
            x: 70,
            y: 770,
          },
          "E.D": {
            x: 70,
            y: 770,
          },
          NOR: {
            x: 70,
            y: 770,
          },
          KE: {
            x: 70,
            y: 770,
          },
          OTHERS: {
            x: 80,
            y: 770,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 11000,
          startY: 13000,
          endX: 346000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 405,
          y: -46,
        },
        position: "UP",
      },
    },
    TAC: {
      rackStorage: {
        bounds: {
          startX: -40000,
          startY: 55000,
          endX: 310000,
          endY: 208000,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {
          "SR.E.025.01": {
            x: -68,
            y: -28,
          },
          "SR.E.026.01": {
            x: -68,
            y: -28,
          },
          "SR.E.027.01": {
            x: -68,
            y: -28,
          },
          "SR.E.028.01": {
            x: -68,
            y: -28,
          },
          "SR.E.029.01": {
            x: -68,
            y: -28,
          },
          "SR.E.030.01": {
            x: -68,
            y: -28,
          },
          "SR.E.031.01": {
            x: -68,
            y: -28,
          },
          "SR.E.032.01": {
            x: -68,
            y: -28,
          },
          "SR.E.035.01": {
            x: -68,
            y: -28,
          },
          "SR.E.036.01": {
            x: -68,
            y: -28,
          },
          "SR.E.037.01": {
            x: -68,
            y: -28,
          },
          "SR.E.038.01": {
            x: -68,
            y: -28,
          },
          "SR.E.039.01": {
            x: -68,
            y: -28,
          },
          "SR.E.040.01": {
            x: -68,
            y: -28,
          },
          "SR.E.041.01": {
            x: -68,
            y: -28,
          },
          "SR.E.042.01": {
            x: -68,
            y: -28,
          },
          "SR.E.043.01": {
            x: -68,
            y: -28,
          },
          "SR.E.044.01": {
            x: -68,
            y: -28,
          },
          "SR.E.045.01": {
            x: -68,
            y: -28,
          },
          "SR.E.046.01": {
            x: -68,
            y: -28,
          },
          "SR.E.049.01": {
            x: -68,
            y: -28,
          },
          "SR.E.050.01": {
            x: -68,
            y: -28,
          },
          "SR.E.051.01": {
            x: -68,
            y: -28,
          },
          "SR.E.052.01": {
            x: -68,
            y: -28,
          },
          "SR.B.E.025.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.026.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.027.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.028.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.029.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.030.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.031.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.032.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.033.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.034.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.035.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.036.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.037.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.038.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.039.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.040.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.041.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.042.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.043.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.044.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.045.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.046.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.047.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.048.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.049.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.050.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.051.01": {
            x: -68,
            y: -30,
          },
          "SR.B.E.052.01": {
            x: -68,
            y: -30,
          },
          "RDD.RSR.N.01.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.02.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.03.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.04.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.05.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.06.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.07.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.08.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.09.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.10.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.11.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.12.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.13.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.14.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.15.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.16.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.17.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.18.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.19.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.20.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.21.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.22.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.23.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.24.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.25.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.26.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.27.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.28.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.29.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.30.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.31.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.32.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.33.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.34.01": {
            x: -56,
            y: -35,
          },
          "RDD.RSR.N.35.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.36.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.37.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.38.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.39.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.40.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.41.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.42.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.43.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.44.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.45.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.46.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.47.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.48.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.49.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.50.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.51.01": {
            x: -56,
            y: -42,
          },
          "RDD.RSR.N.52.01": {
            x: -56,
            y: -42,
          },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "5.4px",
            height: "3.4px",
          },
        },
        bounds: {
          startX: -52000,
          startY: 49000,
          endX: 336000,
          endY: 211000,
        },
        eachItemOffset: {
          "A.E.001": { x: -68, y: -17 },
          "A.E.002": { x: -67, y: -17 },
          "A.E.003": { x: -67, y: -17 },
          "A.E.004": { x: -67, y: -17 },
          "A.E.005": { x: -68, y: -17 },
          "A.E.006": { x: -67, y: -17 },
          "A.E.007": { x: -68, y: -17 },
          "A.E.008": { x: -68, y: -17 },
          "A.E.009": { x: -69, y: -17 },
          "A.E.010": { x: -68, y: -17 },
          "A.E.011": { x: -67, y: -17 },
          "A.E.012": { x: -68, y: -17 },
          "A.E.013": { x: -68, y: -17 },
          "A.E.014": { x: -68, y: -17 },
          "A.E.015": { x: -68, y: -17 },
          "A.E.016": { x: -67, y: -17 },
          "A.E.017": { x: -68, y: -17 },
          "A.E.018": { x: -67, y: -17 },
          "A.E.019": { x: -68, y: -17 },
          "A.E.020": { x: -68, y: -17 },
          "A.E.021": { x: -68, y: -17 },
          "A.E.022": { x: -68, y: -17 },
          "A.E.023": { x: -67, y: -17 },
          "A.E.024": { x: -68, y: -17 },
          "A.E.025": { x: -68, y: -17 },
          "A.E.026": { x: -68, y: -17 },
          "A.E.027": { x: -68, y: -17 },
          "A.E.028": { x: -68, y: -17 },
          "A.E.029": { x: -68, y: -17 },
          "B.W.001": { x: -68, y: -23 },
          "B.W.002": { x: -67, y: -23 },
          "B.W.003": { x: -67, y: -23 },
          "B.W.004": { x: -67, y: -23 },
          "B.W.005": { x: -68, y: -23 },
          "B.W.006": { x: -67, y: -23 },
          "B.W.007": { x: -68, y: -23 },
          "B.W.008": { x: -68, y: -23 },
          "B.W.009": { x: -69, y: -23 },
          "B.W.010": { x: -68, y: -23 },
          "B.W.011": { x: -67, y: -23 },
          "B.W.012": { x: -68, y: -23 },
          "B.W.013": { x: -68, y: -23 },
          "B.W.014": { x: -68, y: -23 },
          "B.W.015": { x: -68, y: -23 },
          "B.W.016": { x: -67, y: -23 },
          "B.W.017": { x: -68, y: -23 },
          "B.W.018": { x: -67, y: -23 },
          "B.W.019": { x: -68, y: -23 },
          "B.W.020": { x: -68, y: -23 },
          "B.W.021": { x: -68, y: -23 },
          "B.W.022": { x: -68, y: -23 },
          "B.W.023": { x: -67, y: -23 },
          "B.W.024": { x: -68, y: -23 },
          "B.W.025": { x: -68, y: -23 },
          "B.W.026": { x: -68, y: -23 },
          "B.W.027": { x: -68, y: -23 },
          "B.W.028": { x: -68, y: -23 },
          "B.W.029": { x: -68, y: -23 },

          "B.E.001": { x: -68, y: 0 },
          "B.E.002": { x: -67, y: 0 },
          "B.E.003": { x: -67, y: 0 },
          "B.E.004": { x: -67, y: 0 },
          "B.E.005": { x: -68, y: 0 },
          "B.E.006": { x: -67, y: 0 },
          "B.E.007": { x: -68, y: 0 },
          "B.E.008": { x: -68, y: 0 },
          "B.E.009": { x: -69, y: 0 },
          "B.E.010": { x: -68, y: 0 },
          "B.E.011": { x: -67, y: 0 },
          "B.E.012": { x: -68, y: 0 },
          "B.E.013": { x: -68, y: 0 },
          "B.E.014": { x: -68, y: 0 },
          "B.E.015": { x: -68, y: 0 },
          "B.E.016": { x: -67, y: 0 },
          "B.E.017": { x: -68, y: 0 },
          "B.E.018": { x: -67, y: 0 },
          "B.E.019": { x: -68, y: 0 },
          "B.E.020": { x: -68, y: 0 },
          "B.E.021": { x: -68, y: 0 },
          "B.E.022": { x: -68, y: 0 },
          "B.E.023": { x: -67, y: 0 },
          "B.E.024": { x: -68, y: 0 },
          "B.E.025": { x: -68, y: 0 },
          "B.E.026": { x: -68, y: 0 },
          "B.E.027": { x: -68, y: 0 },
          "B.E.028": { x: -68, y: 0 },
          "B.E.029": { x: -68, y: 0 },

          "C.W.001": { x: -68, y: -5 },
          "C.W.002": { x: -67, y: -5 },
          "C.W.003": { x: -67, y: -5 },
          "C.W.004": { x: -67, y: -5 },
          "C.W.005": { x: -68, y: -5 },
          "C.W.006": { x: -67, y: -5 },
          "C.W.007": { x: -68, y: -5 },
          "C.W.008": { x: -68, y: -5 },
          "C.W.009": { x: -69, y: -5 },
          "C.W.010": { x: -68, y: -5 },
          "C.W.011": { x: -67, y: -5 },
          "C.W.012": { x: -68, y: -5 },
          "C.W.013": { x: -68, y: -5 },
          "C.W.014": { x: -68, y: -5 },
          "C.W.015": { x: -68, y: -5 },
          "C.W.029": { x: -68, y: -5 },
          "C.W.030": { x: -68, y: -10 },

          "C.W.GLD.016": { x: -68, y: 4 },
          "C.W.GLD.017": { x: -68, y: 4 },
          "C.W.GLD.018": { x: -68, y: 4 },
          "C.W.GLD.019": { x: -68, y: 4 },
          "C.W.GLD.020": { x: -68, y: 4 },
          "C.W.GLD.021": { x: -68, y: 4 },
          "C.W.GLD.022": { x: -68, y: 4 },
          "C.W.GLD.023": { x: -68, y: 4 },
          "C.W.GLD.024": { x: -68, y: 4 },
          "C.W.GLD.025": { x: -68, y: 4 },
          "C.W.GLD.026": { x: -68, y: 4 },
          "C.W.GLD.027": { x: -68, y: 4 },
          "C.W.GLD.028": { x: -68, y: 4 },

          "C.E.001": { x: -68, y: 34 },
          "C.E.002": { x: -67, y: 24 },
          "C.E.003": { x: -67, y: 24 },
          "C.E.004": { x: -67, y: 23 },
          "C.E.005": { x: -68, y: 23 },
          "C.E.006": { x: -67, y: 23 },
          "C.E.007": { x: -68, y: 23 },
          "C.E.008": { x: -68, y: 23 },
          "C.E.009": { x: -69, y: 23 },
          "C.E.010": { x: -68, y: 23 },
          "C.E.011": { x: -67, y: 23 },
          "C.E.012": { x: -68, y: 23 },
          "C.E.013": { x: -68, y: 23 },
          "C.E.014": { x: -68, y: 23 },
          "C.E.015": { x: -68, y: 23 },
          "C.E.016": { x: -67, y: 23 },
          "C.E.017": { x: -68, y: 23 },
          "C.E.018": { x: -67, y: 23 },
          "C.E.019": { x: -68, y: 23 },
          "C.E.020": { x: -68, y: 23 },
          "C.E.021": { x: -68, y: 23 },
          "C.E.022": { x: -68, y: 23 },
          "C.E.023": { x: -67, y: 23 },
          "C.E.024": { x: -68, y: 23 },
          "C.E.025": { x: -68, y: 23 },
          "C.E.026": { x: -68, y: 23 },
          "C.E.027": { x: -68, y: 23 },
          "C.E.028": { x: -67, y: 23 },
          "C.E.029": { x: -68, y: 23 },
          "C.E.030": { x: -67, y: 23 },
          "C.E.031": { x: -68, y: 23 },
          "C.E.032": { x: -68, y: 23 },
          "C.E.033": { x: -68, y: 23 },
          "C.E.034": { x: -68, y: 55 },
          "C.E.035": { x: -68, y: 55 },
          "C.E.036": { x: -68, y: 55 },
          "C.E.037": { x: -68, y: 55 },
          "C.E.038": { x: -68, y: 55 },
          "C.E.039": { x: -68, y: 55 },
          "C.E.040": { x: -68, y: 55 },

          //A.E
          "A.E.034": { x: -67, y: -19 },
          "A.E.035": { x: -67, y: -19 },
          "A.E.036": { x: -67, y: -18 },
          "A.E.037": { x: -67, y: -18 },
          "A.E.038": { x: -68, y: -18 },
          "A.E.039": { x: -68, y: -18 },
          "A.E.040": { x: -68, y: -18 },
          //O.S
          "O.S.001": { x: -65, y: -28 },
          "O.S.002": { x: -65, y: -6 },
          "O.S.003": { x: -65, y: -5 },
          "O.S.004": { x: -65, y: -3 },
          "O.S.005": { x: -65, y: -28 },
          "O.S.006": { x: -65, y: -6 },
          "O.S.007": { x: -65, y: -5 },
          "O.S.008": { x: -65, y: -4 },
          "O.S.009": { x: -65, y: -3 },
          "O.S.010": { x: -65, y: -2 },
          "O.S.011": { x: -65, y: -2 },

          "A.W.011": { x: -67, y: -42 },
          "A.W.016": { x: -67, y: -42 },
          "A.W.018": { x: -67, y: -42 },
          "A.W.023": { x: -67, y: -42 },
        },
        eastLocationOffset: {
          x: -68,
          y: -20,
        },
        westLocationOffset: {
          x: -68,
          y: -42,
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 990,
          y: 425,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -425,
          y: -210,
        },
        eachItemOffset: {
          L1: {
            x: 20,
            y: -20,
          },
          L2: {
            x: 20,
            y: -40,
          },
          L3: {
            x: 105,
            y: -120,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -202,
            y: 325,
          },
          destination: {
            x: -250,
            y: 320,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -200,
            y: 310,
          },
          HNDOFF: {
            x: -200,
            y: 260,
          },
          "A.W": {
            x: -203,
            y: 420,
          },
          "H.W": {
            x: -205,
            y: 380,
          },
          "C.E": {
            x: -202,
            y: 310,
          },
          "J.W": {
            x: -200,
            y: 310,
          },
          "G.W": {
            x: -230,
            y: 400,
          },
          SSR: {
            x: -200,
            y: 310,
          },
          "E.W": {
            x: -200,
            y: 310,
          },
          STR: {
            x: -200,
            y: 330,
          },
          SOR: {
            x: -205,
            y: 310,
          },
          "I.W": {
            x: -200,
            y: 310,
          },
          BATT: {
            x: -250,
            y: 320,
          },
          "A.E": {
            x: -210,
            y: 320,
          },
          "RDR.K": {
            x: -200,
            y: 310,
          },
          "B.E": {
            x: -200,
            y: 180,
          },
          NSR: {
            x: -200,
            y: 280,
          },
          "D.E": {
            x: -200,
            y: 310,
          },
          "C.W.GLD": {
            x: -205,
            y: 310,
          },
          "SR.B.E": {
            x: -200,
            y: 260,
          },
          "B.W": {
            x: -205,
            y: 300,
          },
          "J.E": {
            x: -200,
            y: 310,
          },
          "H.E": {
            x: -200,
            y: 310,
          },
          "RSR.P.N": {
            x: -200,
            y: 310,
          },
          "C.W": {
            x: -200,
            y: 350,
          },
          CW: {
            x: -200,
            y: 350,
          },
          "D.W": {
            x: -205,
            y: 380,
          },
          "F.W": {
            x: -205,
            y: 380,
          },
          "G.E": {
            x: -205,
            y: 380,
          },
          "F.E": {
            x: -205,
            y: 380,
          },
          "I.E": {
            x: -205,
            y: 380,
          },
          "RWRK.DRP": {
            x: -205,
            y: 380,
          },
          "D.GLD": {
            x: -205,
            y: 380,
          },
          L1: {
            x: -205,
            y: 320,
          },
          L3: {
            x: -205,
            y: 380,
          },
          "D.N": {
            x: -205,
            y: 380,
          },
          "E.D": {
            x: -205,
            y: 380,
          },
          NOR: {
            x: -205,
            y: 380,
          },
          KE: {
            x: -205,
            y: 380,
          },
          OTHERS: {
            x: -200,
            y: 310,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 151000,
          startY: 13000,
          endX: 500500,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 745,
          y: 216,
        },
        position: "UP",
      },
    },
    COL: {
      rackStorage: {
        bounds: {
          startX: 50000,
          startY: 34000,
          endX: 420000,
          endY: 176000,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {
          "SR.E.003.01": {
            x: -68,
            y: -28,
          },
          "SR.E.004.01": {
            x: -68,
            y: -28,
          },
          "SR.E.005.01": {
            x: -68,
            y: -28,
          },
          "SR.E.006.01": {
            x: -68,
            y: -28,
          },
          "SR.E.007.01": {
            x: -68,
            y: -28,
          },
          "SR.E.009.01": {
            x: -68,
            y: -28,
          },
          "SR.E.010.01": {
            x: -68,
            y: -28,
          },
          "SR.E.011.01": {
            x: -68,
            y: -28,
          },
          "SR.E.012.01": {
            x: -68,
            y: -28,
          },
          "SR.E.013.01": {
            x: -68,
            y: -28,
          },
          "SR.E.014.01": {
            x: -68,
            y: -28,
          },
          "SR.E.015.01": {
            x: -68,
            y: -28,
          },
          "SR.E.016.01": {
            x: -68,
            y: -28,
          },
          "SR.E.017.01": {
            x: -68,
            y: -28,
          },
          "SR.E.018.01": {
            x: -68,
            y: -28,
          },
          "SR.E.020.01": {
            x: -68,
            y: -28,
          },
          "SR.E.021.01": {
            x: -68,
            y: -28,
          },
          "SR.E.022.01": {
            x: -68,
            y: -28,
          },
          "SR.E.023.01": {
            x: -68,
            y: -28,
          },
          "SR.E.024.01": {
            x: -68,
            y: -28,
          },
          "SR.E.025.01": {
            x: -68,
            y: -28,
          },
          "SR.E.026.01": {
            x: -68,
            y: -28,
          },
          "SR.E.027.01": {
            x: -68,
            y: -28,
          },
          "SR.E.028.01": {
            x: -68,
            y: -28,
          },
          "SR.E.030.01": {
            x: -68,
            y: -28,
          },
          "SR.E.031.01": {
            x: -68,
            y: -28,
          },
          "SR.E.032.01": {
            x: -68,
            y: -28,
          },
          "SR.E.033.01": {
            x: -68,
            y: -28,
          },
          "SR.E.034.01": {
            x: -68,
            y: -28,
          },
          "SR.E.035.01": {
            x: -68,
            y: -28,
          },
          "SR.E.036.01": {
            x: -68,
            y: -28,
          },
          "SSR.020.01": {
            x: -65,
            y: -32,
          },
          "SSR.019.01": {
            x: -65,
            y: -32,
          },
          "SSR.018.01": {
            x: -65,
            y: -32,
          },
          "SSR.017.01": {
            x: -65,
            y: -32,
          },
          "SSR.016.01": {
            x: -65,
            y: -32,
          },
          "SSR.015.01": {
            x: -65,
            y: -32,
          },
          "SSR.014.01": {
            x: -65,
            y: -32,
          },
          "SSR.013.01": {
            x: -65,
            y: -32,
          },
          "SSR.012.01": {
            x: -65,
            y: -32,
          },
          "SSR.008.01": {
            x: -65,
            y: -32,
          },
          "SSR.007.01": {
            x: -65,
            y: -32,
          },
          "SSR.006.01": {
            x: -65,
            y: -32,
          },
          "SSR.005.01": {
            x: -65,
            y: -32,
          },
          "SSR.004.01": {
            x: -65,
            y: -32,
          },
          "SSR.003.01": {
            x: -65,
            y: -32,
          },
          "SSR.002.01": {
            x: -65,
            y: -32,
          },
          "SSR.001.01": {
            x: -65,
            y: -32,
          },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "5.2px",
            height: "4px",
          },
        },
        bounds: {
          startX: 50000,
          startY: 34000,
          endX: 425000,
          endY: 176000,
        },
        eachItemOffset: {
          "F.X.001": {
            x: -67,
            y: 0,
          },
          "F.X.002": {
            x: -67,
            y: 0,
          },
          "F.W.001": {
            x: -67,
            y: -35,
          },
          "F.W.012": {
            x: -69,
            y: -35,
          },
          "F.W.013": {
            x: -69,
            y: -35,
          },
          "F.W.014": {
            x: -70,
            y: -35,
          },
          "F.W.015": {
            x: -70,
            y: -35,
          },
          "F.W.016": {
            x: -70,
            y: -35,
          },
          "F.E.001": {
            x: -67,
            y: -20,
          },
          "F.E.002": {
            x: -68,
            y: -20,
          },
          "F.E.003": {
            x: -68,
            y: -20,
          },
          "F.E.004": {
            x: -68,
            y: -20,
          },
          "F.E.005": {
            x: -68,
            y: -20,
          },
          "F.E.006": {
            x: -68,
            y: -20,
          },
          "F.E.007": {
            x: -68,
            y: -20,
          },
          "F.E.008": {
            x: -69,
            y: -20,
          },
          "F.E.009": {
            x: -69,
            y: -20,
          },
          "F.E.010": {
            x: -69,
            y: -20,
          },
          "F.E.011": {
            x: -69,
            y: -20,
          },
          "F.E.012": {
            x: -69,
            y: -20,
          },
          "F.E.013": {
            x: -69,
            y: -20,
          },
          "F.E.014": {
            x: -69,
            y: -20,
          },
          "F.E.015": {
            x: -69,
            y: -20,
          },
          "F.E.016": {
            x: -70,
            y: -20,
          },
          "E.W.001": {
            x: -67,
            y: -34,
          },
          "E.W.002": {
            x: -68,
            y: -34,
          },
          "E.W.003": {
            x: -68,
            y: -34,
          },
          "E.W.004": {
            x: -68,
            y: -34,
          },
          "E.W.005": {
            x: -68,
            y: -34,
          },
          "E.W.006": {
            x: -68,
            y: -34,
          },
          "E.W.007": {
            x: -68,
            y: -34,
          },
          "E.W.008": {
            x: -69,
            y: -34,
          },
          "E.W.009": {
            x: -69,
            y: -34,
          },
          "E.W.010": {
            x: -69,
            y: -34,
          },
          "E.W.011": {
            x: -69,
            y: -34,
          },
          "E.W.012": {
            x: -69,
            y: -34,
          },
          "E.W.013": {
            x: -69,
            y: -34,
          },
          "E.W.014": {
            x: -70,
            y: -34,
          },
          "E.W.015": {
            x: -70,
            y: -34,
          },
          "E.W.016": {
            x: -70,
            y: -34,
          },
          "H.E.017": {
            x: -70,
            y: -15,
          },
          "H.E.018": {
            x: -70,
            y: -15,
          },
          "H.E.019": {
            x: -71,
            y: -15,
          },
          "D.E.001": {
            x: -67,
            y: -18,
          },
          "D.E.002": {
            x: -68,
            y: -18,
          },
          "D.E.003": {
            x: -68,
            y: -18,
          },
          "D.E.004": {
            x: -68,
            y: -18,
          },
          "D.E.005": {
            x: -68,
            y: -18,
          },
          "D.E.006": {
            x: -68,
            y: -18,
          },
          "D.E.007": {
            x: -68,
            y: -18,
          },
          "D.E.008": {
            x: -69,
            y: -18,
          },
          "D.E.009": {
            x: -69,
            y: -18,
          },
          "D.E.010": {
            x: -69,
            y: -18,
          },
          "D.E.011": {
            x: -69,
            y: -18,
          },
          "D.E.012": {
            x: -69,
            y: -18,
          },
          "D.E.013": {
            x: -69,
            y: -18,
          },
          "D.E.014": {
            x: -70,
            y: -18,
          },
          "D.E.015": {
            x: -70,
            y: -18,
          },
          "D.E.016": {
            x: -70,
            y: -18,
          },
          "J.E.017": {
            x: -70,
            y: -19,
          },
          "J.E.018": {
            x: -71,
            y: -19,
          },
          "J.E.019": {
            x: -71,
            y: -19,
          },
          "J.E.020": {
            x: -71,
            y: -19,
          },
          "J.E.021": {
            x: -72,
            y: -19,
          },
          "J.E.022": {
            x: -71,
            y: -19,
          },
          "J.E.023": {
            x: -72,
            y: -19,
          },
          "J.E.024": {
            x: -72,
            y: -19,
          },
          "J.E.025": {
            x: -72,
            y: -19,
          },
          "J.E.026": {
            x: -72,
            y: -19,
          },
          "J.E.027": {
            x: -72,
            y: -19,
          },
          "J.E.028": {
            x: -72,
            y: -19,
          },
          "J.E.029": {
            x: -73,
            y: -19,
          },
          "J.E.030": {
            x: -73,
            y: -19,
          },
          "J.E.031": {
            x: -73,
            y: -19,
          },
          "J.E.032": {
            x: -73,
            y: -19,
          },
          "J.E.033": {
            x: -73,
            y: -19,
          },
          "J.E.034": {
            x: -73,
            y: -19,
          },
          //J.W
          "J.W.017": {
            x: -70,
            y: -34,
          },
          "J.W.018": {
            x: -71,
            y: -34,
          },
          "J.W.019": {
            x: -71,
            y: -34,
          },
          "J.W.020": {
            x: -70,
            y: -34,
          },
          "J.W.021": {
            x: -71,
            y: -34,
          },
          "J.W.022": {
            x: -71,
            y: -34,
          },
          "J.W.023": {
            x: -72,
            y: -33,
          },
          "J.W.024": {
            x: -71,
            y: -33,
          },
          "J.W.025": {
            x: -72,
            y: -33,
          },
          "J.W.026": {
            x: -72,
            y: -33,
          },
          "J.W.027": {
            x: -72,
            y: -33,
          },
          "J.W.028": {
            x: -73,
            y: -33,
          },
          "J.W.029": {
            x: -73,
            y: -33,
          },
          "J.W.030": {
            x: -73,
            y: -33,
          },
          "J.W.031": {
            x: -73,
            y: -33,
          },
          "J.W.032": {
            x: -73,
            y: -33,
          },
          "J.W.033": {
            x: -73,
            y: -33,
          },
          "J.W.034": {
            x: -73,
            y: -33,
          },
          //E.E
          "E.E.001": {
            x: -67,
            y: -19,
          },
          "E.E.002": {
            x: -68,
            y: -19,
          },
          "E.E.003": {
            x: -68,
            y: -19,
          },
          "E.E.004": {
            x: -68,
            y: -19,
          },
          "E.E.005": {
            x: -68,
            y: -19,
          },
          "E.E.006": {
            x: -68,
            y: -19,
          },
          "E.E.007": {
            x: -68,
            y: -19,
          },
          "E.E.008": {
            x: -69,
            y: -19,
          },
          "E.E.009": {
            x: -69,
            y: -19,
          },
          "E.E.010": {
            x: -69,
            y: -19,
          },
          "E.E.011": {
            x: -69,
            y: -19,
          },
          "E.E.012": {
            x: -69,
            y: -19,
          },
          "E.E.013": {
            x: -69,
            y: -19,
          },
          "E.E.014": {
            x: -70,
            y: -19,
          },
          "E.E.015": {
            x: -70,
            y: -19,
          },
          "E.E.016": {
            x: -70,
            y: -19,
          },
          "D.W.001": {
            x: -67,
            y: -32,
          },
          "D.W.002": {
            x: -68,
            y: -32,
          },
          "D.W.003": {
            x: -68,
            y: -32,
          },
          "D.W.004": {
            x: -68,
            y: -32,
          },
          "D.W.005": {
            x: -68,
            y: -32,
          },
          "D.W.006": {
            x: -68,
            y: -32,
          },
          "D.W.007": {
            x: -68,
            y: -32,
          },
          "D.W.008": {
            x: -69,
            y: -32,
          },
          "D.W.009": {
            x: -69,
            y: -32,
          },
          "D.W.010": {
            x: -69,
            y: -32,
          },
          "D.W.011": {
            x: -69,
            y: -32,
          },
          "D.W.012": {
            x: -69,
            y: -32,
          },
          "D.W.013": {
            x: -69,
            y: -32,
          },
          "D.W.014": {
            x: -70,
            y: -32,
          },
          "D.W.015": {
            x: -70,
            y: -32,
          },
          "D.W.016": {
            x: -70,
            y: -32,
          },
          "C.W.001": {
            x: -67,
            y: -29,
          },
          "C.W.002": {
            x: -68,
            y: -29,
          },
          "C.W.003": {
            x: -68,
            y: -29,
          },
          "C.W.004": {
            x: -68,
            y: -29,
          },
          "C.W.005": {
            x: -68,
            y: -29,
          },
          "C.W.006": {
            x: -69,
            y: -29,
          },
          "C.W.007": {
            x: -68,
            y: -29,
          },
          "C.W.008": {
            x: -68,
            y: -29,
          },
          "C.W.009": {
            x: -68,
            y: -29,
          },
          "C.W.010": {
            x: -68,
            y: -29,
          },
          "C.W.011": {
            x: -68,
            y: -29,
          },
          "C.W.012": {
            x: -69,
            y: -29,
          },
          "C.W.013": {
            x: -69,
            y: -29,
          },
          "C.W.014": {
            x: -70,
            y: -29,
          },
          "C.W.015": {
            x: -70,
            y: -29,
          },
          "C.W.016": {
            x: -70,
            y: -29,
          },
          "K.E.017": {
            x: -70,
            y: -22,
          },
          "K.E.018": {
            x: -71,
            y: -22,
          },
          "K.E.019": {
            x: -71,
            y: -22,
          },
          "K.E.020": {
            x: -71,
            y: -22,
          },
          "K.E.021": {
            x: -71,
            y: -22,
          },
          "K.E.022": {
            x: -71,
            y: -22,
          },
          "K.E.023": {
            x: -72,
            y: -22,
          },
          "K.E.024": {
            x: -72,
            y: -22,
          },
          "K.E.025": {
            x: -72,
            y: -22,
          },
          "K.E.026": {
            x: -72,
            y: -22,
          },
          "K.E.027": {
            x: -72,
            y: -22,
          },
          "K.E.028": {
            x: -72,
            y: -22,
          },
          "K.E.029": {
            x: -73,
            y: -22,
          },
          "K.E.030": {
            x: -73,
            y: -22,
          },
          "K.E.031": {
            x: -73,
            y: -22,
          },
          "K.E.032": {
            x: -73,
            y: -22,
          },
          "K.E.033": {
            x: -73,
            y: -22,
          },
          "K.E.034": {
            x: -73,
            y: -22,
          },
          "I.E.017": {
            x: -70,
            y: -19,
          },
          "I.E.018": {
            x: -71,
            y: -19,
          },
          "I.E.019": {
            x: -71,
            y: -19,
          },
          "I.E.020": {
            x: -71,
            y: -19,
          },
          "I.E.021": {
            x: -72,
            y: -19,
          },
          "I.E.022": {
            x: -71,
            y: -19,
          },
          "I.E.023": {
            x: -72,
            y: -19,
          },
          "I.E.024": {
            x: -72,
            y: -19,
          },
          "I.E.025": {
            x: -72,
            y: -19,
          },
          "I.E.026": {
            x: -72,
            y: -19,
          },
          "I.E.027": {
            x: -72,
            y: -19,
          },
          "I.E.028": {
            x: -72,
            y: -19,
          },
          "I.E.029": {
            x: -73,
            y: -19,
          },
          "I.E.030": {
            x: -73,
            y: -19,
          },
          "I.E.031": {
            x: -73,
            y: -19,
          },
          "I.E.032": {
            x: -73,
            y: -19,
          },
          "I.E.033": {
            x: -73,
            y: -19,
          },
          "I.E.034": {
            x: -73,
            y: -19,
          },
          "I.W.017": {
            x: -70,
            y: -33,
          },
          "I.W.018": {
            x: -71,
            y: -33,
          },
          "I.W.019": {
            x: -71,
            y: -33,
          },
          "I.W.020": {
            x: -71,
            y: -33,
          },
          "I.W.021": {
            x: -72,
            y: -33,
          },
          "I.W.022": {
            x: -71,
            y: -33,
          },
          "I.W.023": {
            x: -72,
            y: -33,
          },
          "I.W.024": {
            x: -72,
            y: -33,
          },
          "I.W.025": {
            x: -72,
            y: -33,
          },
          "I.W.026": {
            x: -72,
            y: -33,
          },
          "I.W.027": {
            x: -72,
            y: -33,
          },
          "I.W.028": {
            x: -72,
            y: -33,
          },
          "I.W.029": {
            x: -73,
            y: -33,
          },
          "I.W.030": {
            x: -73,
            y: -33,
          },
          "I.W.031": {
            x: -73,
            y: -33,
          },
          "I.W.032": {
            x: -73,
            y: -33,
          },
          "I.W.033": {
            x: -73,
            y: -33,
          },
          "I.W.034": {
            x: -73,
            y: -33,
          },
          "H.W.017": {
            x: -70,
            y: -33,
          },
          "H.W.018": {
            x: -71,
            y: -33,
          },
          "H.W.019": {
            x: -71,
            y: -33,
          },
          "H.W.020": {
            x: -71,
            y: -33,
          },
          "H.W.021": {
            x: -72,
            y: -33,
          },
          "H.W.022": {
            x: -71,
            y: -33,
          },
          "H.W.023": {
            x: -72,
            y: -33,
          },
          "H.W.024": {
            x: -72,
            y: -33,
          },
          "H.W.025": {
            x: -72,
            y: -33,
          },
          "H.W.026": {
            x: -72,
            y: -33,
          },
          "H.W.027": {
            x: -72,
            y: -33,
          },
          "H.W.028": {
            x: -72,
            y: -33,
          },
          "H.W.029": {
            x: -73,
            y: -33,
          },
          "H.W.030": {
            x: -73,
            y: -33,
          },
          "H.W.031": {
            x: -73,
            y: -33,
          },
          "H.W.032": {
            x: -73,
            y: -33,
          },
          "H.W.033": {
            x: -73,
            y: -33,
          },
          "H.W.034": {
            x: -73,
            y: -33,
          },
          "C.E.007": {
            x: -68,
            y: -8,
          },
          "C.E.008": {
            x: -69,
            y: -8,
          },
          "C.E.009": {
            x: -69,
            y: -8,
          },
          "C.E.010": {
            x: -69,
            y: -8,
          },
          "C.E.011": {
            x: -69,
            y: -8,
          },
          "C.E.012": {
            x: -69,
            y: -8,
          },
          "P.005": {
            x: -80,
            y: -40,
          },
          "P.004": {
            x: -80,
            y: -40,
          },
          "P.003": {
            x: -80,
            y: -40,
          },
          "P.002": {
            x: -80,
            y: -41,
          },
          "P.001": {
            x: -80,
            y: -40,
          },
          "Z.EMP.042": {
            x: -66,
            y: -44,
          },
          "Z.EMP.041": {
            x: -66,
            y: -44,
          },
          "Z.EMP.040": {
            x: -66,
            y: -44,
          },
          "Z.EMP.039": {
            x: -66,
            y: -44,
          },
          "Z.EMP.038": {
            x: -66,
            y: -44,
          },
          "Z.EMP.037": {
            x: -66,
            y: -44,
          },
          "Z.EMP.036": {
            x: -66,
            y: -44,
          },
          "Z.GLD.035": {
            x: -66,
            y: -42,
          },
          "Z.GLD.034": {
            x: -66,
            y: -42,
          },
          "Z.GLD.033": {
            x: -66,
            y: -42,
          },
          "Z.GLD.032": {
            x: -66,
            y: -42,
          },
          "Z.GLD.031": {
            x: -66,
            y: -42,
          },
          "Z.GLD.030": {
            x: -66,
            y: -42,
          },
          "Z.GLD.029": {
            x: -66,
            y: -42,
          },
          "Z.GLD.028": {
            x: -66,
            y: -42,
          },
          "Z.GLD.027": {
            x: -66,
            y: -42,
          },
          "Z.GLD.026": {
            x: -66,
            y: -42,
          },
          "Z.GLD.025": {
            x: -66,
            y: -42,
          },
          "Z.GLD.024": {
            x: -66,
            y: -42,
          },
          "Z.GLD.023": {
            x: -66,
            y: -42,
          },
          "Z.GLD.022": {
            x: -66,
            y: -42,
          },
          "Z.GLD.021": {
            x: -66,
            y: -42,
          },
          "Z.GLD.020": {
            x: -66,
            y: -42,
          },

          "Z.S.019": {
            x: -66,
            y: -44,
          },
          "Z.S.018": {
            x: -66,
            y: -44,
          },
          "Z.S.017": {
            x: -66,
            y: -44,
          },
          "Z.S.016": {
            x: -66,
            y: -44,
          },
          "Z.S.015": {
            x: -66,
            y: -44,
          },
          "Z.S.014": {
            x: -66,
            y: -44,
          },
          "Z.S.013": {
            x: -66,
            y: -44,
          },
          "Z.S.012": {
            x: -66,
            y: -44,
          },
          "Z.S.011": {
            x: -66,
            y: -44,
          },
          "Z.S.010": {
            x: -66,
            y: -44,
          },
          "Z.S.009": {
            x: -66,
            y: -44,
          },
          "Z.S.008": {
            x: -66,
            y: -44,
          },
          "Z.S.007": {
            x: -66,
            y: -44,
          },
          "Z.S.006": {
            x: -66,
            y: -44,
          },
          "Z.S.005": {
            x: -66,
            y: -44,
          },
          "Z.S.004": {
            x: -66,
            y: -44,
          },
          "Z.S.003": {
            x: -66,
            y: -44,
          },
          "Z.S.002": {
            x: -66,
            y: -44,
          },
          "Z.S.001": {
            x: -66,
            y: -44,
          },
          "K.W.017": {
            x: -70,
            y: -34,
          },
          "K.W.018": {
            x: -71,
            y: -34,
          },
          "K.W.019": {
            x: -71,
            y: -34,
          },
          "K.W.020": {
            x: -71,
            y: -34,
          },
          "K.W.021": {
            x: -71,
            y: -34,
          },
          "K.W.022": {
            x: -71,
            y: -34,
          },
          "K.W.023": {
            x: -70,
            y: -34,
          },
          "K.W.024": {
            x: -70,
            y: -34,
          },
          "K.W.025": {
            x: -70,
            y: -34,
          },
          "K.W.026": {
            x: -71,
            y: -34,
          },
          "K.W.027": {
            x: -71,
            y: -34,
          },
          "K.W.028": {
            x: -72,
            y: -34,
          },
          "K.W.029": {
            x: -73,
            y: -33,
          },
          "K.W.030": {
            x: -73,
            y: -39,
          },
          "K.W.031": {
            x: -73,
            y: -39,
          },
          "K.W.032": {
            x: -73,
            y: -39,
          },
          "K.W.033": {
            x: -73,
            y: -39,
          },
        },
        eastLocationOffset: {
          x: -68,
          y: -20,
        },
        westLocationOffset: {
          x: -68,
          y: -35,
        },
        groupOffset: {
          x: 68,
          y: 20,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 780,
          y: 165,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -450,
          y: -140,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: -430,
          },
          L2: {
            x: 0,
            y: -330,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -167,
            y: 965,
          },
          destination: {
            x: -230,
            y: 960,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -167,
            y: 950,
          },
          HNDOFF: {
            x: -167,
            y: 950,
          },
          "A.W": {
            x: -167,
            y: 950,
          },
          "H.W": {
            x: -167,
            y: 950,
          },
          "C.E": {
            x: -167,
            y: 950,
          },
          "J.W": {
            x: -167,
            y: 950,
          },
          "G.W": {
            x: -167,
            y: 950,
          },
          SSR: {
            x: -167,
            y: 950,
          },
          "E.W": {
            x: -167,
            y: 950,
          },
          STR: {
            x: -167,
            y: 950,
          },
          SOR: {
            x: -167,
            y: 950,
          },
          "I.W": {
            x: -167,
            y: 950,
          },
          BATT: {
            x: -167,
            y: 950,
          },
          "A.E": {
            x: -167,
            y: 950,
          },
          "RDR.K": {
            x: -167,
            y: 950,
          },
          "B.E": {
            x: -167,
            y: 950,
          },
          NSR: {
            x: -167,
            y: 950,
          },
          "D.E": {
            x: -167,
            y: 950,
          },
          "C.W.GLD": {
            x: -167,
            y: 950,
          },
          "SR.B.E": {
            x: -167,
            y: 950,
          },
          "B.W": {
            x: -167,
            y: 950,
          },
          "J.E": {
            x: -167,
            y: 950,
          },
          "H.E": {
            x: -167,
            y: 950,
          },
          "RSR.P.N": {
            x: -167,
            y: 950,
          },
          "C.W": {
            x: -167,
            y: 955,
          },
          CW: {
            x: -167,
            y: 955,
          },
          "D.W": {
            x: -167,
            y: 950,
          },
          "F.W": {
            x: -167,
            y: 950,
          },
          "G.E": {
            x: -167,
            y: 950,
          },
          "F.E": {
            x: -167,
            y: 950,
          },
          "I.E": {
            x: -167,
            y: 950,
          },
          "RWRK.DRP": {
            x: -167,
            y: 950,
          },
          "D.GLD": {
            x: -167,
            y: 950,
          },
          L1: {
            x: -167,
            y: 950,
          },
          L3: {
            x: -167,
            y: 950,
          },
          "D.N": {
            x: -167,
            y: 950,
          },
          "E.D": {
            x: -167,
            y: 950,
          },
          NOR: {
            x: -167,
            y: 950,
          },
          KE: {
            x: -167,
            y: 960,
          },
          OTHERS: {
            x: -167,
            y: 947,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 69000,
          startY: 13000,
          endX: 445000,
          endY: 169000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 40,
          y: 125,
        },
        position: "UP",
      },
    },
    RCH: {
      rackStorage: {
        bounds: {
          startX: 0,
          startY: -6000,
          endX: 435000,
          endY: 131000,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {
          "SR.W.001.01": {
            x: -68,
            y: -39,
          },
          "SR.W.002.01": {
            x: -68,
            y: -39,
          },
          "SR.W.003.01": {
            x: -68,
            y: -39,
          },
          "SR.W.004.01": {
            x: -70,
            y: -39,
          },
          "SR.W.005.01": {
            x: -68,
            y: -39,
          },
          "SR.W.006.01": {
            x: -68,
            y: -39,
          },
          "SR.W.007.01": {
            x: -68,
            y: -39,
          },
          "SR.W.008.01": {
            x: -70,
            y: -39,
          },
          "SR.W.009.01": {
            x: -68,
            y: -39,
          },
          "SR.W.010.01": {
            x: -68,
            y: -39,
          },
          "SR.W.011.01": {
            x: -68,
            y: -39,
          },
          "SR.W.012.01": {
            x: -70,
            y: -39,
          },
          "SR.W.013.01": {
            x: -68,
            y: -39,
          },
          "SR.W.014.01": {
            x: -68,
            y: -39,
          },
          "SR.W.015.01": {
            x: -68,
            y: -39,
          },
          "SR.W.016.01": {
            x: -68,
            y: -39,
          },
          "SR.W.017.01": {
            x: -68,
            y: -39,
          },
          "SR.W.018.01": {
            x: -70,
            y: -39,
          },
          "SR.W.019.01": {
            x: -68,
            y: -39,
          },
          "SR.W.020.01": {
            x: -68,
            y: -39,
          },
          "SR.W.021.01": {
            x: -68,
            y: -39,
          },
          "SR.W.022.01": {
            x: -70,
            y: -39,
          },
          "SR.W.023.01": {
            x: -68,
            y: -39,
          },
          "SR.W.024.01": {
            x: -68,
            y: -39,
          },
          "SR.W.025.01": {
            x: -68,
            y: -39,
          },
          "SR.W.026.01": {
            x: -70,
            y: -39,
          },
          "SR.W.027.01": {
            x: -68,
            y: -39,
          },
          "SR.W.028.01": {
            x: -68,
            y: -39,
          },
          "SR.W.029.01": {
            x: -68,
            y: -39,
          },
          "SR.W.030.01": {
            x: -70,
            y: -39,
          },
          "SR.W.031.01": {
            x: -68,
            y: -39,
          },
          "SR.W.032.01": {
            x: -68,
            y: -39,
          },
          "SR.E.001.01": {
            x: -68,
            y: -28,
          },
          "SR.E.002.01": {
            x: -68,
            y: -28,
          },
          "SR.E.003.01": {
            x: -68,
            y: -28,
          },
          "SR.E.004.01": {
            x: -70,
            y: -28,
          },
          "SR.E.005.01": {
            x: -68,
            y: -28,
          },
          "SR.E.006.01": {
            x: -68,
            y: -28,
          },
          "SR.E.007.01": {
            x: -68,
            y: -28,
          },
          "SR.E.008.01": {
            x: -70,
            y: -28,
          },
          "SR.E.009.01": {
            x: -68,
            y: -28,
          },
          "SR.E.010.01": {
            x: -68,
            y: -28,
          },
          "SR.E.011.01": {
            x: -68,
            y: -28,
          },
          "SR.E.012.01": {
            x: -68,
            y: -28,
          },
          "SR.E.013.01": {
            x: -68,
            y: -28,
          },
          "SR.E.014.01": {
            x: -70,
            y: -28,
          },
          "SR.E.015.01": {
            x: -68,
            y: -28,
          },
          "SR.E.016.01": {
            x: -68,
            y: -28,
          },
          "SR.E.017.01": {
            x: -68,
            y: -28,
          },
          "SR.E.018.01": {
            x: -70,
            y: -28,
          },
          "SR.E.019.01": {
            x: -68,
            y: -28,
          },
          "SR.E.020.01": {
            x: -68,
            y: -28,
          },
          "SR.E.021.01": {
            x: -68,
            y: -28,
          },
          "SR.E.022.01": {
            x: -70,
            y: -28,
          },
          "SR.E.023.01": {
            x: -68,
            y: -28,
          },
          "SR.E.024.01": {
            x: -68,
            y: -28,
          },
          "SR.E.025.01": {
            x: -68,
            y: -28,
          },
          "SR.E.026.01": {
            x: -70,
            y: -28,
          },
          "SR.E.027.01": {
            x: -68,
            y: -28,
          },
          "SR.E.028.01": {
            x: -68,
            y: -28,
          },
          "SR.E.029.01": {
            x: -68,
            y: -28,
          },
          "SR.E.030.01": {
            x: -70,
            y: -28,
          },
          "SR.E.031.01": {
            x: -68,
            y: -28,
          },
          "SR.E.032.01": {
            x: -68,
            y: -28,
          },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "4.7px",
            height: "3.7px",
          },
        },
        bounds: {
          startX: 0,
          startY: -6000,
          endX: 435000,
          endY: 130000,
        },
        eachItemOffset: {
          "A.W.008": { x: -78, y: -50 },
          "A.W.009": { x: -70, y: -35 },
          "A.W.010": { x: -73, y: -35 },
          "A.W.011": { x: -69, y: -35 },
          "A.W.012": { x: -68, y: -35 },
          "A.W.013": { x: -68, y: -35 },
          "A.W.014": { x: -68, y: -35 },
          "A.W.015": { x: -68, y: -35 },
          "A.W.016": { x: -68, y: -35 },
          "A.W.017": { x: -69, y: -35 },
          "A.W.018": { x: -70, y: -35 },
          "A.W.019": { x: -70, y: -35 },
          "A.W.020": { x: -69, y: -35 },
          "A.W.021": { x: -70, y: -35 },
          "A.W.022": { x: -69, y: -35 },
          "A.W.023": { x: -68, y: -35 },
          "A.W.024": { x: -68, y: -35 },
          "A.W.025": { x: -67, y: -37 },
          "A.W.027": { x: -67, y: -37 },
          "A.W.028": { x: -68, y: -37 },
          "A.W.029": { x: -69, y: -37 },

          "G.W.035": { x: -66, y: -37 },
          "G.W.036": { x: -66, y: -37 },
          "G.W.037": { x: -66, y: -37 },
          "G.W.038": { x: -66, y: -37 },
          "G.W.039": { x: -67, y: -37 },
          "G.W.040": { x: -66, y: -37 },
          "G.W.041": { x: -67, y: -37 },
          "G.W.042": { x: -67, y: -37 },
          "G.W.043": { x: -67, y: -37 },
          "G.W.044": { x: -67, y: -37 },
          "G.W.045": { x: -67, y: -37 },
          "G.W.045": { x: -67, y: -37 },
          "G.W.046": { x: -67, y: -37 },

          "A.E.001": { x: -72, y: -107 },
          "A.E.002": { x: -71, y: -107 },
          "A.E.003": { x: -71, y: -107 },
          "A.E.004": { x: -71, y: -107 },
          "A.E.005": { x: -71, y: -107 },
          "A.E.006": { x: -71, y: -107 },
          "A.E.007": { x: -71, y: -107 },
          "A.E.008": { x: -71, y: -107 },
          "A.E.009": { x: -71, y: -107 },
          "A.E.010": { x: -70, y: -107 },

          "A.E.011": { x: -70, y: -107 },
          "A.E.012": { x: -70, y: -107 },
          "A.E.013": { x: -70, y: -107 },
          "A.E.014": { x: -70, y: -107 },
          "A.E.015": { x: -70, y: -107 },
          "A.E.016": { x: -70, y: -107 },
          "A.E.017": { x: -69, y: -107 },
          "A.E.018": { x: -70, y: -107 },
          "A.E.019": { x: -70, y: -107 },
          "A.E.020": { x: -69, y: -107 },

          "A.E.021": { x: -69, y: -107 },
          "A.E.022": { x: -69, y: -107 },
          "A.E.023": { x: -69, y: -107 },
          "A.E.024": { x: -69, y: -107 },
          "A.E.025": { x: -69, y: -107 },
          "A.E.026": { x: -69, y: -107 },
          "A.E.027": { x: -68, y: -107 },
          "A.E.028": { x: -68, y: -107 },
          "A.E.029": { x: -67, y: -107 },
          "A.E.030": { x: -67, y: -107 },

          "B.W.001": { x: -72, y: -18 },
          "B.W.002": { x: -71, y: -18 },
          "B.W.003": { x: -71, y: -18 },
          "B.W.004": { x: -71, y: -18 },
          "B.W.005": { x: -71, y: -18 },
          "B.W.006": { x: -71, y: -18 },
          "B.W.007": { x: -71, y: -18 },
          "B.W.008": { x: -71, y: -18 },
          "B.W.009": { x: -71, y: -18 },
          "B.W.010": { x: -70, y: -18 },

          "B.W.011": { x: -70, y: -18 },
          "B.W.012": { x: -70, y: -18 },
          "B.W.013": { x: -70, y: -18 },
          "B.W.014": { x: -70, y: -18 },
          "B.W.015": { x: -70, y: -18 },
          "B.W.016": { x: -70, y: -18 },
          "B.W.017": { x: -69, y: -18 },
          "B.W.018": { x: -70, y: -18 },
          "B.W.019": { x: -70, y: -18 },
          "B.W.020": { x: -69, y: -18 },

          "B.W.021": { x: -69, y: -18 },
          "B.W.022": { x: -69, y: -18 },
          "B.W.023": { x: -69, y: -18 },
          "B.W.024": { x: -69, y: -18 },
          "B.W.025": { x: -69, y: -18 },
          "B.W.026": { x: -69, y: -18 },
          "B.W.027": { x: -68, y: -18 },
          "B.W.028": { x: -68, y: -18 },
          "B.W.029": { x: -67, y: -18 },
          "B.W.030": { x: -67, y: -18 },
          "B.E.001": { x: -68, y: -90 },
          //CE
          "C.E.001": { x: -71, y: -72 },
          "C.E.002": { x: -71, y: -72 },
          "C.E.003": { x: -71, y: -72 },
          "C.E.004": { x: -71, y: -72 },
          "C.E.005": { x: -71, y: -72 },
          "C.E.006": { x: -71, y: -72 },
          "C.E.007": { x: -71, y: -72 },
          "C.E.008": { x: -71, y: -72 },
          "C.E.009": { x: -71, y: -72 },
          "C.E.010": { x: -70, y: -72 },
          "C.E.011": { x: -70, y: -72 },
          "C.E.012": { x: -70, y: -72 },
          "C.E.013": { x: -70, y: -72 },
          "C.E.014": { x: -70, y: -72 },
          "C.E.015": { x: -70, y: -72 },
          "C.E.016": { x: -70, y: -72 },
          "C.E.017": { x: -69, y: -72 },
          "C.E.018": { x: -69, y: -72 },
          "C.E.019": { x: -69, y: -72 },
          "C.E.020": { x: -69, y: -72 },
          "C.E.021": { x: -69, y: -72 },
          "C.E.022": { x: -69, y: -72 },
          "C.E.023": { x: -69, y: -72 },
          "C.E.024": { x: -69, y: -72 },
          "C.E.025": { x: -69, y: -72 },
          "C.E.026": { x: -69, y: -72 },
          //"CW"
          "C.W.001": { x: -71, y: 0 },
          "C.W.002": { x: -71, y: 0 },
          "C.W.003": { x: -71, y: 0 },
          "C.W.004": { x: -71, y: 0 },
          "C.W.005": { x: -70, y: 0 },
          "C.W.006": { x: -71, y: 0 },
          "C.W.007": { x: -71, y: 0 },
          "C.W.008": { x: -71, y: 0 },
          "C.W.009": { x: -71, y: 0 },
          "C.W.010": { x: -70, y: 0 },
          "C.W.011": { x: -70, y: 0 },
          "C.W.012": { x: -70, y: 0 },
          "C.W.013": { x: -70, y: 0 },
          "C.W.014": { x: -70, y: 0 },
          "C.W.015": { x: -70, y: 0 },
          "C.W.016": { x: -70, y: 0 },
          "C.W.017": { x: -68, y: 0 },
          "C.W.018": { x: -69, y: 0 },
          "C.W.019": { x: -69, y: 0 },
          "C.W.020": { x: -68, y: 0 },
          "C.W.021": { x: -68, y: 0 },
          "C.W.022": { x: -68, y: 0 },
          "C.W.023": { x: -68, y: 0 },
          "C.W.024": { x: -68, y: 0 },
          "C.W.025": { x: -68, y: 0 },
          "C.W.026": { x: -68, y: 0 },
          "C.W.027": { x: -68, y: 0 },
          "C.W.028": { x: -68, y: 20 },
          //B.E
          "B.E.001": { x: -71, y: -90 },
          "B.E.002": { x: -71, y: -90 },
          "B.E.003": { x: -71, y: -90 },
          "B.E.004": { x: -71, y: -90 },
          "B.E.005": { x: -70, y: -90 },
          "B.E.006": { x: -71, y: -90 },
          "B.E.007": { x: -71, y: -90 },
          "B.E.008": { x: -71, y: -90 },
          "B.E.009": { x: -71, y: -90 },
          "B.E.010": { x: -70, y: -90 },
          "B.E.011": { x: -70, y: -90 },
          "B.E.012": { x: -70, y: -90 },
          "B.E.013": { x: -70, y: -90 },
          "B.E.014": { x: -70, y: -90 },
          "B.E.015": { x: -70, y: -90 },
          "B.E.016": { x: -70, y: -90 },

          "B.E.017": { x: -68, y: -90 },
          "B.E.018": { x: -69, y: -90 },
          "B.E.019": { x: -69, y: -90 },
          "B.E.020": { x: -68, y: -90 },
          "B.E.021": { x: -68, y: -90 },
          "B.E.022": { x: -68, y: -90 },
          "B.E.023": { x: -68, y: -90 },
          "B.E.024": { x: -68, y: -90 },
          "B.E.025": { x: -68, y: -90 },
          "B.E.026": { x: -68, y: -90 },
          "B.E.027": { x: -68, y: -90 },
          "B.E.028": { x: -68, y: -90 },
          "B.E.029": { x: -67, y: -157 },
          "B.E.030": { x: -67, y: -123 },
          //DW
          "D.W.001": { x: -71, y: 18 },
          "D.W.002": { x: -71, y: 18 },
          "D.W.003": { x: -71, y: 18 },
          "D.W.004": { x: -71, y: 18 },
          "D.W.005": { x: -71, y: 18 },
          "D.W.006": { x: -71, y: 18 },
          "D.W.007": { x: -71, y: 18 },
          "D.W.008": { x: -71, y: 18 },
          "D.W.009": { x: -71, y: 18 },
          "D.W.010": { x: -70, y: 18 },
          "D.W.011": { x: -70, y: 18 },
          "D.W.012": { x: -70, y: 18 },
          "D.W.013": { x: -70, y: 18 },
          "D.W.014": { x: -70, y: 18 },
          "D.W.015": { x: -70, y: 18 },
          "D.W.016": { x: -70, y: 18 },
          "D.W.017": { x: -69, y: 18 },
          "D.W.018": { x: -69, y: 18 },
          "D.W.019": { x: -69, y: 18 },
          "D.W.020": { x: -69, y: 18 },
          "D.W.021": { x: -69, y: 18 },
          "D.W.022": { x: -69, y: 18 },
          "D.W.023": { x: -69, y: 18 },
          "D.W.024": { x: -69, y: 18 },
          "D.W.025": { x: -69, y: 18 },
          "D.W.026": { x: -69, y: 18 },

          "D.W.027": { x: -68, y: 18 },
          "D.W.028": { x: -68, y: 19 },
          "D.W.029": { x: -67, y: 22 },
          "D.W.030": { x: -67, y: 22 },
          //DE
          "D.E.001": { x: -71, y: -59 },
          "D.E.002": { x: -71, y: -59 },
          "D.E.003": { x: -71, y: -59 },
          "D.E.004": { x: -71, y: -59 },
          "D.E.005": { x: -71, y: -59 },
          "D.E.006": { x: -71, y: -59 },
          "D.E.007": { x: -71, y: -59 },
          "D.E.008": { x: -71, y: -59 },
          "D.E.009": { x: -71, y: -59 },
          "D.E.010": { x: -70, y: -59 },
          "D.E.011": { x: -70, y: -59 },
          "D.E.012": { x: -70, y: -59 },
          "D.E.013": { x: -70, y: -59 },
          "D.E.014": { x: -70, y: -59 },
          "D.E.015": { x: -70, y: -59 },
          "D.E.016": { x: -70, y: -59 },
          "D.E.017": { x: -69, y: -59 },
          "D.E.018": { x: -69, y: -59 },
          "D.E.019": { x: -69, y: -59 },
          "D.E.020": { x: -69, y: -59 },
          "D.E.021": { x: -69, y: -59 },
          "D.E.022": { x: -69, y: -59 },
          "D.E.023": { x: -69, y: -59 },
          "D.E.024": { x: -69, y: -59 },
          "D.E.025": { x: -69, y: -59 },
          "D.E.026": { x: -69, y: -59 },
          "D.E.027": { x: -68, y: -76 },
          "D.E.028": { x: -68, y: -67 },
          "D.E.029": { x: -68, y: -41 },
          "D.E.030": { x: -67, y: -24 },
          //EW
          "E.W.001": { x: -71, y: 36 },
          "E.W.002": { x: -71, y: 36 },
          "E.W.003": { x: -71, y: 36 },
          "E.W.004": { x: -71, y: 36 },
          "E.W.005": { x: -71, y: 36 },
          "E.W.006": { x: -71, y: 36 },
          "E.W.007": { x: -71, y: 36 },
          "E.W.008": { x: -71, y: 36 },
          "E.W.009": { x: -71, y: 36 },
          "E.W.010": { x: -70, y: 36 },
          "E.W.011": { x: -70, y: 36 },
          "E.W.012": { x: -70, y: 36 },
          "E.W.013": { x: -70, y: 36 },
          "E.W.014": { x: -70, y: 36 },
          "E.W.015": { x: -70, y: 36 },
          "E.W.016": { x: -70, y: 36 },
          "E.W.017": { x: -69, y: 36 },
          "E.W.018": { x: -69, y: 36 },
          "E.W.019": { x: -69, y: 36 },
          "E.W.020": { x: -69, y: 36 },
          "E.W.021": { x: -69, y: 36 },
          "E.W.022": { x: -69, y: 36 },
          "E.W.023": { x: -69, y: 36 },
          "E.W.024": { x: -69, y: 36 },
          "E.W.025": { x: -69, y: 36 },
          "E.W.026": { x: -69, y: 36 },
          //FW
          "F.W.001": { x: -71, y: 55 },
          "F.W.002": { x: -71, y: 55 },
          "F.W.003": { x: -71, y: 55 },
          "F.W.004": { x: -71, y: 55 },

          "F.W.005": { x: -70, y: 55 },
          "F.W.006": { x: -71, y: 55 },
          "F.W.007": { x: -71, y: 55 },
          "F.W.008": { x: -70, y: 55 },
          "F.W.009": { x: -70, y: 55 },
          "F.W.010": { x: -70, y: 55 },

          "F.W.011": { x: -70, y: 55 },
          "F.W.012": { x: -70, y: 55 },
          "F.W.013": { x: -70, y: 55 },
          "F.W.014": { x: -70, y: 55 },
          "F.W.015": { x: -70, y: 55 },
          "F.W.016": { x: -70, y: 55 },

          "F.W.017": { x: -69, y: 55 },
          "F.W.018": { x: -69, y: 55 },
          "F.W.019": { x: -69, y: 55 },
          "F.W.020": { x: -68, y: 55 },
          "F.W.021": { x: -69, y: 55 },
          "F.W.022": { x: -68, y: 55 },

          "F.W.023": { x: -69, y: 55 },
          "F.W.024": { x: -69, y: 55 },
          "F.W.025": { x: -69, y: 55 },
          "F.W.026": { x: -69, y: 55 },
          "F.W.027": { x: -68, y: 57 },
          "F.W.028": { x: -68, y: 57 },
          //EE
          "E.E.001": { x: -71, y: -45 },
          "E.E.002": { x: -71, y: -45 },
          "E.E.003": { x: -71, y: -45 },
          "E.E.004": { x: -71, y: -45 },

          "E.E.005": { x: -70, y: -45 },
          "E.E.006": { x: -71, y: -45 },
          "E.E.007": { x: -71, y: -45 },
          "E.E.008": { x: -70, y: -45 },
          "E.E.009": { x: -70, y: -45 },
          "E.E.010": { x: -70, y: -45 },

          "E.E.011": { x: -70, y: -45 },
          "E.E.012": { x: -70, y: -45 },
          "E.E.013": { x: -70, y: -45 },
          "E.E.014": { x: -70, y: -45 },
          "E.E.015": { x: -70, y: -45 },
          "E.E.016": { x: -70, y: -45 },

          "E.E.017": { x: -69, y: -45 },
          "E.E.018": { x: -69, y: -45 },
          "E.E.019": { x: -69, y: -45 },
          "E.E.020": { x: -68, y: -45 },
          "E.E.021": { x: -69, y: -45 },
          "E.E.022": { x: -68, y: -45 },

          "E.E.023": { x: -69, y: -45 },
          "E.E.024": { x: -69, y: -45 },
          "E.E.025": { x: -69, y: -45 },
          "E.E.026": { x: -69, y: -45 },
          //IE
          "I.E.044": { x: -68, y: -45 },
          "I.E.046": { x: -68, y: -35 },
          //FE
          "F.E.001": { x: -71, y: -25 },
          "F.E.002": { x: -71, y: -25 },
          "F.E.003": { x: -71, y: -25 },
          "F.E.004": { x: -71, y: -25 },

          "F.E.005": { x: -70, y: -25 },
          "F.E.006": { x: -70, y: -25 },
          "F.E.007": { x: -71, y: -25 },
          "F.E.008": { x: -71, y: -25 },
          "F.E.009": { x: -71, y: -25 },
          "F.E.010": { x: -70, y: -25 },

          "F.E.011": { x: -70, y: -25 },
          "F.E.012": { x: -69, y: -25 },
          "F.E.013": { x: -69, y: -25 },
          "F.E.014": { x: -70, y: -25 },
          "F.E.015": { x: -69, y: -25 },
          "F.E.016": { x: -69, y: -25 },
          "F.E.017": { x: -69, y: -30 },
          "F.E.018": { x: -69, y: -30 },
          "F.E.019": { x: -69, y: -30 },
          "F.E.020": { x: -68, y: -30 },
          "F.E.021": { x: -69, y: -30 },
          "F.E.022": { x: -68, y: -30 },
          "F.E.023": { x: -69, y: -30 },
          //GE
          "G.E.034": { x: -68, y: -133 },
          "G.E.035": { x: -67, y: -133 },
          "G.E.036": { x: -67, y: -133 },
          "G.E.037": { x: -67, y: -133 },
          "G.E.038": { x: -67, y: -133 },
          "G.E.039": { x: -67, y: -133 },
          "G.E.040": { x: -67, y: -133 },
          "G.E.041": { x: -67, y: -133 },
          "G.E.042": { x: -67, y: -133 },
          "G.E.043": { x: -67, y: -133 },
          "G.E.044": { x: -67, y: -133 },
          "G.E.045": { x: -67, y: -133 },
          "G.E.046": { x: -67, y: -120 },
          //HW
          "H.W.034": { x: -68, y: -15 },
          "H.W.035": { x: -67, y: -15 },
          "H.W.036": { x: -67, y: -15 },
          "H.W.037": { x: -67, y: -15 },
          "H.W.038": { x: -68, y: -15 },
          "H.W.039": { x: -67, y: -15 },
          "H.W.040": { x: -67, y: -15 },
          "H.W.041": { x: -66, y: -15 },
          "H.W.042": { x: -67, y: -15 },
          "H.W.043": { x: -67, y: -15 },
          "H.W.044": { x: -67, y: -15 },
          "H.W.045": { x: -67, y: -15 },
          "H.W.046": { x: -67, y: -15 },
          //HE
          "H.E.034": { x: -68, y: -67 },
          "H.E.035": { x: -67, y: -67 },
          "H.E.036": { x: -67, y: -67 },
          "H.E.037": { x: -67, y: -67 },
          "H.E.038": { x: -68, y: -67 },
          "H.E.039": { x: -68, y: -67 },
          "H.E.040": { x: -68, y: -67 },
          "H.E.041": { x: -66, y: -67 },
          "H.E.042": { x: -66, y: -67 },
          "H.E.043": { x: -67, y: -67 },
          "H.E.044": { x: -66, y: -67 },
          "H.E.045": { x: -67, y: -67 },
          "H.E.046": { x: -66, y: -55 },
          //IW
          "I.W.034": { x: -68, y: -5 },
          "I.W.035": { x: -67, y: -5 },
          "I.W.036": { x: -67, y: -5 },
          "I.W.037": { x: -67, y: -5 },
          "I.W.038": { x: -68, y: -5 },
          "I.W.039": { x: -68, y: -5 },
          "I.W.040": { x: -68, y: -5 },
          "I.W.041": { x: -66, y: -5 },
          "I.W.042": { x: -66, y: -5 },
          "I.W.043": { x: -67, y: -5 },
          "I.W.044": { x: -66, y: -5 },
          "I.W.045": { x: -67, y: -5 },
          "I.W.046": { x: -66, y: -5 },
          //IE
          "I.E.034": { x: -68, y: -35 },
          "I.E.035": { x: -68, y: -40 },
          "I.E.036": { x: -68, y: -40 },
          "I.E.037": { x: -68, y: -40 },
          "I.E.038": { x: -68, y: -40 },
          "I.E.039": { x: -68, y: -15 },
          "I.E.040": { x: -68, y: -15 },
          "I.E.041": { x: -67, y: -32 },
          "I.E.042": { x: -67, y: -32 },
          "I.E.043": { x: -67, y: -32 },
          "I.E.044": { x: -67, y: -45 },
          "I.E.045": { x: -67, y: -45 },
          "I.E.046": { x: -66, y: -32 },
        },
        eastLocationOffset: {
          x: -68,
          y: -20,
        },
        westLocationOffset: {
          x: -68,
          y: -37,
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 20,
          y: 193,
        },
      },
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -195,
          y: -6,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 70,
          },
          L2: {
            x: 0,
            y: 40,
          },
          L3: {
            x: 0,
            y: 0,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 30,
            y: 195,
          },
          destination: {
            x: -20,
            y: 190,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 28,
            y: 180,
          },
          HNDOFF: {
            x: 28,
            y: 180,
          },
          "G.W": {
            x: 28,
            y: 180,
          },
          "H.W": {
            x: 28,
            y: 180,
          },
          "C.E": {
            x: 28,
            y: 180,
          },
          "J.W": {
            x: 28,
            y: 180,
          },
          SSR: {
            x: 28,
            y: 180,
          },
          "E.W": {
            x: 28,
            y: 180,
          },
          STR: {
            x: 28,
            y: 180,
          },
          SOR: {
            x: 28,
            y: 180,
          },
          "I.W": {
            x: 28,
            y: 180,
          },
          BATT: {
            x: 28,
            y: 180,
          },
          "A.E": {
            x: 28,
            y: 180,
          },
          "RDR.K": {
            x: 28,
            y: 180,
          },
          "A.W": {
            x: 28,
            y: 180,
          },
          "B.E": {
            x: 28,
            y: 180,
          },
          NSR: {
            x: 28,
            y: 180,
          },
          "D.E": {
            x: 28,
            y: 180,
          },
          "C.W.GLD": {
            x: 28,
            y: 180,
          },
          "SR.B.E": {
            x: 28,
            y: 180,
          },
          "B.W": {
            x: 28,
            y: 180,
          },
          "J.E": {
            x: 28,
            y: 180,
          },
          "H.E": {
            x: 28,
            y: 180,
          },
          "RSR.P.N": {
            x: 28,
            y: 180,
          },
          "C.W": {
            x: 28,
            y: 180,
          },
          CW: {
            x: 28,
            y: 180,
          },
          "D.W": {
            x: 28,
            y: 180,
          },
          "F.W": {
            x: 28,
            y: 180,
          },
          "G.E": {
            x: 28,
            y: 180,
          },
          "F.E": {
            x: 28,
            y: 180,
          },
          "I.E": {
            x: 28,
            y: 180,
          },
          "RWRK.DRP": {
            x: 28,
            y: 180,
          },
          "D.GLD": {
            x: 28,
            y: 180,
          },
          L1: {
            x: 28,
            y: 180,
          },
          L3: {
            x: 28,
            y: 180,
          },
          "D.N": {
            x: 28,
            y: 180,
          },
          "E.E": {
            x: 28,
            y: 180,
          },
          NOR: {
            x: 28,
            y: 180,
          },
          "K.E": {
            x: 28,
            y: 180,
          },
          OTHERS: {
            x: 28,
            y: 180,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 98500,
          startY: 13000,
          endX: 500000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 316,
          y: -17,
        },
        position: "UP",
      },
    },
    MIS: {
      rackStorage: {
        bounds: {
          startX: -7500,
          startY: 18500,
          endX: 381500,
          endY: 196200,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {
          "SR.E.001.01": {
            x: -67,
            y: -29,
          },
          "SR.E.002.01": {
            x: -68,
            y: -29,
          },
          "SR.E.003.01": {
            x: -67,
            y: -29,
          },
          "SR.E.004.01": {
            x: -68,
            y: -29,
          },
          "SR.E.005.01": {
            x: -67,
            y: -29,
          },
          "SR.E.006.01": {
            x: -68,
            y: -29,
          },
          "SR.E.007.01": {
            x: -67,
            y: -29,
          },
          "SR.E.008.01": {
            x: -68,
            y: -29,
          },
          "SR.E.009.01": {
            x: -67,
            y: -29,
          },
          "SR.E.010.01": {
            x: -68,
            y: -29,
          },
          "SR.E.011.01": {
            x: -66,
            y: -29,
          },
          "SR.E.012.01": {
            x: -68,
            y: -29,
          },
          "SR.E.013.01": {
            x: -66,
            y: -29,
          },
          "SR.E.014.01": {
            x: -68,
            y: -29,
          },
          "SR.E.015.01": {
            x: -67,
            y: -29,
          },
          "SR.E.016.01": {
            x: -69,
            y: -29,
          },
          "SR.E.017.01": {
            x: -67,
            y: -29,
          },
          "SR.E.018.01": {
            x: -68,
            y: -29,
          },
          "SR.E.019.01": {
            x: -67,
            y: -28,
          },
          "SR.E.020.01": {
            x: -68,
            y: -28,
          },
          "SR.E.021.01": {
            x: -67,
            y: -28,
          },
          "SR.E.022.01": {
            x: -68,
            y: -28,
          },
          "SR.E.023.01": {
            x: -67,
            y: -28,
          },
          "SR.E.024.01": {
            x: -68,
            y: -28,
          },
          "SR.E.025.01": {
            x: -67,
            y: -28,
          },
          "SR.E.026.01": {
            x: -68,
            y: -28,
          },
          "SR.E.027.01": {
            x: -66,
            y: -28,
          },
          "SR.E.028.01": {
            x: -68,
            y: -28,
          },
          "SR.E.029.01": {
            x: -66,
            y: -28,
          },
          "SR.E.030.01": {
            x: -68,
            y: -28,
          },
          "SR.E.031.01": {
            x: -68,
            y: -28,
          },
          "SR.E.032.01": {
            x: -70,
            y: -28,
          },
          "SR.W.032.01": {
            x: -70,
            y: -35,
          },
          "SR.W.029.01": {
            x: -66,
            y: -35,
          },
          "SR.W.027.01": {
            x: -66,
            y: -35,
          },
          "SR.W.025.01": {
            x: -67,
            y: -35,
          },
          "SR.W.023.01": {
            x: -67,
            y: -35,
          },
          "SR.W.021.01": {
            x: -67,
            y: -35,
          },
          "SR.W.019.01": {
            x: -67,
            y: -35,
          },
          "SR.W.017.01": {
            x: -67,
            y: -35,
          },
          "SR.W.015.01": {
            x: -66,
            y: -35,
          },
          "SR.W.013.01": {
            x: -66,
            y: -35,
          },
          "SR.W.011.01": {
            x: -66,
            y: -35,
          },
          "SR.W.009.01": {
            x: -67,
            y: -35,
          },
          "SR.W.007.01": {
            x: -67,
            y: -35,
          },
          "SR.W.005.01": {
            x: -67,
            y: -35,
          },
          "SR.W.003.01": {
            x: -67,
            y: -35,
          },
          "SR.W.001.01": {
            x: -67,
            y: -35,
          },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "4.8px",
            height: "3.3px",
          },
        },
        bounds: {
          startX: -8500,
          startY: 18100,
          endX: 380000,
          endY: 197200,
        },
        eastLocationOffset: {
          x: -68,
          y: -26,
        },
        westLocationOffset: {
          x: -68,
          y: -40,
        },
        eachItemOffset: {
          "A.W.026": {
            x: -68,
            y: -27,
          },
          "A.W.027": {
            x: -68,
            y: -27,
          },
          "A.W.028": {
            x: -68,
            y: -27,
          },
          "A.W.029": {
            x: -68,
            y: -27,
          },
          "A.W.030": {
            x: -68,
            y: -27,
          },
          "A.W.031": {
            x: -68,
            y: -27,
          },
          "A.W.032": {
            x: -68,
            y: -27,
          },
          "A.W.033": {
            x: -68,
            y: -27,
          },
          "A.W.034": {
            x: -68,
            y: -27,
          },
          "G.E.065": {
            x: -68,
            y: -33,
          },
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [22, 50],
          rotation: true,
        },
        coordinate: {
          x: -5,
          y: 390,
        },
      },
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 15000,
          endX: 583000,
          endY: 191000,
        },
        groupOffset: {
          x: -320,
          y: -74,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: -60,
          },
          L2: {
            x: 0,
            y: -25,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 0,
            y: 96,
          },
          destination: {
            x: -100,
            y: 90,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -90,
            y: 120,
          },
          HNDOFF: {
            x: -90,
            y: 120,
          },
          "G.W": {
            x: -90,
            y: 120,
          },
          "H.W": {
            x: -90,
            y: 120,
          },
          "C.E": {
            x: -90,
            y: 120,
          },
          "J.W": {
            x: -90,
            y: 120,
          },
          SSR: {
            x: -90,
            y: 120,
          },
          "E.W": {
            x: -90,
            y: 120,
          },
          STR: {
            x: -90,
            y: 120,
          },
          SOR: {
            x: -90,
            y: 120,
          },
          "I.W": {
            x: -90,
            y: 120,
          },
          BATT: {
            x: -90,
            y: 120,
          },
          "A.E": {
            x: -90,
            y: 90,
          },
          "RDR.K": {
            x: -90,
            y: 120,
          },
          "A.W": {
            x: -90,
            y: 100,
          },
          "B.E": {
            x: -90,
            y: 120,
          },
          NSR: {
            x: -90,
            y: 120,
          },
          "D.E": {
            x: -90,
            y: 90,
          },
          "C.W.GLD": {
            x: -90,
            y: 120,
          },
          "SR.B.E": {
            x: -90,
            y: 120,
          },
          "B.W": {
            x: -90,
            y: 120,
          },
          "J.E": {
            x: -90,
            y: 120,
          },
          "H.E": {
            x: -90,
            y: 120,
          },
          "RSR.P.N": {
            x: -90,
            y: 120,
          },
          "C.W": {
            x: -100,
            y: 120,
          },
          CW: {
            x: -100,
            y: 120,
          },
          "D.W": {
            x: -90,
            y: 120,
          },
          "F.W": {
            x: -90,
            y: 120,
          },
          "G.E": {
            x: -90,
            y: 120,
          },
          "F.E": {
            x: -90,
            y: 120,
          },
          "I.E": {
            x: -90,
            y: 120,
          },
          "RWRK.DRP": {
            x: -90,
            y: 120,
          },
          "D.GLD": {
            x: -90,
            y: 120,
          },
          L1: {
            x: -90,
            y: 120,
          },
          L3: {
            x: -90,
            y: 120,
          },
          "D.N": {
            x: -90,
            y: 120,
          },
          "E.E": {
            x: -90,
            y: 120,
          },
          NOR: {
            x: -90,
            y: 120,
          },
          "K.E": {
            x: -90,
            y: 120,
          },
          OTHERS: {
            x: -90,
            y: 90,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 51500,
          startY: 15000,
          endX: 440000,
          endY: 191000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 180,
          y: 113,
        },
        position: "UP",
      },
    },
    MES: {
      rackStorage: {
        bounds: {
          startX: 29000,
          startY: 32000,
          endX: 406000,
          endY: 172000,
        },
        groupOffset: {
          x: -68,
          y: -35,
        },
        eachItemOffset: {
          "SR.N.01.01": {
            x: -62,
            y: -35,
          },
          "SR.N.02.01": {
            x: -62,
            y: -35,
          },
          "SR.N.03.01": {
            x: -62,
            y: -36,
          },
          "SR.N.04.01": {
            x: -62,
            y: -35,
          },
          "SR.N.05.01": {
            x: -62,
            y: -35,
          },
          "SR.N.06.01": {
            x: -62,
            y: -35,
          },
          "SR.N.07.01": {
            x: -62,
            y: -35,
          },
          "SR.N.08.01": {
            x: -62,
            y: -35,
          },
          "SR.N.09.01": {
            x: -62,
            y: -35,
          },
          "SR.N.10.01": {
            x: -62,
            y: -35,
          },
          "SR.N.11.01": {
            x: -62,
            y: -35,
          },
          "SR.N.12.01": {
            x: -62,
            y: -35,
          },
          "SR.N.13.01": {
            x: -62,
            y: -36,
          },
          "SR.N.14.01": {
            x: -62,
            y: -35,
          },
          "SR.N.15.01": {
            x: -62,
            y: -35,
          },
          "SR.N.16.01": {
            x: -62,
            y: -35,
          },
          "SR.N.17.01": {
            x: -62,
            y: -35,
          },
          "SR.N.18.01": {
            x: -62,
            y: -35,
          },
          "SR.N.19.01": {
            x: -62,
            y: -35,
          },
          "SR.N.20.01": {
            x: -62,
            y: -35,
          },
          "SR.N.21.01": {
            x: -62,
            y: -35,
          },
          "SR.N.22.01": {
            x: -62,
            y: -35,
          },
          "SR.N.23.01": {
            x: -62,
            y: -36,
          },
          "SR.N.24.01": {
            x: -62,
            y: -35,
          },
          "SR.N.25.01": {
            x: -62,
            y: -35,
          },
          "SR.N.26.01": {
            x: -62,
            y: -35,
          },
          "SR.N.27.01": {
            x: -62,
            y: -35,
          },
          "SR.N.28.01": {
            x: -62,
            y: -35,
          },
          "SR.N.29.01": {
            x: -62,
            y: -35,
          },
          "SR.N.30.01": {
            x: -62,
            y: -35,
          },
          "SR.N.31.01": {
            x: -62,
            y: -35,
          },
          "SR.N.32.01": {
            x: -62,
            y: -35,
          },
          "SR.N.33.01": {
            x: -62,
            y: -36,
          },
          "SR.N.34.01": {
            x: -62,
            y: -35,
          },
          "SR.N.35.01": {
            x: -62,
            y: -35,
          },
          "SR.N.36.01": {
            x: -62,
            y: -35,
          },
          "SR.N.37.01": {
            x: -62,
            y: -35,
          },
          "SR.N.38.01": {
            x: -62,
            y: -35,
          },
          "SR.N.39.01": {
            x: -62,
            y: -35,
          },
          "SR.N.40.01": {
            x: -62,
            y: -35,
          },
          "SR.N.41.01": {
            x: -62,
            y: -35,
          },
          "SR.N.42.01": {
            x: -62,
            y: -35,
          },
          "SR.S.03.01": {
            x: -68,
            y: -36,
          },
          "SR.S.13.01": {
            x: -68,
            y: -36,
          },
          "SR.S.23.01": {
            x: -68,
            y: -36,
          },
          "SR.S.33.01": {
            x: -68,
            y: -36,
          },
        },
      },
      blockStorage: {
        marker: {
          size: {
            width: "4.9px", //4.9
            height: "3.6px", //3.6
          },
        },
        bounds: {
          startX: 28000,
          startY: 29500,
          endX: 405000,
          endY: 174000,
        },
        eastLocationOffset: {
          x: -68,
          y: -23,
        },
        westLocationOffset: {
          x: -68,
          y: -39,
        },
        eachItemOffset: {
          "G.W.031": {
            x: -68,
            y: -41,
          },
          "G.W.032": {
            x: -68,
            y: -41,
          },
          "G.W.033": {
            x: -68,
            y: -41,
          },
          "G.W.034": {
            x: -68,
            y: -41,
          },
          "G.W.035": {
            x: -68,
            y: -41,
          },
          "G.W.036": {
            x: -68,
            y: -51,
          },
          "G.W.037": {
            x: -68,
            y: -51,
          },
          "G.W.038": {
            x: -68,
            y: -51,
          },
          "G.W.039": {
            x: -68,
            y: -51,
          },
          "G.W.040": {
            x: -68,
            y: -51,
          },
          "G.W.041": {
            x: -68,
            y: -51,
          },
          "G.W.042": {
            x: -68,
            y: -51,
          },
          "G.W.043": {
            x: -68,
            y: -51,
          },
          "G.W.044": {
            x: -68,
            y: -51,
          },
          "G.W.045": {
            x: -68,
            y: -51,
          },
          "G.W.046": {
            x: -68,
            y: -51,
          },
          "G.W.047": {
            x: -68,
            y: -51,
          },
          "G.W.048": {
            x: -68,
            y: -51,
          },
          "G.W.049": {
            x: -68,
            y: -36,
          },
          "I.E.049": {
            x: -68,
            y: -15,
          },
          //CE
          "C.E.004": {
            x: -68,
            y: -26,
          },
          "C.E.005": {
            x: -68,
            y: -26,
          },
          "C.E.006": {
            x: -68,
            y: -26,
          },
          "C.E.007": {
            x: -68,
            y: -26,
          },
          "C.E.008": {
            x: -68,
            y: -26,
          },
          "C.E.009": {
            x: -68,
            y: -26,
          },
          "C.E.010": {
            x: -68,
            y: -26,
          },
          "C.E.011": {
            x: -68,
            y: -26,
          },
          "C.E.012": {
            x: -68,
            y: -26,
          },
          "C.E.013": {
            x: -68,
            y: -26,
          },
          "C.E.014": {
            x: -68,
            y: -26,
          },
          "C.E.015": {
            x: -68,
            y: -26,
          },
          "C.E.016": {
            x: -68,
            y: -26,
          },
          "C.E.017": {
            x: -68,
            y: -26,
          },
          "C.E.018": {
            x: -68,
            y: -26,
          },
          "C.E.019": {
            x: -68,
            y: -26,
          },
          "C.E.020": {
            x: -68,
            y: -26,
          },
          "C.E.021": {
            x: -68,
            y: -26,
          },
          "C.E.022": {
            x: -68,
            y: -26,
          },
          "C.E.023": {
            x: -68,
            y: -26,
          },
          "C.E.024": {
            x: -68,
            y: -26,
          },
          "C.E.025": {
            x: -68,
            y: -26,
          },
          "C.E.026": {
            x: -68,
            y: -26,
          },
          "C.E.027": {
            x: -68,
            y: -26,
          },
          //CW
          "C.W.004": {
            x: -68,
            y: -41,
          },
          "C.W.005": {
            x: -68,
            y: -41,
          },
          "C.W.006": {
            x: -68,
            y: -41,
          },
          "C.W.007": {
            x: -68,
            y: -41,
          },
          "C.W.008": {
            x: -68,
            y: -41,
          },
          "C.W.009": {
            x: -68,
            y: -41,
          },
          "C.W.010": {
            x: -68,
            y: -41,
          },
          "C.W.011": {
            x: -68,
            y: -41,
          },
          "C.W.012": {
            x: -68,
            y: -41,
          },
          "C.W.013": {
            x: -68,
            y: -41,
          },
          "C.W.014": {
            x: -68,
            y: -41,
          },
          "C.W.015": {
            x: -68,
            y: -41,
          },
          "C.W.016": {
            x: -68,
            y: -41,
          },
          "C.W.017": {
            x: -68,
            y: -41,
          },
          "C.W.018": {
            x: -68,
            y: -41,
          },
          "C.W.019": {
            x: -68,
            y: -41,
          },
          "C.W.020": {
            x: -68,
            y: -41,
          },
          "C.W.021": {
            x: -68,
            y: -41,
          },
          "C.W.022": {
            x: -68,
            y: -41,
          },
          "C.W.023": {
            x: -68,
            y: -41,
          },
          "C.W.024": {
            x: -68,
            y: -41,
          },
          "C.W.025": {
            x: -68,
            y: -41,
          },
          "C.W.026": {
            x: -68,
            y: -41,
          },
          "C.W.027": {
            x: -68,
            y: -41,
          },
          //DW
          "D.W.004": {
            x: -68,
            y: -41,
          },
          "D.W.005": {
            x: -68,
            y: -41,
          },
          "D.W.006": {
            x: -68,
            y: -41,
          },
          "D.W.007": {
            x: -68,
            y: -41,
          },
          "D.W.008": {
            x: -68,
            y: -41,
          },
          "D.W.009": {
            x: -68,
            y: -41,
          },
          "D.W.010": {
            x: -68,
            y: -41,
          },
          "D.W.011": {
            x: -68,
            y: -41,
          },
          "D.W.012": {
            x: -68,
            y: -41,
          },
          "D.W.013": {
            x: -68,
            y: -41,
          },
          "D.W.014": {
            x: -68,
            y: -41,
          },
          "D.W.015": {
            x: -68,
            y: -41,
          },
          "D.W.016": {
            x: -68,
            y: -41,
          },
          "D.W.017": {
            x: -68,
            y: -41,
          },
          "D.W.018": {
            x: -68,
            y: -41,
          },
          "D.W.019": {
            x: -68,
            y: -41,
          },
          "D.W.020": {
            x: -68,
            y: -41,
          },
          "D.W.021": {
            x: -68,
            y: -41,
          },
          "D.W.022": {
            x: -68,
            y: -41,
          },
          "D.W.023": {
            x: -68,
            y: -41,
          },
          "D.W.024": {
            x: -68,
            y: -41,
          },
          "D.W.025": {
            x: -68,
            y: -41,
          },
          "D.W.026": {
            x: -68,
            y: -41,
          },
          "D.W.027": {
            x: -68,
            y: -41,
          },
          //EW
          "E.W.004": {
            x: -68,
            y: -41,
          },
          "E.W.005": {
            x: -68,
            y: -41,
          },
          "E.W.006": {
            x: -68,
            y: -41,
          },
          "E.W.007": {
            x: -68,
            y: -41,
          },
          "E.W.008": {
            x: -68,
            y: -41,
          },
          "E.W.009": {
            x: -68,
            y: -41,
          },
          "E.W.010": {
            x: -68,
            y: -41,
          },
          "E.W.011": {
            x: -68,
            y: -41,
          },
          "E.W.012": {
            x: -68,
            y: -41,
          },
          "E.W.013": {
            x: -68,
            y: -41,
          },
          "E.W.014": {
            x: -68,
            y: -41,
          },
          "E.W.015": {
            x: -68,
            y: -41,
          },
          "E.W.016": {
            x: -68,
            y: -41,
          },
          "E.W.017": {
            x: -68,
            y: -41,
          },
          "E.W.018": {
            x: -68,
            y: -41,
          },
          "E.W.019": {
            x: -68,
            y: -41,
          },
          "E.W.020": {
            x: -68,
            y: -41,
          },
          "E.W.021": {
            x: -68,
            y: -41,
          },
          "E.W.022": {
            x: -68,
            y: -41,
          },
          "E.W.023": {
            x: -68,
            y: -41,
          },
          "E.W.024": {
            x: -68,
            y: -41,
          },
          "E.W.025": {
            x: -68,
            y: -41,
          },
          //IW
          "I.W.032": {
            x: -68,
            y: -41,
          },
          "I.W.033": {
            x: -68,
            y: -41,
          },
          "I.W.034": {
            x: -68,
            y: -41,
          },
          "I.W.035": {
            x: -68,
            y: -41,
          },
          "I.W.036": {
            x: -68,
            y: -41,
          },
          "I.W.037": {
            x: -68,
            y: -41,
          },
          "I.W.038": {
            x: -68,
            y: -41,
          },
          "I.W.039": {
            x: -68,
            y: -41,
          },
          "I.W.040": {
            x: -68,
            y: -41,
          },
          "I.W.041": {
            x: -68,
            y: -41,
          },
          "I.W.042": {
            x: -68,
            y: -41,
          },
          "I.W.043": {
            x: -68,
            y: -41,
          },
          "I.W.044": {
            x: -68,
            y: -41,
          },
          "I.W.045": {
            x: -68,
            y: -41,
          },
          "I.W.046": {
            x: -68,
            y: -41,
          },
          "I.W.047": {
            x: -68,
            y: -41,
          },
          "I.W.048": {
            x: -68,
            y: -41,
          },
          //HW
          "H.W.031": {
            x: -68,
            y: -41,
          },
          "H.W.032": {
            x: -68,
            y: -41,
          },
          "H.W.033": {
            x: -68,
            y: -41,
          },
          "H.W.034": {
            x: -68,
            y: -41,
          },
          "H.W.035": {
            x: -68,
            y: -41,
          },
          "H.W.036": {
            x: -68,
            y: -41,
          },
          "H.W.037": {
            x: -68,
            y: -41,
          },
          "H.W.038": {
            x: -68,
            y: -41,
          },
          "H.W.039": {
            x: -68,
            y: -41,
          },
          "H.W.040": {
            x: -68,
            y: -41,
          },
          "H.W.041": {
            x: -68,
            y: -41,
          },
          "H.W.042": {
            x: -68,
            y: -41,
          },
          "H.W.043": {
            x: -68,
            y: -41,
          },
          "H.W.044": {
            x: -68,
            y: -41,
          },
          "H.W.045": {
            x: -68,
            y: -41,
          },
          "H.W.046": {
            x: -68,
            y: -41,
          },
          "H.W.047": {
            x: -68,
            y: -41,
          },
          "H.W.048": {
            x: -68,
            y: -41,
          },
          "H.W.049": {
            x: -68,
            y: -41,
          },
          //BE
          "B.E.004": {
            x: -68,
            y: -26,
          },
          "B.E.005": {
            x: -68,
            y: -26,
          },
          "B.E.006": {
            x: -68,
            y: -26,
          },
          "B.E.007": {
            x: -68,
            y: -26,
          },
          "B.E.008": {
            x: -68,
            y: -26,
          },
          "B.E.009": {
            x: -68,
            y: -26,
          },
          "B.E.010": {
            x: -68,
            y: -26,
          },
          "B.E.011": {
            x: -68,
            y: -26,
          },
          "B.E.012": {
            x: -68,
            y: -26,
          },
          "B.E.013": {
            x: -68,
            y: -26,
          },
          "B.E.014": {
            x: -68,
            y: -26,
          },
          "B.E.015": {
            x: -68,
            y: -26,
          },
          "B.E.016": {
            x: -68,
            y: -26,
          },
          "B.E.017": {
            x: -68,
            y: -26,
          },
          "B.E.018": {
            x: -68,
            y: -26,
          },
          "B.E.019": {
            x: -68,
            y: -26,
          },
          "B.E.020": {
            x: -68,
            y: -26,
          },
          "B.E.021": {
            x: -68,
            y: -26,
          },
          "B.E.022": {
            x: -68,
            y: -26,
          },
          "B.E.023": {
            x: -68,
            y: -26,
          },
          "B.E.024": {
            x: -68,
            y: -26,
          },
          "B.E.025": {
            x: -68,
            y: -26,
          },
          "B.E.026": {
            x: -68,
            y: -26,
          },
          "B.E.027": {
            x: -68,
            y: -26,
          },
          //DE
          "D.E.004": {
            x: -68,
            y: -26,
          },
          "D.E.005": {
            x: -68,
            y: -26,
          },
          "D.E.006": {
            x: -68,
            y: -26,
          },
          "D.E.007": {
            x: -68,
            y: -26,
          },
          "D.E.008": {
            x: -68,
            y: -26,
          },
          "D.E.009": {
            x: -68,
            y: -26,
          },
          "D.E.010": {
            x: -68,
            y: -26,
          },
          "D.E.011": {
            x: -68,
            y: -26,
          },
          "D.E.012": {
            x: -68,
            y: -26,
          },
          "D.E.013": {
            x: -68,
            y: -26,
          },
          "D.E.014": {
            x: -68,
            y: -26,
          },
          "D.E.015": {
            x: -68,
            y: -26,
          },
          "D.E.016": {
            x: -68,
            y: -26,
          },
          "D.E.017": {
            x: -68,
            y: -26,
          },
          "D.E.018": {
            x: -68,
            y: -26,
          },
          "D.E.019": {
            x: -68,
            y: -26,
          },
          "D.E.020": {
            x: -68,
            y: -26,
          },
          "D.E.021": {
            x: -68,
            y: -26,
          },
          "D.E.022": {
            x: -68,
            y: -26,
          },
          "D.E.023": {
            x: -68,
            y: -26,
          },
          "D.E.024": {
            x: -68,
            y: -26,
          },
          "D.E.025": {
            x: -68,
            y: -26,
          },
          "D.E.026": {
            x: -68,
            y: -26,
          },
          "D.E.027": {
            x: -68,
            y: -26,
          },
          //HE
          "H.E.032": {
            x: -68,
            y: -26,
          },
          "H.E.033": {
            x: -68,
            y: -26,
          },
          "H.E.034": {
            x: -68,
            y: -26,
          },
          "H.E.035": {
            x: -68,
            y: -26,
          },
          "H.E.036": {
            x: -68,
            y: -26,
          },
          "H.E.037": {
            x: -68,
            y: -26,
          },
          "H.E.038": {
            x: -68,
            y: -26,
          },
          "H.E.039": {
            x: -68,
            y: -26,
          },
          "H.E.040": {
            x: -68,
            y: -26,
          },
          "H.E.041": {
            x: -68,
            y: -26,
          },
          "H.E.042": {
            x: -68,
            y: -26,
          },
          "H.E.043": {
            x: -68,
            y: -26,
          },
          "H.E.044": {
            x: -68,
            y: -26,
          },
          "H.E.045": {
            x: -68,
            y: -26,
          },
          "H.E.046": {
            x: -68,
            y: -26,
          },
          "H.E.047": {
            x: -68,
            y: -26,
          },
          "H.E.048": {
            x: -68,
            y: -26,
          },
          "H.E.049": {
            x: -68,
            y: -30,
          },
          //GE
          "G.E.031": {
            x: -68,
            y: -26,
          },
          "G.E.032": {
            x: -68,
            y: -26,
          },
          "G.E.033": {
            x: -68,
            y: -26,
          },
          "G.E.034": {
            x: -68,
            y: -26,
          },
          "G.E.035": {
            x: -68,
            y: -26,
          },
          "G.E.036": {
            x: -68,
            y: -26,
          },
          "G.E.037": {
            x: -68,
            y: -26,
          },
          "G.E.038": {
            x: -68,
            y: -26,
          },
          "G.E.039": {
            x: -68,
            y: -26,
          },
          "G.E.040": {
            x: -68,
            y: -26,
          },
          "G.E.041": {
            x: -68,
            y: -26,
          },
          "G.E.042": {
            x: -68,
            y: -26,
          },
          "G.E.043": {
            x: -68,
            y: -26,
          },
          "G.E.044": {
            x: -68,
            y: -26,
          },
          "G.E.045": {
            x: -68,
            y: -26,
          },
          "G.E.046": {
            x: -68,
            y: -26,
          },
          "G.E.047": {
            x: -68,
            y: -26,
          },
          "G.E.048": {
            x: -68,
            y: -26,
          },
          "G.E.049": {
            x: -68,
            y: -16,
          },
          //BW
          "B.W.011": {
            x: -68,
            y: -44,
          },
          "B.W.012": {
            x: -68,
            y: -44,
          },
          "B.W.013": {
            x: -68,
            y: -44,
          },
          "B.W.014": {
            x: -68,
            y: -44,
          },
          "B.W.015": {
            x: -68,
            y: -44,
          },
          "B.W.016": {
            x: -68,
            y: -44,
          },
          "B.W.017": {
            x: -68,
            y: -44,
          },
          "B.W.018": {
            x: -68,
            y: -44,
          },
          "B.W.019": {
            x: -68,
            y: -44,
          },
          "B.W.020": {
            x: -68,
            y: -44,
          },
          "B.W.021": {
            x: -68,
            y: -44,
          },
          "B.W.022": {
            x: -68,
            y: -44,
          },
        },
        groupOffset: {
          x: -68,
          y: 40,
        },
      },
      nonLgvStorage: {
        marker: {
          size: [50, 22],
          rotation: false,
        },
        coordinate: {
          x: 1180,
          y: 230,
        },
      },
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 15000,
          endX: 583000,
          endY: 191000,
        },
        groupOffset: {
          x: -100,
          y: -110,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: -60,
          },
          L2: {
            x: 0,
            y: -25,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 17,
            y: -105,
          },
          destination: {
            x: 17,
            y: -100,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 17,
            y: -100,
          },
          HNDOFF: {
            x: 17,
            y: -100,
          },
          "G.W": {
            x: 17,
            y: -100,
          },
          "H.W": {
            x: 17,
            y: -100,
          },
          "C.E": {
            x: 17,
            y: -100,
          },
          "J.W": {
            x: 17,
            y: -100,
          },
          SSR: {
            x: 17,
            y: -100,
          },
          "E.W": {
            x: 17,
            y: -100,
          },
          STR: {
            x: 17,
            y: -100,
          },
          SOR: {
            x: 17,
            y: -100,
          },
          "I.W": {
            x: 17,
            y: -100,
          },
          BATT: {
            x: 17,
            y: -100,
          },
          "A.E": {
            x: 17,
            y: -100,
          },
          "RDR.K": {
            x: 17,
            y: -100,
          },
          "A.W": {
            x: 17,
            y: -100,
          },
          "B.E": {
            x: 17,
            y: -100,
          },
          NSR: {
            x: 17,
            y: -100,
          },
          "D.E": {
            x: 17,
            y: -100,
          },
          "C.W.GLD": {
            x: 17,
            y: -100,
          },
          "SR.B.E": {
            x: 17,
            y: -100,
          },
          "B.W": {
            x: 17,
            y: -100,
          },
          "J.E": {
            x: 17,
            y: -100,
          },
          "H.E": {
            x: 17,
            y: -100,
          },
          "RSR.P.N": {
            x: 17,
            y: -100,
          },
          "C.W": {
            x: 17,
            y: -100,
          },
          CW: {
            x: 17,
            y: -100,
          },
          "D.W": {
            x: 17,
            y: -100,
          },
          "F.W": {
            x: 17,
            y: -100,
          },
          "G.E": {
            x: 17,
            y: -100,
          },
          "F.E": {
            x: 17,
            y: -100,
          },
          "I.E": {
            x: 17,
            y: -100,
          },
          "RWRK.DRP": {
            x: 17,
            y: 20,
          },
          "D.GLD": {
            x: 17,
            y: -100,
          },
          L1: {
            x: 17,
            y: -100,
          },
          L3: {
            x: 17,
            y: -100,
          },
          "D.N": {
            x: 17,
            y: -100,
          },
          "E.E": {
            x: 17,
            y: -100,
          },
          NOR: {
            x: 17,
            y: -100,
          },
          "K.E": {
            x: 17,
            y: -100,
          },
          OTHERS: {
            x: 17,
            y: -100,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 131000,
          startY: 15000,
          endX: 506000,
          endY: 190000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 350,
          y: 65,
        },
        position: "UP",
      },
    },
    DET: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -25,
          y: -2,
        },
        eachItemOffset: {
          L1: {
            x: -10,
            y: 100,
          },
          L2: {
            x: -10,
            y: 180,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 40,
            y: 480,
          },
          destination: {
            x: 0,
            y: 480,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 30,
            y: 480,
          },
          HNDOFF: {
            x: 40,
            y: 480,
          },
          "G.W": {
            x: 40,
            y: 480,
          },
          "H.W": {
            x: 40,
            y: 480,
          },
          "C.E": {
            x: 40,
            y: 480,
          },
          "J.W": {
            x: 40,
            y: 480,
          },
          SSR: {
            x: 40,
            y: 480,
          },
          "E.W": {
            x: 40,
            y: 480,
          },
          STR: {
            x: 40,
            y: 480,
          },
          SOR: {
            x: 40,
            y: 480,
          },
          "I.W": {
            x: 40,
            y: 480,
          },
          BATT: {
            x: 110,
            y: 340,
          },
          "A.E": {
            x: 40,
            y: 480,
          },
          "RDR.K": {
            x: 40,
            y: 480,
          },
          "A.W": {
            x: 40,
            y: 480,
          },
          "B.E": {
            x: 40,
            y: 480,
          },
          NSR: {
            x: 40,
            y: 480,
          },
          "D.E": {
            x: 40,
            y: 480,
          },
          "C.W.GLD": {
            x: 40,
            y: 480,
          },
          "SR.B.E": {
            x: 40,
            y: 480,
          },
          "B.W": {
            x: 40,
            y: 480,
          },
          "J.E": {
            x: 40,
            y: 480,
          },
          "H.E": {
            x: 40,
            y: 480,
          },
          "RSR.P.N": {
            x: 40,
            y: 485,
          },
          "C.W": {
            x: 40,
            y: 480,
          },
          CW: {
            x: 40,
            y: 480,
          },
          "D.W": {
            x: 40,
            y: 480,
          },
          "F.W": {
            x: 40,
            y: 490,
          },
          "G.E": {
            x: 40,
            y: 480,
          },
          "F.E": {
            x: 40,
            y: 480,
          },
          "I.E": {
            x: 40,
            y: 480,
          },
          "RWRK.DRP": {
            x: 40,
            y: 480,
          },
          "D.GLD": {
            x: 40,
            y: 480,
          },
          L1: {
            x: 40,
            y: 480,
          },
          L3: {
            x: 40,
            y: 480,
          },
          "D.N": {
            x: 40,
            y: 480,
          },
          "E.E": {
            x: 40,
            y: 480,
          },
          NOR: {
            x: 40,
            y: 480,
          },
          "K.E": {
            x: 40,
            y: 480,
          },
          OTHERS: {
            x: 50,
            y: 480,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 53000,
          startY: 13000,
          endX: 374000,
          endY: 168800,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 135,
          y: 20,
        },
        position: "UP",
      },
    },
    ALA: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 20000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: 80,
          y: -380,
        },
        eachItemOffset: {
          L1: {
            x: -10,
            y: 100,
          },
          L2: {
            x: -10,
            y: 220,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -318,
            y: 265,
          },
          destination: {
            x: -350,
            y: 270,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -318,
            y: 260,
          },
          HNDOFF: {
            x: -318,
            y: 260,
          },
          "G.W": {
            x: -318,
            y: 260,
          },
          "H.W": {
            x: -318,
            y: 260,
          },
          "C.E": {
            x: -318,
            y: 260,
          },
          "J.W": {
            x: -318,
            y: 260,
          },
          SSR: {
            x: -318,
            y: 260,
          },
          "E.W": {
            x: -318,
            y: 260,
          },
          STR: {
            x: -318,
            y: 260,
          },
          SOR: {
            x: -318,
            y: 260,
          },
          "I.W": {
            x: -318,
            y: 260,
          },
          BATT: {
            x: -318,
            y: 260,
          },
          "A.E": {
            x: -318,
            y: 260,
          },
          "RDR.K": {
            x: -318,
            y: 260,
          },
          "A.W": {
            x: -318,
            y: 260,
          },
          "B.E": {
            x: -318,
            y: 260,
          },
          NSR: {
            x: -318,
            y: 260,
          },
          "D.E": {
            x: -318,
            y: 270,
          },
          "C.W.GLD": {
            x: -318,
            y: 260,
          },
          "SR.B.E": {
            x: -318,
            y: 260,
          },
          "B.W": {
            x: -318,
            y: 260,
          },
          "J.E": {
            x: -318,
            y: 260,
          },
          "H.E": {
            x: -318,
            y: 260,
          },
          "RSR.P.N": {
            x: -318,
            y: 260,
          },
          "C.W": {
            x: -318,
            y: 260,
          },
          CW: {
            x: -318,
            y: 260,
          },
          "D.W": {
            x: -318,
            y: 260,
          },
          "F.W": {
            x: -318,
            y: 260,
          },
          "G.E": {
            x: -318,
            y: 260,
          },
          "F.E": {
            x: -318,
            y: 260,
          },
          "I.E": {
            x: -318,
            y: 260,
          },
          "RWRK.DRP": {
            x: -318,
            y: 260,
          },
          "D.GLD": {
            x: -318,
            y: 260,
          },
          L1: {
            x: -318,
            y: 260,
          },
          L3: {
            x: -318,
            y: 260,
          },
          "D.N": {
            x: -318,
            y: 260,
          },
          "E.E": {
            x: -318,
            y: 260,
          },
          NOR: {
            x: -318,
            y: 260,
          },
          "K.E": {
            x: -318,
            y: 260,
          },
          OTHERS: {
            x: -318,
            y: 260,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 59000,
          startY: 20000,
          endX: 368000,
          endY: 170000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 50,
          y: 420,
        },
        position: "UP",
      },
    },
    HAZ: {
      yard: {
        mapBg: {
          bounds: { startX: 0, startY: 0, endX: 0, endY: 0 },
          bgColor: "#ffffff",
        },
        dockDoors: {
          marker: { scale: { x: 1, y: 1 } },
          groupOffset: { x: 0, y: 0 },
        },
        isEnabled: false,
      },
      heatmap: {
        mapBg: {
          bounds: {
            startX: 237000,
            startY: 200000,
            endX: 902000,
            endY: 450000,
          },
          coordinates: {
            startX: 240000,
            startY: 260000,
            endX: 830000,
            endY: 470000,
          },
        },
        bounds: {
          startX: 237000,
          startY: 200000,
          endX: 902000,
          endY: 450000,
        },
        groupOffset: { x: 100, y: 265 },
        horizontalBoxCount: 57,
        verticalBoxCount: 25,
        box: { x: 27, y: 27 },
        isEnabled: true,
      },
      indoorMap: {
        mapBg: {
          bounds: {
            startX: 47000,
            startY: 27000,
            endX: 523000,
            endY: 128000,
          },
          coordinates: {
            startX: 38000,
            startY: -13000,
            endX: 680000,
            endY: 153000,
          },
          bgColor: "#ffffff",
        },
        mapRefBg: {
          bounds: {
            startX: 14000,
            startY: -22000,
            endX: 510000,
            endY: 165000,
          },
          bgColor: "#ffffff",
        },
        lgvs: {
          isEnabled: true,
          marker: { size: [15, 15] },
          bounds: {
            startX: 207000,
            startY: 280000,
            endX: 642000,
            endY: 405000,
          },
          groupOffset: { x: 30, y: 295 },
        },
        productionLines: {
          bounds: {
            startX: 47000,
            startY: 13000,
            endX: 523000,
            endY: 171000,
          },
          groupOffset: { x: 250, y: -775 },
          eachItemOffset: {
            L1: { x: -20, y: 105 },
            L2: { x: -20, y: 40 },
            L3: { x: -30, y: 20 },
            L3WIP: { x: -30, y: -70 },
            L4: { x: -30, y: 0 },
            L5: { x: 0, y: 95 },
            L6: { x: 0, y: -25 },
            L7: { x: 0, y: 45 },
          },
          isEnabled: true,
        },
        destinationPointers: {
          bounds: {
            startX: 88000,
            startY: -42500,
            endX: -292000,
            endY: -450000,
          },
          coordinateCorrection: {
            source: { x: 70, y: 266 },
            destination: { x: 70, y: 250 },
          },
          destinationCorrection: {
            STAGE: { x: -90, y: -30 },
            LINES: { x: -90, y: -30 },
            TSL: { x: 70, y: 267 },
            HNDOFF: { x: 70, y: 267 },
            "G.W": { x: 70, y: 267 },
            "H.W": { x: 70, y: 267 },
            "C.E": { x: 70, y: 267 },
            "J.W": { x: 70, y: 267 },
            SSR: { x: 70, y: 267 },
            "E.W": { x: 70, y: 259 },
            STR: { x: 70, y: 267 },
            SOR: { x: 70, y: 267 },
            "I.W": { x: 70, y: 267 },
            BATT: { x: 70, y: 267 },
            "A.E": { x: 70, y: 267 },
            "RDR.K": { x: 70, y: 267 },
            "A.W": { x: 70, y: 267 },
            "B.E": { x: 68, y: 267 },
            NSR: { x: 70, y: 267 },
            "D.E": { x: 70, y: 267 },
            "C.W.GLD": { x: 70, y: 267 },
            "SR.B.E": { x: 70, y: 267 },
            "B.W": { x: 70, y: 267 },
            "J.E": { x: 70, y: 267 },
            "H.E": { x: 70, y: 267 },
            "RSR.P.N": { x: 70, y: 267 },
            "C.W": { x: 70, y: 260 },
            CW: { x: 70, y: 260 },
            "D.W": { x: 70, y: 267 },
            "F.W": { x: 70, y: 267 },
            "G.E": { x: 70, y: 267 },
            "F.E": { x: 70, y: 267 },
            "I.E": { x: 70, y: 267 },
            "RWRK.DRP": { x: 70, y: 267 },
            "D.GLD": { x: 70, y: 267 },
            L1: { x: 70, y: 267 },
            L3: { x: 70, y: 267 },
            "D.N": { x: 70, y: 267 },
            "E.E": { x: 70, y: 267 },
            NOR: { x: 70, y: 267 },
            "K.E": { x: 70, y: 267 },
            OTHERS: { x: 70, y: 267 },
          },
          isEnabled: true,
        },
        dockDoors: {
          bounds: {
            startX: 20000,
            startY: 60000,
            endX: 453000,
            endY: 375000,
          },
          marker: { size: [15, 75] },
          groupOffset: { x: -332, y: 0 },
          position: "UP",
          isEnabled: true,
        },
        batteryStations: {
          bounds: { startX: 0, startY: 0, endX: 0, endY: 0 },
          eachItemOffset: {},
          groupOffset: { x: 0, y: 0 },
          isEnabled: false,
        },
        rackStorage: {
          isEnabled: true,
          bounds: {
            startX: 128000,
            startY: 256000,
            endX: 566000,
            endY: 387000,
          },
          groupOffset: {
            x: 0,
            y: 0,
          },
          eachItemOffset: {},
        },
        blockStorage: {
          isEnabled: true,
          marker: {
            size: {
              width: "4.4px",
              height: "4.6px",
            },
          },
          bounds: {
            startX: 125000,
            startY: 257000,
            endX: 562000,
            endY: 385000,
          },
          eachItemOffset: {},
          eastLocationOffset: {
            x: -3,
            y: -3,
          },
          westLocationOffset: {
            x: -3,
            y: -14,
          },
          groupOffset: {
            x: -3,
            y: 0,
          },
        },
        nonLgvStorage: {
          isEnabled: true,
          marker: {
            size: [50, 22],
            rotation: false,
          },
          coordinate: {
            x: 690,
            y: 440,
          },
        },
      },
      timeMachine: {
        isEnabled: true,
        bounds: {
          startX: 38000,
          startY: -13000,
          endX: 680000,
          endY: 153000,
        },
      },
      isEnabled: true,
      plantName: "Hazleton",
      plantCode: "HAZ",
      isKafkaEnabled: false,
    },
    LAS: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -250,
          y: -15,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: -150,
          },
          L2: {
            x: 0,
            y: 50,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 70,
            y: 368,
          },
          destination: {
            x: 20,
            y: 370,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 75,
            y: 375,
          },
          HNDOFF: {
            x: 60,
            y: 360,
          },
          "G.W": {
            x: 60,
            y: 360,
          },
          "H.W": {
            x: 60,
            y: 360,
          },
          "C.E": {
            x: 60,
            y: 360,
          },
          "J.W": {
            x: 60,
            y: 360,
          },
          SSR: {
            x: 80,
            y: 420,
          },
          "E.W": {
            x: 60,
            y: 360,
          },
          STR: {
            x: 60,
            y: 360,
          },
          SOR: {
            x: 60,
            y: 360,
          },
          "I.W": {
            x: 60,
            y: 360,
          },
          BATT: {
            x: 60,
            y: 360,
          },
          "A.E": {
            x: 60,
            y: 360,
          },
          "RDR.K": {
            x: 60,
            y: 360,
          },
          "A.W": {
            x: 75,
            y: 365,
          },
          "B.E": {
            x: 60,
            y: 360,
          },
          NSR: {
            x: 60,
            y: 360,
          },
          "D.E": {
            x: 60,
            y: 360,
          },
          "C.W.GLD": {
            x: 60,
            y: 360,
          },
          "SR.B.E": {
            x: 60,
            y: 360,
          },
          "B.W": {
            x: 75,
            y: 365,
          },
          "J.E": {
            x: 60,
            y: 360,
          },
          "H.E": {
            x: 75,
            y: 360,
          },
          "RSR.P.N": {
            x: 60,
            y: 360,
          },
          "C.W": {
            x: 75,
            y: 370,
          },
          CW: {
            x: 75,
            y: 370,
          },
          "D.W": {
            x: 60,
            y: 360,
          },
          "F.W": {
            x: 60,
            y: 370,
          },
          "G.E": {
            x: 75,
            y: 360,
          },
          "F.E": {
            x: 75,
            y: 360,
          },
          "I.E": {
            x: 60,
            y: 360,
          },
          "RWRK.DRP": {
            x: 60,
            y: 360,
          },
          "D.GLD": {
            x: 60,
            y: 360,
          },
          L1: {
            x: 60,
            y: 360,
          },
          L3: {
            x: 60,
            y: 360,
          },
          "D.N": {
            x: 70,
            y: 370,
          },
          "E.E": {
            x: 70,
            y: 370,
          },
          NOR: {
            x: 70,
            y: 370,
          },
          "K.E": {
            x: 70,
            y: 370,
          },
          OTHERS: {
            x: 75,
            y: 375,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 340000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 190,
          y: -17,
        },
        position: "UP",
      },
    },
    MIA: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -75,
          y: 110,
        },
        eachItemOffset: {
          L1: {
            x: -25,
            y: -5,
          },
          L2: {
            x: 0,
            y: 380,
          },
          L3: {
            x: 0,
            y: 420,
          },
          L4: {
            x: -32,
            y: 500,
          },
          L5: {
            x: 40,
            y: 640,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 35,
            y: 498,
          },
          destination: {
            x: 70,
            y: 500,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 35,
            y: 500,
          },
          HNDOFF: {
            x: 35,
            y: 520,
          },
          "G.W": {
            x: 50,
            y: 500,
          },
          "H.W": {
            x: 35,
            y: 500,
          },
          "C.E": {
            x: 35,
            y: 500,
          },
          "J.W": {
            x: 35,
            y: 500,
          },
          SSR: {
            x: 35,
            y: 500,
          },
          "E.W": {
            x: 35,
            y: 500,
          },
          STR: {
            x: 35,
            y: 500,
          },
          SOR: {
            x: 35,
            y: 500,
          },
          "I.W": {
            x: 35,
            y: 500,
          },
          BATT: {
            x: 35,
            y: 500,
          },
          "A.E": {
            x: 35,
            y: 500,
          },
          "RDR.K": {
            x: 35,
            y: 500,
          },
          "A.W": {
            x: 35,
            y: 500,
          },
          "B.E": {
            x: 35,
            y: 500,
          },
          NSR: {
            x: 35,
            y: 500,
          },
          "D.E": {
            x: 35,
            y: 500,
          },
          "C.W.GLD": {
            x: 35,
            y: 500,
          },
          "SR.B.E": {
            x: 35,
            y: 460,
          },
          "B.W": {
            x: 35,
            y: 500,
          },
          "J.E": {
            x: 35,
            y: 500,
          },
          "H.E": {
            x: 35,
            y: 500,
          },
          "RSR.P.N": {
            x: 35,
            y: 500,
          },
          "C.W": {
            x: 35,
            y: 510,
          },
          CW: {
            x: 35,
            y: 510,
          },
          "D.W": {
            x: 35,
            y: 500,
          },
          "F.W": {
            x: 0,
            y: 0,
          },
          "G.E": {
            x: 0,
            y: 0,
          },
          "F.E": {
            x: 0,
            y: 0,
          },
          "I.E": {
            x: 0,
            y: 0,
          },
          "RWRK.DRP": {
            x: 0,
            y: 0,
          },
          "D.GLD": {
            x: 0,
            y: 0,
          },
          L1: {
            x: 35,
            y: 500,
          },
          L3: {
            x: 35,
            y: 500,
          },
          "D.N": {
            x: 35,
            y: 500,
          },
          "E.E": {
            x: 35,
            y: 500,
          },
          NOR: {
            x: 35,
            y: 500,
          },
          "K.E": {
            x: 35,
            y: 500,
          },
          OTHERS: {
            x: 35,
            y: 500,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 28300,
          startY: 13000,
          endX: 303000,
          endY: 155000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 210,
          y: 20,
        },
        position: "DOWN",
      },
    },
    LAN: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: 10,
          y: -115,
        },
        eachItemOffset: {
          L1: {
            x: 10,
            y: -90,
          },
          L2: {
            x: 10,
            y: -55,
          },
          L3: {
            x: 10,
            y: 20,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -60,
            y: -46,
          },
          destination: {
            x: -60,
            y: -40,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -60,
            y: -40,
          },
          HNDOFF: {
            x: -100,
            y: -10,
          },
          "G.W": {
            x: -100,
            y: -10,
          },
          "H.W": {
            x: -100,
            y: -10,
          },
          "C.E": {
            x: -65,
            y: -40,
          },
          "J.W": {
            x: -100,
            y: -10,
          },
          SSR: {
            x: -100,
            y: -10,
          },
          "E.W": {
            x: -40,
            y: -10,
          },
          STR: {
            x: -100,
            y: -10,
          },
          SOR: {
            x: -100,
            y: -10,
          },
          "I.W": {
            x: -100,
            y: -10,
          },
          "A.E": {
            x: -100,
            y: -10,
          },
          "A.W": {
            x: -100,
            y: -10,
          },
          "B.E": {
            x: -100,
            y: -10,
          },
          NSR: {
            x: -100,
            y: -10,
          },
          "D.E": {
            x: -40,
            y: -10,
          },
          "C.W.GLD": {
            x: -100,
            y: -10,
          },
          "SR.B.E": {
            x: -100,
            y: -10,
          },
          "B.W": {
            x: -65,
            y: -30,
          },
          "J.E": {
            x: -100,
            y: -10,
          },
          "H.E": {
            x: -100,
            y: -10,
          },
          "RSR.P.N": {
            x: -100,
            y: -10,
          },
          "C.W": {
            x: 0,
            y: 0,
          },
          CW: {
            x: 0,
            y: 0,
          },
          "D.W": {
            x: 0,
            y: 0,
          },
          "F.W": {
            x: 0,
            y: 0,
          },
          "G.E": {
            x: 0,
            y: 0,
          },
          "F.E": {
            x: 0,
            y: 0,
          },
          "I.E": {
            x: 0,
            y: 0,
          },
          "RWRK.DRP": {
            x: 0,
            y: 0,
          },
          "D.GLD": {
            x: 0,
            y: 0,
          },
          L1: {
            x: 0,
            y: 0,
          },
          L3: {
            x: 0,
            y: 0,
          },
          "D.N": {
            x: 0,
            y: 0,
          },
          "E.E": {
            x: 30,
            y: -50,
          },
          NOR: {
            x: 0,
            y: 0,
          },
          "K.E": {
            x: 0,
            y: 0,
          },
          OTHERS: {
            x: -40,
            y: -60,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: -6000,
          startY: 13000,
          endX: 520000,
          endY: 175000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: -60,
          y: 65,
        },
        position: "DOWN",
      },
    },
    ST3: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -290,
          y: -60,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 50,
          },
          L2: {
            x: 0,
            y: 0,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -35,
            y: 270,
          },
          destination: {
            x: -100,
            y: 265,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -100,
            y: 200,
          },
          HNDOFF: {
            x: -40,
            y: 360,
          },
          "G.W": {
            x: -40,
            y: 275,
          },
          "H.W": {
            x: -40,
            y: 265,
          },
          "C.E": {
            x: -40,
            y: 260,
          },
          "J.W": {
            x: -30,
            y: 270,
          },
          SSR: {
            x: -40,
            y: 265,
          },
          "E.W": {
            x: 20,
            y: 320,
          },
          STR: {
            x: -100,
            y: 200,
          },
          SOR: {
            x: -30,
            y: 290,
          },
          "I.W": {
            x: -35,
            y: 270,
          },
          BATT: {
            x: -100,
            y: 200,
          },
          "A.E": {
            x: -100,
            y: 200,
          },
          "RDR.K": {
            x: -45,
            y: 200,
          },
          "A.W": {
            x: -35,
            y: 330,
          },
          "B.E": {
            x: -100,
            y: 200,
          },
          NSR: {
            x: -100,
            y: 200,
          },
          "D.E": {
            x: -35,
            y: 270,
          },
          "C.W.GLD": {
            x: -100,
            y: 200,
          },
          "SR.B.E": {
            x: -100,
            y: 200,
          },
          "B.W": {
            x: -40,
            y: 300,
          },
          "J.E": {
            x: -100,
            y: 200,
          },
          "H.E": {
            x: -45,
            y: 260,
          },
          "RSR.P.N": {
            x: -32,
            y: 290,
          },
          "C.W": {
            x: -35,
            y: 270,
          },
          CW: {
            x: -35,
            y: 270,
          },
          "D.W": {
            x: -37,
            y: 280,
          },
          "F.W": {
            x: -35,
            y: 275,
          },
          "G.E": {
            x: 0,
            y: 0,
          },
          "F.E": {
            x: 100,
            y: 300,
          },
          "I.E": {
            x: 100,
            y: 300,
          },
          "RWRK.DRP": {
            x: -100,
            y: 300,
          },
          "D.GLD": {
            x: -100,
            y: 300,
          },
          L1: {
            x: -100,
            y: 300,
          },
          L3: {
            x: -100,
            y: 300,
          },
          "D.N": {
            x: -100,
            y: 300,
          },
          "E.E": {
            x: -100,
            y: 300,
          },
          NOR: {
            x: -100,
            y: 300,
          },
          "K.E": {
            x: -100,
            y: 300,
          },
          OTHERS: {
            x: -100,
            y: 200,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 38000,
          startY: 13000,
          endX: 421000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 100,
          y: 60,
        },
        position: "UP",
      },
    },
    BAL: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: 720,
          y: -740,
        },
        eachItemOffset: {
          L1: {
            x: -5,
            y: 815,
          },
          L2: {
            x: -5,
            y: 30,
          },
          L3: {
            x: 5,
            y: 0,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -745,
            y: -920,
          },
          destination: {
            x: -795,
            y: -925,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -745,
            y: -910,
          },
          HNDOFF: {
            x: -745,
            y: -910,
          },
          "G.W": {
            x: -745,
            y: -910,
          },
          "H.W": {
            x: -745,
            y: -910,
          },
          "C.E": {
            x: -745,
            y: -910,
          },
          "J.W": {
            x: -745,
            y: -910,
          },
          SSR: {
            x: -745,
            y: -910,
          },
          "E.W": {
            x: -745,
            y: -910,
          },
          STR: {
            x: -745,
            y: -910,
          },
          SOR: {
            x: -745,
            y: -910,
          },
          "I.W": {
            x: -745,
            y: -910,
          },
          BATT: {
            x: -745,
            y: -910,
          },
          "A.E": {
            x: -745,
            y: -910,
          },
          "RDR.K": {
            x: -745,
            y: -910,
          },
          "A.W": {
            x: -745,
            y: -910,
          },
          "B.E": {
            x: -745,
            y: -910,
          },
          NSR: {
            x: -745,
            y: -910,
          },
          "D.E": {
            x: -745,
            y: -910,
          },
          "C.W.GLD": {
            x: -745,
            y: -910,
          },
          "SR.B.E": {
            x: -745,
            y: -910,
          },
          "B.W": {
            x: -745,
            y: -910,
          },
          "J.E": {
            x: -745,
            y: -910,
          },
          "H.E": {
            x: -745,
            y: -910,
          },
          "RSR.P.N": {
            x: -745,
            y: -910,
          },
          "C.W": {
            x: -745,
            y: -910,
          },
          CW: {
            x: -745,
            y: -910,
          },
          "D.W": {
            x: -745,
            y: -910,
          },
          "F.W": {
            x: -745,
            y: -910,
          },
          "G.E": {
            x: -745,
            y: -910,
          },
          "F.E": {
            x: -745,
            y: -910,
          },
          "I.E": {
            x: -745,
            y: -910,
          },
          "RWRK.DRP": {
            x: -745,
            y: -910,
          },
          "D.GLD": {
            x: -745,
            y: -910,
          },
          L1: {
            x: -745,
            y: -910,
          },
          L3: {
            x: -745,
            y: -910,
          },
          "D.N": {
            x: -725,
            y: -990,
          },
          "E.E": {
            x: -725,
            y: -990,
          },
          NOR: {
            x: -725,
            y: -990,
          },
          "K.E": {
            x: -725,
            y: -990,
          },
          OTHERS: {
            x: -855,
            y: -885,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 117000,
          startY: 13000,
          endX: 483000,
          endY: 169500,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: -862,
          y: 810,
        },
        position: "UP",
      },
    },
    KC2: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -140,
          y: -140,
        },
        eachItemOffset: {
          L1: {
            x: -20,
            y: -80,
          },
          L2: {
            x: -20,
            y: 5,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -125,
            y: 270,
          },
          destination: {
            x: -167,
            y: 268,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -107,
            y: 260,
          },
          HNDOFF: {
            x: -112,
            y: 260,
          },
          "G.W": {
            x: 330,
            y: -921,
          },
          "H.W": {
            x: -112,
            y: 260,
          },
          "C.E": {
            x: -112,
            y: 260,
          },
          "J.W": {
            x: -112,
            y: 260,
          },
          SSR: {
            x: -112,
            y: 260,
          },
          "E.W": {
            x: -112,
            y: -100,
          },
          STR: {
            x: -112,
            y: 260,
          },
          SOR: {
            x: 330,
            y: -530,
          },
          "I.W": {
            x: -112,
            y: 260,
          },
          BATT: {
            x: -112,
            y: 260,
          },
          "A.E": {
            x: -112,
            y: 260,
          },
          "RDR.K": {
            x: -112,
            y: 260,
          },
          "A.W": {
            x: -120,
            y: 260,
          },
          "B.E": {
            x: -112,
            y: 260,
          },
          NSR: {
            x: -112,
            y: 260,
          },
          "D.E": {
            x: -112,
            y: 260,
          },
          "C.W.GLD": {
            x: -112,
            y: 260,
          },
          "SR.B.E": {
            x: -112,
            y: 260,
          },
          "B.W": {
            x: -112,
            y: 260,
          },
          "J.E": {
            x: -112,
            y: 260,
          },
          "H.E": {
            x: 0,
            y: 0,
          },
          "RSR.P.N": {
            x: -112,
            y: 260,
          },
          "C.W": {
            x: -112,
            y: 260,
          },
          CW: {
            x: -112,
            y: 260,
          },
          "D.W": {
            x: -112,
            y: 260,
          },
          "F.W": {
            x: -117,
            y: 260,
          },
          "G.E": {
            x: -112,
            y: 260,
          },
          "F.E": {
            x: -112,
            y: 260,
          },
          "I.E": {
            x: -112,
            y: 260,
          },
          "RWRK.DRP": {
            x: 330,
            y: -530,
          },
          "D.GLD": {
            x: -112,
            y: 260,
          },
          L1: {
            x: -112,
            y: 260,
          },
          L3: {
            x: -120,
            y: 260,
          },
          "D.N": {
            x: -124,
            y: 260,
          },
          "E.E": {
            x: -112,
            y: 260,
          },
          NOR: {
            x: -112,
            y: 260,
          },
          "K.E": {
            x: -112,
            y: 260,
          },
          OTHERS: {
            x: -127,
            y: 280,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 67000,
          startY: 13000,
          endX: 449000,
          endY: 169000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 185,
          y: 155,
        },
        position: "UP",
      },
    },
    MOR: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 529700,
          endY: 161000,
        },
        groupOffset: {
          x: -80,
          y: -72,
        },
        eachItemOffset: {
          L1: {
            x: -18,
            y: -85,
          },
          L2: {
            x: -18,
            y: -10,
          },
          L3: {
            x: -28,
            y: -10,
          },
          L4: {
            x: -28,
            y: 72,
          },
          L5: {
            x: -28,
            y: 100,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -146,
            y: 180,
          },
          destination: {
            x: -150,
            y: 175,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -130,
            y: 268,
          },
          HNDOFF: {
            x: -146,
            y: 200,
          },
          "G.W": {
            x: -146,
            y: 295,
          },
          "H.W": {
            x: -146,
            y: 200,
          },
          "C.E": {
            x: -146,
            y: 203,
          },
          "J.W": {
            x: -146,
            y: 200,
          },
          SSR: {
            x: -146,
            y: 200,
          },
          "E.W": {
            x: -146,
            y: 170,
          },
          STR: {
            x: -146,
            y: 200,
          },
          SOR: {
            x: -146,
            y: 200,
          },
          "I.W": {
            x: -146,
            y: 200,
          },
          BATT: {
            x: -146,
            y: 200,
          },
          "A.E": {
            x: -146,
            y: 200,
          },
          "RDR.K": {
            x: -146,
            y: 200,
          },
          "A.W": {
            x: -146,
            y: 160,
          },
          "B.E": {
            x: -146,
            y: 195,
          },
          NSR: {
            x: -146,
            y: 195,
          },
          "D.E": {
            x: -146,
            y: 195,
          },
          "C.W.GLD": {
            x: -146,
            y: 195,
          },
          "SR.B.E": {
            x: -146,
            y: 195,
          },
          "B.W": {
            x: -146,
            y: 195,
          },
          "J.E": {
            x: -100,
            y: 150,
          },
          "H.E": {
            x: -146,
            y: 195,
          },
          "RSR.P.N": {
            x: -146,
            y: 195,
          },
          "C.W": {
            x: -146,
            y: 195,
          },
          CW: {
            x: -146,
            y: 195,
          },
          "D.W": {
            x: -135,
            y: 195,
          },
          "F.W": {
            x: -146,
            y: 195,
          },
          "G.E": {
            x: -146,
            y: 195,
          },
          "F.E": {
            x: -150,
            y: 200,
          },
          "I.E": {
            x: -146,
            y: 195,
          },
          "RWRK.DRP": {
            x: -125,
            y: 215,
          },
          "D.GLD": {
            x: -146,
            y: 195,
          },
          L1: {
            x: -146,
            y: 195,
          },
          L3: {
            x: -138,
            y: 195,
          },
          "D.N": {
            x: -146,
            y: 195,
          },
          "E.E": {
            x: -146,
            y: 195,
          },
          NOR: {
            x: -146,
            y: 195,
          },
          "K.E": {
            x: -146,
            y: 195,
          },
          OTHERS: {
            x: -145,
            y: 176,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 68800,
          startY: -42000,
          endX: 493700,
          endY: 174000,
        },
        eachItemOffset: {
          "STAGEE.005": { x: 0, y: 0 },
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 140,
          y: 182,
        },
        position: "UP",
      },
    },
    TEM: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 161000,
        },
        groupOffset: {
          x: -280,
          y: 230,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 0,
          },
          L2: {
            x: 0,
            y: 80,
          },
          L6: {
            x: 80,
            y: 180,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -40,
            y: 19,
          },
          destination: {
            x: -55,
            y: 22,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -42,
            y: 55,
          },
          HNDOFF: {
            x: -45,
            y: 25,
          },
          "G.W": {
            x: -45,
            y: 10,
          },
          "H.W": {
            x: -45,
            y: 25,
          },
          "C.E": {
            x: -45,
            y: 25,
          },
          "J.W": {
            x: -45,
            y: 25,
          },
          SSR: {
            x: -45,
            y: 25,
          },
          "E.W": {
            x: -39,
            y: 25,
          },
          STR: {
            x: -45,
            y: 25,
          },
          SOR: {
            x: -45,
            y: 25,
          },
          "I.W": {
            x: -45,
            y: 25,
          },
          BATT: {
            x: -45,
            y: 25,
          },
          "A.E": {
            x: -45,
            y: 25,
          },
          "RDR.K": {
            x: -45,
            y: 25,
          },
          "A.W": {
            x: -45,
            y: 25,
          },
          "B.E": {
            x: -39,
            y: 25,
          },
          NSR: {
            x: -45,
            y: 25,
          },
          "D.E": {
            x: -45,
            y: 25,
          },
          "C.W.GLD": {
            x: -45,
            y: 25,
          },
          "SR.B.E": {
            x: -45,
            y: 25,
          },
          "B.W": {
            x: -45,
            y: 25,
          },
          "J.E": {
            x: -45,
            y: 25,
          },
          "H.E": {
            x: -45,
            y: 25,
          },
          "RSR.P.N": {
            x: -45,
            y: 25,
          },
          "C.W": {
            x: -42,
            y: 55,
          },
          CW: {
            x: -42,
            y: 55,
          },
          "D.W": {
            x: -45,
            y: 25,
          },
          "F.W": {
            x: -39,
            y: 25,
          },
          "G.E": {
            x: -38,
            y: 55,
          },
          "F.E": {
            x: -39,
            y: 55,
          },
          "I.E": {
            x: -45,
            y: 25,
          },
          "RWRK.DRP": {
            x: -45,
            y: 25,
          },
          "D.GLD": {
            x: -45,
            y: 25,
          },
          L1: {
            x: -45,
            y: 25,
          },
          L3: {
            x: -42,
            y: 55,
          },
          "D.N": {
            x: -45,
            y: 25,
          },
          "E.E": {
            x: -39,
            y: 35,
          },
          NOR: {
            x: -42,
            y: 55,
          },
          "K.E": {
            x: -42,
            y: 55,
          },
          OTHERS: {
            x: -45,
            y: 25,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 37200,
          startY: 13000,
          endX: 429000,
          endY: 161000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 316,
          y: -185,
        },
        position: "DOWN",
      },
    },
    LOU: {
      productionLines: {
        bounds: {
          startX: 47000,
          startY: 13000,
          endX: 523000,
          endY: 171000,
        },
        groupOffset: {
          x: -95,
          y: -102,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 283,
          },
          L2: {
            x: 0,
            y: 205,
          },
          L3: {
            x: 0,
            y: 230,
          },
          L4: {
            x: 0,
            y: 105,
          },
          L3WIP: {
            x: 0,
            y: 190,
          },
          L5: {
            x: 0,
            y: 0,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -320,
            y: 345,
          },
          destination: {
            x: -275,
            y: 350,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -320,
            y: 335,
          },
          HNDOFF: {
            x: -320,
            y: 335,
          },
          "G.W": {
            x: -320,
            y: 320,
          },
          "H.W": {
            x: -320,
            y: 345,
          },
          "C.E": {
            x: -320,
            y: 335,
          },
          "J.W": {
            x: -320,
            y: 335,
          },
          SSR: {
            x: -320,
            y: 335,
          },
          "E.W": {
            x: -320,
            y: 335,
          },
          STR: {
            x: -320,
            y: 335,
          },
          SOR: {
            x: -320,
            y: 335,
          },
          "I.W": {
            x: -320,
            y: 335,
          },
          BATT: {
            x: -320,
            y: 335,
          },
          "A.E": {
            x: -320,
            y: 335,
          },
          "RDR.K": {
            x: -320,
            y: 335,
          },
          "A.W": {
            x: -320,
            y: 335,
          },
          "B.E": {
            x: -320,
            y: 335,
          },
          NSR: {
            x: -320,
            y: 335,
          },
          "D.E": {
            x: -320,
            y: 335,
          },
          "C.W.GLD": {
            x: -320,
            y: 335,
          },
          "SR.B.E": {
            x: -320,
            y: 335,
          },
          "B.W": {
            x: -320,
            y: 335,
          },
          "J.E": {
            x: -320,
            y: 335,
          },
          "H.E": {
            x: -320,
            y: 335,
          },
          "RSR.P.N": {
            x: -320,
            y: 335,
          },
          "C.W": {
            x: -320,
            y: 335,
          },
          CW: {
            x: -320,
            y: 335,
          },
          "D.W": {
            x: -320,
            y: 335,
          },
          "F.W": {
            x: -320,
            y: 335,
          },
          "G.E": {
            x: -320,
            y: 335,
          },
          "F.E": {
            x: -320,
            y: 335,
          },
          "I.E": {
            x: -320,
            y: 350,
          },
          "RWRK.DRP": {
            x: -320,
            y: 335,
          },
          "D.GLD": {
            x: -320,
            y: 335,
          },
          L1: {
            x: -320,
            y: 335,
          },
          L3: {
            x: -320,
            y: 335,
          },
          "D.N": {
            x: -320,
            y: 335,
          },
          "E.E": {
            x: -320,
            y: 335,
          },
          NOR: {
            x: -320,
            y: 335,
          },
          "K.E": {
            x: -320,
            y: 335,
          },
          OTHERS: {
            x: -320,
            y: 335,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 52600,
          startY: 13000,
          endX: 400000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 160,
          y: 280,
        },
        position: "DOWN",
      },
    },
    KNC: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 16000,
          endX: 583000,
          endY: 171000,
        },
        groupOffset: {
          x: -215,
          y: -55,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 0,
          },
          L2: {
            x: 5,
            y: 175,
          },
          L3: {
            x: -10,
            y: 340,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 70,
            y: 30,
          },
          destination: {
            x: 65,
            y: 40,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 65,
            y: 10,
          },
          HNDOFF: {
            x: 65,
            y: 10,
          },
          "G.W": {
            x: 65,
            y: 10,
          },
          "H.W": {
            x: 70,
            y: 25,
          },
          "C.E": {
            x: 70,
            y: 25,
          },
          "J.W": {
            x: 70,
            y: 45,
          },
          SSR: {
            x: 540,
            y: 400,
          },
          "E.W": {
            x: 70,
            y: 45,
          },
          STR: {
            x: 70,
            y: 45,
          },
          SOR: {
            x: 70,
            y: 45,
          },
          "I.W": {
            x: 70,
            y: 45,
          },
          BATT: {
            x: 70,
            y: 45,
          },
          "A.E": {
            x: 70,
            y: 45,
          },
          "RDR.K": {
            x: 70,
            y: 25,
          },
          "A.W": {
            x: 70,
            y: 25,
          },
          "B.E": {
            x: 70,
            y: 25,
          },
          NSR: {
            x: 70,
            y: 25,
          },
          "D.E": {
            x: 70,
            y: 25,
          },
          "C.W.GLD": {
            x: 70,
            y: 25,
          },
          "SR.B.E": {
            x: 70,
            y: 25,
          },
          "B.W": {
            x: 70,
            y: 25,
          },
          "J.E": {
            x: 70,
            y: 25,
          },
          "H.E": {
            x: 70,
            y: 25,
          },
          "RSR.P.N": {
            x: 70,
            y: 25,
          },
          "C.W": {
            x: 70,
            y: 35,
          },
          CW: {
            x: 70,
            y: 35,
          },
          "D.W": {
            x: 70,
            y: 45,
          },
          "F.W": {
            x: 70,
            y: 35,
          },
          "G.E": {
            x: 70,
            y: 45,
          },
          "F.E": {
            x: 65,
            y: 25,
          },
          "I.E": {
            x: 70,
            y: 45,
          },
          "RWRK.DRP": {
            x: 70,
            y: 25,
          },
          "D.GLD": {
            x: 70,
            y: 25,
          },
          L1: {
            x: 70,
            y: 25,
          },
          L3: {
            x: 70,
            y: 25,
          },
          "D.N": {
            x: 70,
            y: 25,
          },
          "E.E": {
            x: 70,
            y: 25,
          },
          NOR: {
            x: 70,
            y: 25,
          },
          "K.E": {
            x: 70,
            y: 25,
          },
          OTHERS: {
            x: 65,
            y: 10,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 42000,
          startY: 13000,
          endX: 323500,
          endY: 167000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 160,
          y: 105,
        },
        position: "UP",
      },
    },
    BLM: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 16000,
          endX: 583000,
          endY: 171000,
        },
        groupOffset: {
          x: -172,
          y: -38,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 0,
          },
          L2: {
            x: 0,
            y: 40,
          },
          L3: {
            x: 0,
            y: 175,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 105,
            y: -45,
          },
          destination: {
            x: 100,
            y: -46,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 105,
            y: -53,
          },
          HNDOFF: {
            x: 105,
            y: -53,
          },
          "G.W": {
            x: 105,
            y: -53,
          },
          "H.W": {
            x: 105,
            y: -53,
          },
          "C.E": {
            x: 105,
            y: -53,
          },
          "J.W": {
            x: 105,
            y: -53,
          },
          SSR: {
            x: 105,
            y: -53,
          },
          "E.W": {
            x: 105,
            y: -53,
          },
          STR: {
            x: 105,
            y: -53,
          },
          SOR: {
            x: 105,
            y: -53,
          },
          "I.W": {
            x: 105,
            y: -53,
          },
          BATT: {
            x: 105,
            y: -53,
          },
          "A.E": {
            x: 105,
            y: -53,
          },
          "RDR.K": {
            x: 105,
            y: -53,
          },
          "A.W": {
            x: 105,
            y: -53,
          },
          "B.E": {
            x: 105,
            y: -53,
          },
          NSR: {
            x: 105,
            y: -53,
          },
          "D.E": {
            x: 105,
            y: -53,
          },
          "C.W.GLD": {
            x: 105,
            y: -53,
          },
          "SR.B.E": {
            x: 105,
            y: -53,
          },
          "B.W": {
            x: 105,
            y: -53,
          },
          "J.E": {
            x: 105,
            y: -53,
          },
          "H.E": {
            x: 105,
            y: -53,
          },
          "RSR.P.N": {
            x: 105,
            y: -53,
          },
          "C.W": {
            x: 105,
            y: -53,
          },
          CW: {
            x: 105,
            y: -53,
          },
          "D.W": {
            x: 105,
            y: -53,
          },
          "F.W": {
            x: 105,
            y: -53,
          },
          "G.E": {
            x: 105,
            y: -53,
          },
          "F.E": {
            x: 105,
            y: -53,
          },
          "I.E": {
            x: 105,
            y: -53,
          },
          "RWRK.DRP": {
            x: 105,
            y: -53,
          },
          "D.GLD": {
            x: 105,
            y: -53,
          },
          L1: {
            x: 105,
            y: -53,
          },
          L3: {
            x: 105,
            y: -53,
          },
          "D.N": {
            x: 105,
            y: -53,
          },
          "E.E": {
            x: 105,
            y: -53,
          },
          NOR: {
            x: 105,
            y: -53,
          },
          "K.E": {
            x: 105,
            y: -53,
          },
          OTHERS: {
            x: 105,
            y: -53,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 134000,
          startY: 16000,
          endX: 440000,
          endY: 168500,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 520,
          y: 25,
        },
        position: "UP",
      },
    },
    KEN: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 16000,
          endX: 583000,
          endY: 171000,
        },
        groupOffset: {
          x: -230,
          y: 20,
        },
        eachItemOffset: {
          L1: {
            x: -20,
            y: 190,
          },
          L2: {
            x: -20,
            y: 150,
          },
          L3: {
            x: -10,
            y: 50,
          },
          L4: {
            x: -10,
            y: 0,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 200,
            y: -30,
          },
          destination: {
            x: 150,
            y: -27,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 200,
            y: -75,
          },
          HNDOFF: {
            x: 200,
            y: -75,
          },
          "G.W": {
            x: 200,
            y: -75,
          },
          "H.W": {
            x: 200,
            y: -75,
          },
          "C.E": {
            x: 200,
            y: -75,
          },
          "J.W": {
            x: 200,
            y: -75,
          },
          SSR: {
            x: 200,
            y: -75,
          },
          "E.W": {
            x: 200,
            y: -32,
          },
          STR: {
            x: 200,
            y: -20,
          },
          SOR: {
            x: 200,
            y: -75,
          },
          "I.W": {
            x: 200,
            y: -75,
          },
          BATT: {
            x: 200,
            y: -75,
          },
          "A.E": {
            x: 200,
            y: -75,
          },
          "RDR.K": {
            x: 200,
            y: -75,
          },
          "A.W": {
            x: 200,
            y: -75,
          },
          "B.E": {
            x: 200,
            y: -75,
          },
          NSR: {
            x: 200,
            y: -75,
          },
          "D.E": {
            x: 200,
            y: -75,
          },
          "C.W.GLD": {
            x: 200,
            y: -75,
          },
          "SR.B.E": {
            x: 200,
            y: -75,
          },
          "B.W": {
            x: 200,
            y: -75,
          },
          "J.E": {
            x: 200,
            y: -75,
          },
          "H.E": {
            x: 200,
            y: -75,
          },
          "RSR.P.N": {
            x: 200,
            y: -75,
          },
          "C.W": {
            x: 200,
            y: -75,
          },
          CW: {
            x: 200,
            y: -75,
          },
          "D.W": {
            x: 200,
            y: -75,
          },
          "F.W": {
            x: 200,
            y: -75,
          },
          "G.E": {
            x: 200,
            y: -75,
          },
          "F.E": {
            x: 200,
            y: -75,
          },
          "I.E": {
            x: 200,
            y: -75,
          },
          "RWRK.DRP": {
            x: 200,
            y: -50,
          },
          "D.GLD": {
            x: 200,
            y: -75,
          },
          L1: {
            x: 200,
            y: -75,
          },
          L3: {
            x: 200,
            y: -75,
          },
          "D.N": {
            x: 200,
            y: -75,
          },
          "E.E": {
            x: 200,
            y: -75,
          },
          NOR: {
            x: 200,
            y: -75,
          },
          "K.E": {
            x: 200,
            y: -75,
          },
          OTHERS: {
            x: 200,
            y: -50,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 48000,
          startY: 16000,
          endX: 390000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 160,
          y: -65,
        },
        position: "UP",
      },
    },
    SAN: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 14000,
          endX: 583000,
          endY: 171000,
        },
        groupOffset: {
          x: -180,
          y: 260,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 55,
          },
          L2: {
            x: 0,
            y: 15,
          },
          L3: {
            x: 0,
            y: 0,
          },
          L4: {
            x: -16,
            y: -10,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 120,
            y: 0,
          },
          destination: {
            x: 135,
            y: 0,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 120,
            y: 0,
          },
          HNDOFF: {
            x: 120,
            y: 0,
          },
          "G.W": {
            x: 120,
            y: 0,
          },
          "H.W": {
            x: 120,
            y: 0,
          },
          "C.E": {
            x: 120,
            y: 0,
          },
          "J.W": {
            x: 120,
            y: 0,
          },
          SSR: {
            x: 120,
            y: 0,
          },
          STR: {
            x: 120,
            y: 0,
          },
          SOR: {
            x: 120,
            y: 0,
          },
          "I.W": {
            x: 120,
            y: 0,
          },
          BATT: {
            x: 120,
            y: 0,
          },
          "A.E": {
            x: 120,
            y: 0,
          },
          "RDR.K": {
            x: 120,
            y: 0,
          },
          "A.W": {
            x: 120,
            y: 0,
          },
          "B.E": {
            x: 120,
            y: 0,
          },
          NSR: {
            x: 120,
            y: 0,
          },
          "D.E": {
            x: 120,
            y: 0,
          },
          "C.W.GLD": {
            x: 120,
            y: 0,
          },
          "SR.B.E": {
            x: 120,
            y: 0,
          },
          "B.W": {
            x: 120,
            y: 0,
          },
          "J.E": {
            x: 120,
            y: 0,
          },
          "H.E": {
            x: 120,
            y: 0,
          },
          "RSR.P.N": {
            x: 120,
            y: 0,
          },
          "C.W": {
            x: 120,
            y: 0,
          },
          CW: {
            x: 120,
            y: 0,
          },
          "D.W": {
            x: 120,
            y: 0,
          },
          "F.W": {
            x: 120,
            y: 0,
          },
          "G.E": {
            x: 120,
            y: 0,
          },
          "F.E": {
            x: 120,
            y: 0,
          },
          "I.E": {
            x: 120,
            y: 0,
          },
          "RWRK.DRP": {
            x: 120,
            y: 0,
          },
          "D.GLD": {
            x: 120,
            y: 0,
          },
          L1: {
            x: 120,
            y: 0,
          },
          L3: {
            x: 120,
            y: 0,
          },
          "D.N": {
            x: 120,
            y: 0,
          },
          "E.E": {
            x: 120,
            y: 0,
          },
          NOR: {
            x: 120,
            y: 0,
          },
          "K.E": {
            x: 120,
            y: 0,
          },
          OTHERS: {
            x: 120,
            y: 0,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 49500,
          startY: 14000,
          endX: 495000,
          endY: 180000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 108,
          y: -25,
        },
        position: "DOWN",
      },
    },
    SL2: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 16000,
          endX: 583000,
          endY: 171000,
        },
        groupOffset: {
          x: 435,
          y: 310,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 0,
          },
          L2: {
            x: 0,
            y: 460,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -203,
            y: -975,
          },
          destination: {
            x: -130,
            y: -980,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -200,
            y: -990,
          },
          HNDOFF: {
            x: -200,
            y: -990,
          },
          "G.W": {
            x: -200,
            y: -990,
          },
          "H.W": {
            x: -200,
            y: -990,
          },
          "C.E": {
            x: -200,
            y: -990,
          },
          "J.W": {
            x: -200,
            y: -990,
          },
          SSR: {
            x: -200,
            y: -990,
          },
          "E.W": {
            x: -200,
            y: -990,
          },
          STR: {
            x: -200,
            y: -990,
          },
          SOR: {
            x: -200,
            y: -990,
          },
          "I.W": {
            x: -200,
            y: -990,
          },
          BATT: {
            x: -200,
            y: -990,
          },
          "A.E": {
            x: -200,
            y: -990,
          },
          "RDR.K": {
            x: -200,
            y: -990,
          },
          "A.W": {
            x: -200,
            y: -990,
          },
          "B.E": {
            x: -200,
            y: -990,
          },
          NSR: {
            x: -200,
            y: -990,
          },
          "D.E": {
            x: -200,
            y: -990,
          },
          "C.W.GLD": {
            x: -200,
            y: -990,
          },
          "SR.B.E": {
            x: -200,
            y: -990,
          },
          "B.W": {
            x: -200,
            y: -990,
          },
          "J.E": {
            x: -200,
            y: -990,
          },
          "H.E": {
            x: -200,
            y: -990,
          },
          "RSR.P.N": {
            x: -200,
            y: -990,
          },
          "C.W": {
            x: -200,
            y: -990,
          },
          CW: {
            x: -200,
            y: -990,
          },
          "D.W": {
            x: -200,
            y: -990,
          },
          "F.W": {
            x: -200,
            y: -990,
          },
          "G.E": {
            x: -200,
            y: -990,
          },
          "F.E": {
            x: -200,
            y: -990,
          },
          "I.E": {
            x: -200,
            y: -990,
          },
          "RWRK.DRP": {
            x: -200,
            y: -990,
          },
          "D.GLD": {
            x: -200,
            y: -990,
          },
          L1: {
            x: -200,
            y: -990,
          },
          L3: {
            x: -200,
            y: -990,
          },
          "D.N": {
            x: -200,
            y: -990,
          },
          "E.E": {
            x: -200,
            y: -990,
          },
          NOR: {
            x: -200,
            y: -990,
          },
          "K.E": {
            x: -200,
            y: -990,
          },
          OTHERS: {
            x: -200,
            y: -990,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 161000,
          startY: 16000,
          endX: 360000,
          endY: 171000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 250,
          y: 327,
        },
        position: "DOWN",
      },
    },
    PIT: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 10000,
          endX: 590000,
          endY: 231000,
        },
        groupOffset: {
          x: 710,
          y: -665,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 0,
          },
          L2: {
            x: 0,
            y: 85,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -730,
            y: -1475,
          },
          destination: {
            x: -765,
            y: -1470,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -765,
            y: -1470,
          },
          HNDOFF: {
            x: -765,
            y: -1470,
          },
          "G.W": {
            x: -730,
            y: -1465,
          },
          "H.W": {
            x: -730,
            y: -1470,
          },
          "C.E": {
            x: -720,
            y: -1470,
          },
          "J.W": {
            x: -765,
            y: -1470,
          },
          SSR: {
            x: -730,
            y: -1470,
          },
          "E.W": {
            x: -765,
            y: -1470,
          },
          STR: {
            x: -765,
            y: -1470,
          },
          SOR: {
            x: -765,
            y: -1470,
          },
          "I.W": {
            x: -765,
            y: -1470,
          },
          BATT: {
            x: -730,
            y: -1470,
          },
          "A.E": {
            x: -730,
            y: -1470,
          },
          "RDR.K": {
            x: -765,
            y: -1470,
          },
          "A.W": {
            x: -725,
            y: -1450,
          },
          "B.E": {
            x: -730,
            y: -1470,
          },
          NSR: {
            x: -730,
            y: -1470,
          },
          "D.E": {
            x: -735,
            y: -1470,
          },
          "C.W.GLD": {
            x: -735,
            y: -1470,
          },
          "SR.B.E": {
            x: -730,
            y: -1470,
          },
          "B.W": {
            x: -730,
            y: -1470,
          },
          "J.E": {
            x: -730,
            y: -1470,
          },
          "H.E": {
            x: -730,
            y: -1470,
          },
          "RSR.P.N": {
            x: -730,
            y: -1470,
          },
          "C.W": {
            x: -730,
            y: -1465,
          },
          CW: {
            x: -730,
            y: -1465,
          },
          "D.W": {
            x: -730,
            y: -1450,
          },
          "F.W": {
            x: -730,
            y: -1480,
          },
          "G.E": {
            x: -730,
            y: -1470,
          },
          "F.E": {
            x: -725,
            y: -1470,
          },
          "I.E": {
            x: -730,
            y: -1470,
          },
          "RWRK.DRP": {
            x: -600,
            y: -1400,
          },
          "D.GLD": {
            x: -735,
            y: -1470,
          },
          L1: {
            x: -735,
            y: -1470,
          },
          L3: {
            x: -735,
            y: -1470,
          },
          "D.N": {
            x: -735,
            y: -1470,
          },
          "E.E": {
            x: -735,
            y: -1470,
          },
          NOR: {
            x: -735,
            y: -1470,
          },
          "K.E": {
            x: -735,
            y: -1470,
          },
          OTHERS: {
            x: -765,
            y: -1470,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 191000,
          startY: 10000,
          endX: 537000,
          endY: 231000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: -600,
          y: 598,
        },
        position: "UP",
      },
    },
    MTY: {
      dockDoors: {
        bounds: {
          startX: 123000,
          startY: 15000,
          endX: 468000,
          endY: 191000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: -425,
          y: 164,
        },
        position: "DOWN",
      },
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 15000,
          endX: 583000,
          endY: 191000,
        },
        groupOffset: {
          x: 350,
          y: 90,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: -60,
          },
          L2: {
            x: 0,
            y: 10,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: -75,
            y: -15,
          },
          destination: {
            x: -30,
            y: -10,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: -65,
            y: -20,
          },
          HNDOFF: {
            x: -65,
            y: -20,
          },
          "G.W": {
            x: -65,
            y: -20,
          },
          "H.W": {
            x: -65,
            y: -20,
          },
          "C.E": {
            x: -65,
            y: -20,
          },
          "J.W": {
            x: -65,
            y: -20,
          },
          SSR: {
            x: -65,
            y: -20,
          },
          "E.W": {
            x: -65,
            y: -20,
          },
          STR: {
            x: -65,
            y: -20,
          },
          SOR: {
            x: -65,
            y: -20,
          },
          "I.W": {
            x: -65,
            y: -20,
          },
          BATT: {
            x: -65,
            y: -20,
          },
          "A.E": {
            x: -65,
            y: -20,
          },
          "RDR.K": {
            x: -65,
            y: -20,
          },
          "A.W": {
            x: -65,
            y: -20,
          },
          "B.E": {
            x: -65,
            y: -20,
          },
          NSR: {
            x: -65,
            y: -20,
          },
          "D.E": {
            x: -75,
            y: -20,
          },
          "C.W.GLD": {
            x: -65,
            y: -20,
          },
          "SR.B.E": {
            x: -65,
            y: -20,
          },
          "B.W": {
            x: -65,
            y: -20,
          },
          "J.E": {
            x: -65,
            y: -20,
          },
          "H.E": {
            x: -75,
            y: -20,
          },
          "RSR.P.N": {
            x: -65,
            y: -20,
          },
          "C.W": {
            x: -65,
            y: -20,
          },
          CW: {
            x: -65,
            y: -20,
          },
          "D.W": {
            x: -65,
            y: -20,
          },
          "F.W": {
            x: -65,
            y: -20,
          },
          "G.E": {
            x: -75,
            y: -25,
          },
          "F.E": {
            x: -65,
            y: -20,
          },
          "I.E": {
            x: -65,
            y: -20,
          },
          "RWRK.DRP": {
            x: -65,
            y: -20,
          },
          "D.GLD": {
            x: -65,
            y: -20,
          },
          L1: {
            x: -65,
            y: -20,
          },
          L3: {
            x: -65,
            y: -20,
          },
          "D.N": {
            x: -45,
            y: -10,
          },
          "E.E": {
            x: -85,
            y: -30,
          },
          NOR: {
            x: -65,
            y: -20,
          },
          "K.E": {
            x: -65,
            y: -20,
          },
          OTHERS: {
            x: -75,
            y: -15,
          },
        },
      },
    },
    OKC: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: 15000,
          endX: 583000,
          endY: 191000,
        },
        groupOffset: {
          x: -165,
          y: 45,
        },
        eachItemOffset: {
          L1: {
            x: 0,
            y: 0,
          },
          L2: {
            x: -10,
            y: 80,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 195,
            y: 55,
          },
          destination: {
            x: 180,
            y: 55,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 195,
            y: 60,
          },
          HNDOFF: {
            x: 195,
            y: 60,
          },
          "G.W": {
            x: 195,
            y: 60,
          },
          "H.W": {
            x: 195,
            y: 60,
          },
          "C.E": {
            x: 195,
            y: 60,
          },
          "J.W": {
            x: 195,
            y: 60,
          },
          SSR: {
            x: 195,
            y: 60,
          },
          "E.W": {
            x: 195,
            y: 60,
          },
          STR: {
            x: 195,
            y: 60,
          },
          SOR: {
            x: 195,
            y: 60,
          },
          "I.W": {
            x: 195,
            y: 60,
          },
          BATT: {
            x: 195,
            y: 60,
          },
          "A.E": {
            x: 195,
            y: 60,
          },
          "RDR.K": {
            x: 195,
            y: 60,
          },
          "A.W": {
            x: 195,
            y: 60,
          },
          "B.E": {
            x: 195,
            y: 60,
          },
          NSR: {
            x: 195,
            y: 60,
          },
          "D.E": {
            x: 195,
            y: 60,
          },
          "C.W.GLD": {
            x: 195,
            y: 60,
          },
          "SR.B.E": {
            x: 195,
            y: 60,
          },
          "B.W": {
            x: 195,
            y: 60,
          },
          "J.E": {
            x: 195,
            y: 60,
          },
          "H.E": {
            x: 195,
            y: 60,
          },
          "RSR.P.N": {
            x: 195,
            y: 60,
          },
          "C.W": {
            x: 195,
            y: 60,
          },
          CW: {
            x: 195,
            y: 60,
          },
          "D.W": {
            x: 195,
            y: 60,
          },
          "F.W": {
            x: 195,
            y: 60,
          },
          "G.E": {
            x: 195,
            y: 60,
          },
          "F.E": {
            x: 195,
            y: 60,
          },
          "I.E": {
            x: 195,
            y: 60,
          },
          "RWRK.DRP": {
            x: 195,
            y: 60,
          },
          "D.GLD": {
            x: 195,
            y: 60,
          },
          L1: {
            x: 195,
            y: 60,
          },
          L3: {
            x: 195,
            y: 60,
          },
          "D.N": {
            x: 195,
            y: 60,
          },
          "E.E": {
            x: 195,
            y: 60,
          },
          NOR: {
            x: 195,
            y: 60,
          },
          "K.E": {
            x: 195,
            y: 60,
          },
          OTHERS: {
            x: 180,
            y: 60,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 46000,
          startY: 15000,
          endX: 389000,
          endY: 191000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 160,
          y: -53,
        },
        position: "UP",
      },
    },
    MXC: {
      productionLines: {
        bounds: {
          startX: 50000,
          startY: -42000,
          endX: 583000,
          endY: 191000,
        },
        groupOffset: {
          x: -350,
          y: -200,
        },
        eachItemOffset: {
          L1: {
            x: -5,
            y: 40,
          },
          L2: {
            x: -5,
            y: 0,
          },
        },
      },
      destinationPointers: {
        bounds: {
          startX: 88000,
          startY: -42500,
          endX: -292000,
          endY: -450000,
        },
        coordinateCorrection: {
          source: {
            x: 16,
            y: 72,
          },
          destination: {
            x: 0,
            y: 65,
          },
        },
        destinationCorrection: {
          STAGE: {
            x: -90,
            y: -30,
          },
          LINES: {
            x: -90,
            y: -30,
          },
          TSL: {
            x: 16,
            y: 80,
          },
          HNDOFF: {
            x: 16,
            y: 80,
          },
          "G.W": {
            x: 16,
            y: 80,
          },
          "H.W": {
            x: 16,
            y: 80,
          },
          "C.E": {
            x: 16,
            y: 80,
          },
          "J.W": {
            x: 16,
            y: 80,
          },
          SSR: {
            x: 16,
            y: 80,
          },
          "E.W": {
            x: 16,
            y: 80,
          },
          STR: {
            x: 16,
            y: 80,
          },
          SOR: {
            x: 16,
            y: 80,
          },
          "I.W": {
            x: 16,
            y: 80,
          },
          BATT: {
            x: 16,
            y: 80,
          },
          "A.E": {
            x: 16,
            y: 80,
          },
          "RDR.K": {
            x: 16,
            y: 80,
          },
          "A.W": {
            x: 16,
            y: 80,
          },
          "B.E": {
            x: 16,
            y: 80,
          },
          NSR: {
            x: 16,
            y: 80,
          },
          "D.E": {
            x: 16,
            y: 80,
          },
          "C.W.GLD": {
            x: 16,
            y: 80,
          },
          "SR.B.E": {
            x: 16,
            y: 80,
          },
          "B.W": {
            x: 16,
            y: 80,
          },
          "J.E": {
            x: 16,
            y: 80,
          },
          "H.E": {
            x: 16,
            y: 80,
          },
          "RSR.P.N": {
            x: 16,
            y: 80,
          },
          "C.W": {
            x: 20,
            y: 80,
          },
          CW: {
            x: 20,
            y: 80,
          },
          "D.W": {
            x: 16,
            y: 80,
          },
          "F.W": {
            x: 16,
            y: 80,
          },
          "G.E": {
            x: 16,
            y: 80,
          },
          "F.E": {
            x: 16,
            y: 80,
          },
          "I.E": {
            x: 16,
            y: 80,
          },
          "RWRK.DRP": {
            x: 16,
            y: 80,
          },
          "D.GLD": {
            x: 16,
            y: 80,
          },
          L1: {
            x: 16,
            y: 80,
          },
          L3: {
            x: 16,
            y: 80,
          },
          "D.N": {
            x: 16,
            y: 80,
          },
          "E.E": {
            x: 16,
            y: 80,
          },
          NOR: {
            x: 16,
            y: 80,
          },
          "K.E": {
            x: 16,
            y: 80,
          },
          OTHERS: {
            x: 16,
            y: 80,
          },
        },
      },
      dockDoors: {
        bounds: {
          startX: 44500,
          startY: -42000,
          endX: 368000,
          endY: 191000,
        },
        marker: {
          size: [15, 75],
        },
        groupOffset: {
          x: 130,
          y: 175,
        },
        position: "UP",
      },
    },
  };

  //Get the plant form external file

  let currentPlant = plantsConfig[getLocalStorageItem("locationCode")];
  if (currentPlant.hasOwnProperty(objectRequired))
    return currentPlant[objectRequired]; // Return the corresponding property
  else return currentPlant;
};

export const getPlantMapImageUrl = (config, locationCode, imageType) => {
  const {
    AZURE_BLOB_BASE_URL,
    WAREHOUSE_CONTAINER_URI,
    WAREHOUSE_REF_CONTAINER_URI,
    YARD_CONTAINER_URI,
    YARD_REF_CONTAINER_URI,
  } = config;
  let baseUrl = `${AZURE_BLOB_BASE_URL}`;
  if (locationCode && imageType) {
    switch (imageType) {
      case "WAREHOUSE_MAP":
        baseUrl = baseUrl.concat(WAREHOUSE_CONTAINER_URI);
        break;
      case "WAREHOUSE_MAP_REF":
        baseUrl = baseUrl.concat(WAREHOUSE_REF_CONTAINER_URI);
        break;
      case "YARD_MAP":
        baseUrl = baseUrl.concat(YARD_CONTAINER_URI);
        break;
      case "YARD_MAP_REF":
        baseUrl = baseUrl.concat(YARD_REF_CONTAINER_URI);
        break;
    }
    return baseUrl.concat(`${locationCode}.png`);
  } else {
    return null;
  }
};

export const getUserAuthPayload = (userData) => {
  if (userData) {
    let payload = {};
    const { oid, name, email } = userData;
    Object.assign(payload, userDataObject);
    payload.mail = email;
    payload.displayName = name;
    payload.id = oid;
    return payload;
  }
};

export const setBlockStorageSize = (blockSize) => {
  const { blockWidth, blockHeight, singleStackWidth, singleStackHeight } =
    blockSize;
  //Get the Root of the DOM
  let root = document.querySelector(":root");
  let rootStyles = getComputedStyle(root);
  //Set Block Storage Height and Width
  root.style.setProperty("--block-storage-width", blockWidth);
  root.style.setProperty("--block-storage-height", blockHeight);

  //Set Block Single Stack Height and Width
  root.style.setProperty(
    "--block-single-stack-storage-width",
    singleStackWidth
  );
  root.style.setProperty(
    "--block-single-stack-storage-height",
    singleStackHeight
  );

  //Set Block Single Stack Start Margin
};

export const setRackStorageSize = (rackSize) => {
  const { rackWidth, rackHeight } = rackSize;
  //Get the Root of the DOM
  let root = document.querySelector(":root");
  let rootStyles = getComputedStyle(root);
  //Set Block Storage Height and Width
  root.style.setProperty("--rack-storage-width", rackWidth);
  root.style.setProperty("--rack-storage-height", rackHeight);
};
