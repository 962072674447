const DockDoorStatus = ({
  additionalDataLoader,
  additionalDockDoorDetails,
  renderTextCardStatus,
  renderCardStatus,
}) => {
  return (
    <div className="detail-section-container column-section">
      <div className="additional-data-section-title">
        <h3>Dock Door Status</h3>
      </div>
      {additionalDataLoader ? (
        <div className="dock-spinner-wrapper">
          <h4>Fetching Dock Door Status...</h4>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="details-wrapper">
          {Object.values(additionalDockDoorDetails.dockDoorStatus).length !==
          0 ? (
            <>{renderCardStatus(additionalDockDoorDetails.dockDoorStatus)}</>
          ) : null}
          {Object.values(additionalDockDoorDetails.dockDoorStatus).length !==
          0 ? (
            <>
              {renderTextCardStatus(additionalDockDoorDetails.dockDoorStatus)}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DockDoorStatus;
