import '../App.css';
import { MsalAuth, useIsUserAuthenticated } from '../util/Auth/AzureAuth';
import logo from '../assets/images/logo.png';

const ProtectedRoute = ({msalUser, setMsalUser, children}) => {
    let isUserValid = useIsUserAuthenticated();
        return (
        isUserValid ? children : 
        <div className='loggin-container'>
            {MsalAuth(setMsalUser)}
            <img src={logo}/>
            <h3>Loading... </h3>
        </div>
    )
}

export default ProtectedRoute