import React from "react";
import './button.css';

const Button = ({icon, placeholder, onClick, btnClass, imgClass, disabled, state}) => {
    const buttonStateHandler = () => {
        if(state == 'loading') {
            return `button-container-loading`;
        } else {
            if(btnClass) {
                return `button-container-active ${btnClass}`
            }
        }
    }
    return (
        <div onClick={state == 'loading' ? null : onClick} 
            className={`button-container ${buttonStateHandler()}`}
        >
            {state == 'loading' ? 
                <>
                    <div className='spinner'></div>
                </>
            :
                <>
                    {icon ? <img className={imgClass ? imgClass : ""} src={icon} alt="icon"/> : null}
                    {placeholder}
                </>
            }
        </div>
    )
}

export default Button;