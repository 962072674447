import { useContext, useState } from "react";
import "../../../assets/css/AllocatedView/AllocatedView.css";
import arrowLeft from "../../../assets/icons/arrowLeft.png";
import expandIcon from "../../../assets/icons/expandDedault.png";
import { AppContext } from "../../../Context/Context";
import EmptyDockDoor from "./EmptyDockDoor";
import DockDoor from "./DockDoor";
import LegendPopup from "./LegendPopup";
const AllocatedView = ({ data }) => {
  const {
    allocatedView,
    setAllocatedView,
    allocatedViewLegend,
    setAllocatedViewLegend,
  } = useContext(AppContext);

  const sliceDockDoorName = (dockDoor) => {
    return dockDoor.substring(dockDoor.length - 2);
  };

  const STYLE_CONFIG_BY_STATUS = {
    A: {
      backgroundColor: "#EEC909",
      textColor: "#000000",
    },
    C: {
      backgroundColor: "#238749",
      textColor: "#ffffff",
    },
    S: {
      backgroundColor: "#BF2E3A",
      textColor: "#ffffff",
    },
    E: {
      backgroundColor: "#38AB00",
      textColor: "#ffffff",
    },
    P: {
      backgroundColor: "#E77400",
      textColor: "#ffffff",
    },
    H: {
      backgroundColor: "#01CC1F",
      textColor: "#000000",
    },
  };

  const getStylesByStatus = (status) => {
    return STYLE_CONFIG_BY_STATUS[status];
  };

  const renderDockDoor = () => {
    return data.map((item, index) => {
      const {
        dockDoor,
        trailerNumber,
        palletsRequested,
        palletsStaged,
        status,
        duration,
        checkInTime,
      } = item;

      const { backgroundColor, textColor } = { ...getStylesByStatus(status) };

      if (trailerNumber == null)
        return (
          <EmptyDockDoor key={index} dockDoor={sliceDockDoorName(dockDoor)} />
        );
      return (
        <DockDoor
          key={index}
          data={item}
          dockDoor={sliceDockDoorName(dockDoor)}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      );
    });
  };

  if (data?.length === 0) {
    return (
      <div className="loader-container">
        <div className="allocated-rm-loader">
          <h4>Loading Allocated View Data...</h4>
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="allocated-container">
      <div
        id="allocated-popup-wrapper-id"
        className="allocatedview-legend-popup-wrapper"
      >
        <div className="allocated-popup-container">
          <div className="dock-door-list">{renderDockDoor()}</div>

          {allocatedViewLegend ? <LegendPopup /> : null}
        </div>
      </div>
      {!allocatedViewLegend && data?.length > 0 ? (
        <div
          className="legend-modal"
          onClick={() => setAllocatedViewLegend((prev) => !prev)}
        >
          <div>
            <img alt=" close modal" src={expandIcon} />
          </div>
          <div>
            <span className="legend-modal-btn"> Show Legends</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AllocatedView;
