export const getDynamicApiLink = () => {
  const origin = window.location.origin;
  if (
    origin.includes("dev-lgvone") ||
    origin.includes("localhost") ||
    origin.includes("qa-lgvone")
  ) {
    return process.env.REACT_APP_DEV_POINT;
  } else if (origin.includes("uat-lgvone")) {
    return process.env.REACT_APP_UAT_POINT;
  } else if (origin.includes("lgvone.niagarawater")) {
    return process.env.REACT_APP_PROD_POINT;
  }
};

export const getSocketEndPoint = () => {
  const origin = window.location.origin;
  if (
    origin.includes("dev-lgvone") ||
    origin.includes("localhost") ||
    origin.includes("qa-lgvone")
  ) {
    return process.env.REACT_APP_ON_PREM_POINT;
  } else if (origin.includes("uat-lgvone")) {
    return process.env.REACT_APP_ON_PREM_POINT_UAT;
  } else if (origin.includes("lgvone.niagarawater")) {
    return process.env.REACT_APP_PROD_ON_PREM_POINT;
  }
};

export const plantLocations = `${getDynamicApiLink()}/plantlocations`;
export const yardLegend = `${window.location.origin}/dummyAPI/yardLegend.json`;
export const plantDetailsAPI = `${window.location.origin}/dummyAPI/plants/`;

export const lgvHistoryStatus = `${getDynamicApiLink()}/lgvtraffic`;
export const heatmapDetails = `${getDynamicApiLink()}/heatmapdetails`;

export const dockDoorBriefStatus = `${getDynamicApiLink()}/dockstatus`;

export const plantConfigUrl = `${getDynamicApiLink()}/config`;
