import { useContext, useEffect, useState } from "react";
import { Tooltip } from "antd";

const LineProductionPopup = ({lineData, index}) => {
    const [isLineProductionPopupOpen, setIsLineProductionPopupOpen] = useState(false);

    const openModal = (index) => {
        setIsLineProductionPopupOpen(!isLineProductionPopupOpen);
    }

    const closeModal = (index) => {
        setIsLineProductionPopupOpen(!isLineProductionPopupOpen);
    }

    const lineSeriesNameChecker = (lineName) => {
        if(lineName.includes('WIP')) {
            return 'W';
        } else {
            return lineName;
        }
    }

    const lgvSectionColor = (dataCount) => {
        if(dataCount >= 8) {
            return {backgroundColor:'#FF0000', color:'white'};
        } else if(dataCount >= 5) {
            return {backgroundColor:'#D7B401', color:'white'};
        } else if (dataCount == 0) {
            return {backgroundColor:'#FAFAFA', color:'black'};
        } else {
            return {backgroundColor:'#238749', color:'white'};
        }
    }

    const palletSectionColor = (dataCount) => {
        if(dataCount >= 8) {
            return {backgroundColor:'#FF0000', color:'white'};
        } else if(dataCount >= 5) {
            return {backgroundColor:'#D7B401', color:'white'};
        } else {
            return {backgroundColor:'#238749', color:'white'};
        }
    }
    return (
        <div key={index} onClick={() => openModal(index)} className='line-prod-container'>
            <div className="pallets-container">
                <div style={lgvSectionColor(lineData['LGV'])} className='prod-top-section'>
                    <div className='line-count'>{lineData['LGV']}</div>
                    <div className='line-text'>LGV</div>
                </div>
                {
                    lineData['FIP'] ? 
                    <div className='prod-middle-section fip-container'>
                        <div className='fip-section'>FIP</div>
                    </div> : null
                }
                <div style={palletSectionColor(lineData['PALLETS'])} className='prod-middle-section'>
                    <div className='pallet-count'>{lineData['PALLETS']}</div>
                    <div className='pallet-text'>Pallet</div>
                </div>
            </div>
            <div className='prod-bottom-section'>
                <div className='line-section'>
                    <div className="tool-tip">
                        {lineSeriesNameChecker(lineData['LINE_NAME'])}
                        <span className="tool-tip-text">
                            {lineData['LINE_NAME']}
                        </span>
                    </div>
                </div>
                <div className={`line-color ${lineData.STATION_1 === 1 ? 'line-color-on' : "line-color-off"}`}>1</div>
                <div className={`line-color ${lineData.STATION_2 === 1 ? 'line-color-on' : "line-color-off"}`}>2</div>
            </div>
        </div>
    );
}



export default LineProductionPopup;