import Button from "../Reusables/Button/Button";
import '../../assets/css/Greetings/greetings.css';
import niagaraLogo from '../../assets/images/logo.png';
import arrowRight from '../../assets/icons/arrowRight.svg';
import treesImage from '../../assets/images/bottomTrees.png';
import circle1 from '../../assets/images/leftCircle.png';
import circle2 from '../../assets/images/rightCircle.png';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/Context";
const Greetings = ({openLocationModal}) => {
    const {locationsData, setLocationsData} = useContext(AppContext);
    const [isLocationSelectionButtonEnabled, setIsLocationSelectionButtonEnabled] = useState(true);
    useEffect(() => {
        if(locationsData) {
            setIsLocationSelectionButtonEnabled(false);
        } else {
            setIsLocationSelectionButtonEnabled(true);
        }
    }, [locationsData])
    return (
        <div className="greetings-wrapper">
            <div className="designs bottom-trees-design">
                <img alt="trees" src={treesImage}/>
                <img alt="trees" src={treesImage}/>
                <img alt="trees" src={treesImage}/>
            </div>
            <div className="designs circle-design">
                <img id="circle1ui" alt="circle" src={circle1}/>
                <img id="circle2ui" alt="circle" src={circle2}/>
            </div>
            <div className="greetings-container">
                <img alt='niagara-logo' src={niagaraLogo}/>
                <h1 className="text">Welcome!</h1>
                <h3 className="text">To proceed, select a site location on the map</h3>
                <Button 
                    onClick={openLocationModal}             
                    btnClass='select-location-btn reverse-content' 
                    placeholder='Proceed' 
                    icon={arrowRight} 
                    state={`${isLocationSelectionButtonEnabled ? 'loading' : ''}`}
                />
                    
                {isLocationSelectionButtonEnabled ? 
                    <div className="loading-text">
                        <p>Just hang on a moment as we get the map ready for you</p>
                    </div>
                : <></>}
            </div>
        </div>
    )
}

export default Greetings;