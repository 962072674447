import "../../assets/css/LgvPopup/LgvPopup.css";
import { statusColorRenderer } from "../../util/helper/helperFunctions";
/**
 * Renders the React Leaflet icon with HTML Element
 * @param {data, status, className, isFollowing}
 * @returns HTML Element
 */
const LgvIcon = ({ data, status, className, isFollowing }) => {
  const renderIconType = (status) => {
    if (status == "white") {
      return {
        backgroundColor: "white",
        color: "black",
        fontSize: "6px",
        transform: "rotate(90deg)",
      };
    } else {
      return { backgroundColor: `${statusColorRenderer(status)}` };
    }
  };
  return (
    <span className="lgv-icon-wrapper">
      <span
        style={renderIconType(status)}
        className={`${className ? className : "lgv-icon-style"}`}
      >
        {data}
      </span>
      {isFollowing ? (
        <>
          <span id="lgv-wrapper-circle">
            <span className="lgv-wrapper-circle-outer"></span>
            <span className="lgv-wrapper-circle-inner"></span>
          </span>
        </>
      ) : null}
    </span>
  );
};

export default LgvIcon;
