import { useEffect, useState, useContext } from "react"
import '../../../assets/css/SiteSelection/SiteSelectionPopup.css';
import { AppContext } from "../../../Context/Context";
import { getLocalStorageItem, setLocalStorageItem } from "../../../util/helper/helperFunctions";

const SiteSelectionPopup = ({locationDetails, selectedLocation}) => {
    const {name, state, code, timeZone} = locationDetails;
    const [buttonState, setButtonState] = useState("site-unselected-button");

    useEffect(() => {
        if(name == getLocalStorageItem('locationSelected')) {
            setButtonState('site-selection-button')
        } else {
            setButtonState('site-unselected-button')
        }
    }, [getLocalStorageItem('locationSelected')])

    const {locationCode, setLocationCode} = useContext(AppContext);
    const [buttonText, setButtonText] = useState('Select');

    useEffect(() => {
        if(name == getLocalStorageItem('locationSelected')) {
            setButtonText('Selected');
        } else {
            setButtonText('Select');
        }
    }, [getLocalStorageItem('locationSelected'), name])

    const updateLocation = () => {
        localStorage.setItem('locationCode', JSON.stringify(code));
        setLocationCode(code);
        localStorage.setItem('locationSelected', JSON.stringify(name));
        document.getElementById('updateLocationButton').innerText = 'Selected'
        setLocalStorageItem('timeZone', timeZone);
        let origin = window.location.origin;
        window.location.replace(`${origin}/${code}`);
    }
    return(
        <div className="site-selection-container" id="siteSelectionPopupDivId">
            <div className="site-left-section">
                <p className="location">{code}</p>
                <p className="location-state">{state}</p>
            </div>
            <div className="site-right-section">
                <button disabled={buttonText == 'Selected' ? true : false} onClick={() => updateLocation()} id="updateLocationButton" className={buttonState}>
                    {buttonText}
                </button>
            </div>
        </div>
    )
}

export default SiteSelectionPopup;