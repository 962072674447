import { useContext, useEffect, useState } from "react";
import "../../../assets/css/DockDoor/dockDoorPopup.css";
import {
  apiHeadersFormatter,
  dockDoorStatusColor,
  expireAuthToken,
  findDockDoorPosition,
  setLocalStorageItem,
} from "../../../util/helper/helperFunctions";
import { AppContext } from "../../../Context/Context";
import { dockDoorBriefStatus } from "../../../util/network/apiUrl";
import { Progress } from "antd";
import {
  mockDockDoorData,
  noDataDockDetails,
} from "../../../util/helper/constants";

import check from "../../../assets/icons/check.png";
import error from "../../../assets/icons/error.png";
import minimizeButton from "../../../assets/icons/minimize.png";
import closeButton from "../../../assets/icons/close.png";
import DockDoorStatus from "./DockDoorStatus";
import { getApi } from "../../../util/network/getApi";

const DockDoorPopup = ({ dockData, dockMode, socket }) => {
  const { isMarkerPopupOpen, setIsMarkerPopupOpen, plantConfig } =
    useContext(AppContext);
  const [dockDoorBriefData, setDockDoorBriefData] = useState();
  const [dockModeLocData, setDockModeLocData] = useState();
  const [dockDataMock, setDockDataMock] = useState();
  const { selectedMarkerData, setSelectedMarkerData } = useContext(AppContext);
  const [palletsLoader, setPalletsLoader] = useState(true);
  const [additionalDataLoader, setAdditionalDataLoader] = useState(true);
  const [additionalDockDoorDetails, setAdditionalDockDoorDetails] = useState({
    dockDoorStatus: {},
    palletsInTrailer: [],
  });
  const noData = "No Data Found";
  let dockDetailsInterval;
  const [minimizeInitiated, setMinimizeInitiated] = useState(false);
  const { minimizedWebObjectsList, setMinimizedWebObjectsList } =
    useContext(AppContext);
  const { alertObject, setAlertObject } = useContext(AppContext);

  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsMarkerPopupOpen(false);
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("dock-door-wrapper-id")) {
      killTimeInterval();
      modalCloseHandler();
    }
  });

  const groupDockDoorWithPallets = (rawData) => {
    let groupedDocks = {};
    rawData.map((dock, index) => {
      if (!groupedDocks[dock.POSITION]) {
        groupedDocks[dock.POSITION] = [];
      }
      groupedDocks[dock.POSITION].push(dock);
    });
    return groupedDocks;
  };

  useEffect(() => {
    let dockDoorPosition = findDockDoorPosition(plantConfig);
    if (dockDoorPosition == "DOWN") {
      let localDockDoor = JSON.parse(JSON.stringify(dockData));
      let groupedDocks = groupDockDoorWithPallets(localDockDoor);
      setDockModeLocData(Object.values(groupedDocks).reverse());
    } else {
      let groupedDocks = groupDockDoorWithPallets(dockData);
      setDockModeLocData(groupedDocks);
    }
    setTimeout(() => {
      setPalletsLoader(false);
    }, 1500);

    return () => {
      setPalletsLoader(true);
    };
  }, []);

  useEffect(() => {
    if (selectedMarkerData) {
      let dockDoorPosition = findDockDoorPosition(plantConfig);
      if (dockDoorPosition == "DOWN") {
        let localDockDoor = JSON.parse(JSON.stringify(selectedMarkerData));
        let groupedDocks = groupDockDoorWithPallets(localDockDoor);
        setDockModeLocData(Object.values(groupedDocks).reverse());
      } else {
        let groupedDocks = groupDockDoorWithPallets(selectedMarkerData);
        setDockModeLocData(groupedDocks);
      }
      setTimeout(() => {
        setPalletsLoader(false);
      }, 1500);
    }
  }, [selectedMarkerData]);

  const killTimeInterval = () => {
    clearInterval(dockDetailsInterval);
    dockDetailsInterval = null;
  };

  useEffect(() => {
    document.body.classList.add("prevent-scroll");
    setLocalStorageItem("dockDoorTouched", dockData[0]["DOCK"]);
  }, []);

  useEffect(() => {
    if (isMarkerPopupOpen && dockData[0]["DOCK"] !== 0) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
      if (auth && locationCodeLocal) {
        const getDockDoorDetails = async () => {
          try {
            let selectedDock = dockData[0]["DOCK"];
            let dockDoorData = await getApi(
              `${dockDoorBriefStatus}/${selectedDock}?location=${locationCodeLocal}`,
              apiHeadersFormatter(auth.accessToken)
            );
            localStorage.setItem("isModalDetailExist", JSON.stringify(true));
            if (dockDoorData.status == 200) {
              if (dockDoorData.data !== "") {
                setDockDoorBriefData(dockDoorData.data);
              } else {
                setDockDoorBriefData(null);
              }
            } else {
              if (dockDoorData.response.status == 401) {
                expireAuthToken();
              }
            }
          } catch (err) {
            console.log(err);
          }
        };
        //Simple Dock door
        const initiateAdditionalDockDoorSimpleModeWebSocket = () => {
          let locationCodeLocal = JSON.parse(
            localStorage.getItem("locationCode")
          );
          if (socket && locationCodeLocal) {
            socket.on(`${locationCodeLocal}_DOCK_DETAILS_SIMPLE`, (data) => {
              if (
                data.results.length !== 0 &&
                data.results !== "Error connecting to datasource!"
              ) {
                if (!dockMode) {
                  let metaData = dockData[0];
                  let metaDataObject = {
                    lgvInside: metaData.LGV_IN_TRAILER,
                    doorOpen: metaData.DOOR_OPEN,
                    trailerAtDoor: metaData.TRAILER_AT_DOOR,
                    readyForLoading: metaData.READY_FOR_LOADING,
                    doorOperation: metaData.DOOR_IN_MANUAL,
                    airbagHold: metaData.AIRBAG_HOLD,
                    fault: metaData.FAULT,
                  };
                  setAdditionalDockDoorDetails({
                    ...additionalDockDoorDetails,
                    dockDoorStatus: metaDataObject,
                    palletsInTrailer: dockData,
                  });
                  setAdditionalDataLoader(false);
                  // setAdditionalDockDoorDetails(dockData);
                }
              }
            });
          }
        };
        //Complex Dock door
        const initiateAdditionalDockDoorComplexModeWebSocket = () => {
          let locationCodeLocal = JSON.parse(
            localStorage.getItem("locationCode")
          );
          if (socket && locationCodeLocal) {
            socket.on(`${locationCodeLocal}_DOCK_DETAILS_COMPLEX`, (data) => {
              if (
                data.results.length !== 0 &&
                data.results !== "Error connecting to datasource!"
              ) {
                if (dockMode) {
                  let metaData = dockData[0];
                  let metaDataObject = {
                    lgvInside: metaData.LGV_IN_TRAILER,
                    doorOpen: metaData.DOOR_OPEN,
                    trailerAtDoor: metaData.TRAILER_AT_DOOR,
                    readyForLoading: metaData.READY_FOR_LOADING,
                    doorOperation: metaData.DOOR_IN_MANUAL,
                    airbagHold: metaData.AIRBAG_HOLD,
                    fault: metaData.FAULT,
                  };
                  setAdditionalDockDoorDetails({
                    ...additionalDockDoorDetails,
                    dockDoorStatus: metaDataObject,
                    palletsInTrailer: dockData,
                  });
                  setAdditionalDataLoader(false);
                }
              }
            });
          }
        };

        initiateAdditionalDockDoorSimpleModeWebSocket();
        initiateAdditionalDockDoorComplexModeWebSocket();

        dockDetailsInterval = setInterval(() => {
          getDockDoorDetails();
        }, 2000);
      }
      return () => {
        clearInterval(dockDetailsInterval);
        dockDetailsInterval = null;
        socket.off(`${locationCodeLocal}_DOCK_DETAILS_SIMPLE`);
        socket.off(`${locationCodeLocal}_DOCK_DETAILS_COMPLEX`);
      };
    }
  }, [isMarkerPopupOpen]);

  const parseCurrentTimeStamp = (timestamp) => {
    if (timestamp !== "No Data Found") {
      let date = timestamp.split("T");
      let time = date[1].split("Z");
      return String(date[0].concat(time[0]));
    } else {
      return "No Data Found";
    }
  };

  const renderPallets = (value) => {
    if (value.length == 1) {
      return value.map((pallet, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: dockDoorStatusColor(
                pallet.STATUS,
                !dockMode ? "SIMPLE" : "COMPLEX"
              ),
            }}
            className={`dock-popup dock-popup-small`}
          >
            <h4>
              {pallet.SKU}
              <br />
              {pallet.STATUS}
            </h4>
          </div>
        );
      });
    } else if (value.length == 2) {
      return (
        <div className="dock dock-popup-big">
          {value.map((pallet, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: dockDoorStatusColor(
                    pallet.STATUS,
                    !dockMode ? "SIMPLE" : "COMPLEX"
                  ),
                }}
                className={`dock-popup ${
                  index == 0
                    ? "dock-popup-big-complex-left"
                    : "dock-popup-big-complex-right"
                }`}
              >
                <h4>
                  {pallet.SKU}
                  <br />
                  {pallet.STATUS}
                </h4>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderCardStatus = (dockDoorStatus) => {
    const { lgvInside, doorOpen, trailerAtDoor } = dockDoorStatus;
    return (
      <div className="status-card-status-wrapper">
        <div className="status-card">
          {lgvInside == 1 ? <img src={check} /> : <img src={error} />}
          <p>LGV Inside</p>
        </div>
        <div className="status-card">
          {doorOpen == 1 ? <img src={check} /> : <img src={error} />}
          <p>Door Open</p>
        </div>
        <div className="status-card">
          {trailerAtDoor == 1 ? <img src={check} /> : <img src={error} />}
          <p>Trailer At Door</p>
        </div>
      </div>
    );
  };

  const getTextCardValue = (label, data) => {
    if (data !== undefined) {
      switch (label) {
        case "doorOperation":
          switch (data) {
            case 1:
              return <p className="manual-text-tag">Manual</p>;
            case 0:
              return <p className="auto-text-tag">Auto</p>;
          }
          break;
        case "normal":
          switch (data) {
            case 1:
              return <p className="valid-text-tag">Yes</p>;
            case 0:
              return <p className="invalid-text-tag">No</p>;
          }
      }
    }
  };

  const renderTextCardStatus = (dockDoorStatus) => {
    const { readyForLoading, doorOperation, airbagHold, fault } =
      dockDoorStatus;
    return (
      <div className="status-card-status-wrapper">
        <div className="status-card-text">
          <p>Ready for Loading</p>
          {getTextCardValue("normal", readyForLoading)}
        </div>
        <div className="status-card-text">
          <p>Door Operation</p>
          {getTextCardValue("doorOperation", doorOperation)}
        </div>
        <div className="status-card-text">
          <p>Airbag Hold</p>
          {getTextCardValue("normal", airbagHold)}
        </div>
        <div className="status-card-text">
          <p>Fault</p>
          {getTextCardValue("normal", fault)}
        </div>
      </div>
    );
  };

  const renderTrailerInPalletsTable = (palletsInTrailer) => {
    if (palletsInTrailer.length !== 0) {
      return (
        <table className="pallets-table">
          <tr className="pallets-table-header-section">
            <th className="pallets-table-header">Position in Trailer</th>
            <th className="pallets-table-header">SKU</th>
            <th className="pallets-table-header">LPN</th>
          </tr>
          {palletsInTrailer.map((pallet, index) => {
            return (
              <tr key={index} className="pallets-table-data">
                <td className="pallets-table-row-data">
                  {pallet.POSITION !== null ? pallet.POSITION : "-"}
                </td>
                <td className="pallets-table-row-data">
                  {pallet.SKU !== null ? pallet.SKU : "-"}
                </td>
                <td className="pallets-table-row-data">
                  {pallet.LPN !== null ? pallet.LPN : "-"}
                </td>
              </tr>
            );
          })}
        </table>
      );
    }
  };

  const minimizeModalHandler = () => {
    let currentDockList = minimizedWebObjectsList.docks;
    if (currentDockList.length < 10) {
      let isDockAvailableInList = false;
      currentDockList.forEach((item) => {
        if (item[0].DOCK == dockData[0].DOCK) {
          isDockAvailableInList = true;
        }
      });
      if (isDockAvailableInList == false) {
        currentDockList.push(dockData);
        setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
          ...minimizedWebObjectsList,
          docks: currentDockList,
        }));
        setMinimizeInitiated(true);
        setTimeout(() => {
          modalCloseHandler();
        }, 450);
      } else {
        // setAlertObject({
        //   type: "warning",
        //   payload: {
        //     heading: "Dock Door Already Minimized",
        //     description: "Cannot add the Dock Door which is already in List.",
        //   },
        // });
        modalCloseHandler();
      }
    } else {
      setAlertObject({
        type: "warning",
        payload: {
          heading: "Limit reached!",
          description: "Cannot minimize more than 10 Dock Doors..",
        },
      });
    }
  };

  return (
    <div id="dock-door-wrapper-id" className="dock-door-wrapper">
      <div
        className={`dock-door-wrapper-container ${
          minimizeInitiated ? "modal-animation" : ""
        }`}
      >
        <div className="dock-door-details-section">
          <div className="dock-door-details-header">
            <div className="left-detail-title-section">
              <h3>Dock Door Details</h3>
              <div className="modal-action-buttons">
                <div
                  onClick={() => minimizeModalHandler()}
                  className="modal-action-button-wrapper"
                >
                  <img
                    className="modal-action-button-no-height"
                    src={minimizeButton}
                  />
                </div>
                <div
                  onClick={() => modalCloseHandler()}
                  className="modal-action-button-wrapper"
                >
                  <img className="modal-action-button" src={closeButton} />
                </div>
              </div>
            </div>
          </div>

          <div className="dock-door-details-body">
            <div className="dock-door-detail-left-section">
              {dockDoorBriefData ? (
                <div className="left-detail-data-section">
                  <div className="detail-section-container">
                    <div className="detail-section">
                      <h4>Door</h4>
                      <p>
                        {dockDoorBriefData?.["DOOR"] !== ""
                          ? dockDoorBriefData?.["DOOR"]
                          : noData}
                      </p>
                    </div>
                    <div className="detail-section">
                      <h4>Shipment</h4>
                      <p>
                        {dockDoorBriefData?.["SHIPMENT"] !== ""
                          ? dockDoorBriefData?.["SHIPMENT"]
                          : noData}
                      </p>
                    </div>
                    <div className="detail-section">
                      <h4>Snapshot</h4>
                      <p>
                        {dockDoorBriefData["CURRENT_DATE_TIME"] !== ""
                          ? parseCurrentTimeStamp(
                              dockDoorBriefData?.["CURRENT_DATE_TIME"]
                            )
                          : noData}
                      </p>
                    </div>
                  </div>

                  <div className="detail-section-container">
                    <div className="detail-section">
                      <h4>Type</h4>
                      <p>
                        {dockDoorBriefData?.["TYPE"] !== ""
                          ? dockDoorBriefData?.["TYPE"]
                          : noData}
                      </p>
                    </div>
                    <div className="detail-section">
                      <h4>Status</h4>
                      <p>
                        {dockDoorBriefData?.["STATUS"] !== ""
                          ? dockDoorBriefData?.["STATUS"]
                          : noData}
                      </p>
                    </div>
                    <div className="detail-section">
                      <h4>RemainPall</h4>
                      <p>{dockDoorBriefData?.["REMAINPALL"]}</p>
                    </div>
                    <div className="detail-section">
                      <h4>Load Time</h4>
                      <p>{dockDoorBriefData?.["LOADTIME"]}</p>
                    </div>
                    <div className="detail-section">
                      <h4>Process Time</h4>
                      <p>{dockDoorBriefData?.["PROCESSTIME"]}</p>
                    </div>
                    <div className="detail-section">
                      <h4>Progress</h4>
                      <Progress
                        percent={
                          dockDoorBriefData?.["PROGRESS"]
                            ? dockDoorBriefData?.["PROGRESS"]
                            : 0
                        }
                      />
                    </div>
                    <div className="detail-section">
                      <h4>Allocated</h4>
                      <p>{dockDoorBriefData["ALLOCATED"]}</p>
                    </div>
                    <div className="detail-section">
                      <h4>Released</h4>
                      <p>{dockDoorBriefData["RELEASED"]}</p>
                    </div>
                    <div className="detail-section">
                      <h4>Active</h4>
                      <p>{dockDoorBriefData?.["ACTIVE"]}</p>
                    </div>
                  </div>
                  {/* Dock door Status */}
                  <DockDoorStatus
                    additionalDataLoader={additionalDataLoader}
                    additionalDockDoorDetails={additionalDockDoorDetails}
                    renderTextCardStatus={renderTextCardStatus}
                    renderCardStatus={renderCardStatus}
                  />
                  {/* Pallets in Trailer */}
                  <div className="detail-section-container">
                    <div className="additional-data-section-title extra-bottom-margin">
                      <h3>Pallets in Trailer</h3>
                    </div>
                    {additionalDataLoader ? (
                      <div className="dock-spinner-wrapper">
                        <h4>Fetching Pallets in Trailer...</h4>
                        <div className="spinner"></div>
                      </div>
                    ) : (
                      <>
                        {additionalDockDoorDetails.palletsInTrailer.length !==
                        0 ? (
                          <div className="details-wrapper details-wrapper-scroller">
                            {renderTrailerInPalletsTable(
                              additionalDockDoorDetails.palletsInTrailer
                            )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              ) : null}
              {dockDoorBriefData == undefined ? (
                <div className="dock-door-brief-loader">
                  {dockDoorBriefData === null ? (
                    <>
                      {/* <h4>No Data Available!</h4> */}
                      <DockDoorStatus
                        additionalDataLoader={additionalDataLoader}
                        additionalDockDoorDetails={additionalDockDoorDetails}
                        renderTextCardStatus={renderTextCardStatus}
                        renderCardStatus={renderCardStatus}
                      />
                    </>
                  ) : (
                    <>
                      <h4>Fetching Dock Data...</h4>
                      <div className="spinner"></div>
                    </>
                  )}
                </div>
              ) : null}
            </div>

            <div className="dock-door-detail-right-section">
              <div className="dock-door-popup">
                <div className="dock-door-popup-container">
                  {!palletsLoader ? (
                    <>
                      <div className="dock-popup-top-section">
                        {dockModeLocData[0]
                          ? dockModeLocData[0][0]["DOCK"] &&
                            dockModeLocData[0][0]["DOCK"].split(".")[1]
                          : dockModeLocData[1] &&
                            dockModeLocData[1][0]["DOCK"] &&
                            dockModeLocData[1][0]["DOCK"].split(".")[1]}
                      </div>
                      <div className="dock-popup-bottom-section">
                        {dockModeLocData &&
                          Object.values(dockModeLocData).map((value, index) => {
                            return renderPallets(value);
                          })}
                      </div>
                    </>
                  ) : (
                    <div className="rm-loader">
                      <h4>Loading...</h4>
                      <div className="spinner"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DockDoorPopup;
