import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/Context";
import { setLocalStorageItem } from "../../../util/helper/helperFunctions";
import "../../../assets/css/LineProduction/lineProduction.css";
import minimizeButton from "../../../assets/icons/minimize.png";
import closeButton from "../../../assets/icons/close.png";

const LineProductionShowModal = ({ lineData }) => {
  const { isMarkerPopupOpen, setIsMarkerPopupOpen } = useContext(AppContext);
  const [productionData, setProductionData] = useState(lineData);
  const { lineProductionData, setLineProductionData } = useContext(AppContext);
  const { selectedMarkerData, setSelectedMarkerData } = useContext(AppContext);

  const [minimizeInitiated, setMinimizeInitiated] = useState(false);
  const { minimizedWebObjectsList, setMinimizedWebObjectsList } =
    useContext(AppContext);
  const { alertObject, setAlertObject } = useContext(AppContext);

  useEffect(() => {
    if (lineProductionData && lineProductionData.length !== 0) {
      let currentProductionData = lineProductionData.find(
        (currentLine) => currentLine.LINE_NAME == lineData.LINE_NAME
      );
      setProductionData(currentProductionData);
    }
  }, [lineProductionData]);

  useEffect(() => {
    if (selectedMarkerData) {
      setProductionData(selectedMarkerData);
    }
  }, [selectedMarkerData]);

  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsMarkerPopupOpen(false);
  };

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("line-prod-popup-wrapper-id")) {
      modalCloseHandler();
    }
  });

  useEffect(() => {
    document.body.classList.add("prevent-scroll");
    setLocalStorageItem("productionLineTouched", productionData.LINE_NAME);
  }, []);

  const minimizeModalHandler = () => {
    let currentProductionList = minimizedWebObjectsList.productionLine;
    if (currentProductionList.length < 10) {
      let isProductionLineAvailableInList = false;
      currentProductionList.forEach((item) => {
        if (item.LINE_NAME == lineData.LINE_NAME) {
          isProductionLineAvailableInList = true;
        }
      });
      if (isProductionLineAvailableInList == false) {
        currentProductionList.push(lineData);
        setMinimizedWebObjectsList((minimizedWebObjectsList) => ({
          ...minimizedWebObjectsList,
          productionLine: currentProductionList,
        }));
        setMinimizeInitiated(true);
        setTimeout(() => {
          modalCloseHandler();
        }, 450);
      } else {
        modalCloseHandler();
      }
    } else {
      setAlertObject({
        type: "warning",
        payload: {
          heading: "Limit reached!",
          description: "Cannot minimize more than 10 Production Line..",
        },
      });
    }
  };

  return (
    <div id="line-prod-popup-wrapper-id" className="line-prod-popup-wrapper">
      <div
        className={`line-prod-popup-container ${
          minimizeInitiated ? "modal-animation" : ""
        }`}
      >
        <div className="prod-popup-top-section">
          <h3>{lineData["LINE_NAME"]} Production</h3>
          <div className="modal-action-buttons">
            <div
              onClick={() => minimizeModalHandler()}
              className="modal-action-button-wrapper"
            >
              <img
                className="prod-modal-action-button-no-height"
                src={minimizeButton}
              />
            </div>
            <div
              onClick={() => modalCloseHandler()}
              className="modal-action-button-wrapper"
            >
              <img className="modal-action-button" src={closeButton} />
            </div>
          </div>
        </div>
        <div className="prod-popup-bottom-section">
          <div className="bottom-item-container">
            <p className="prod-title">Job Number</p>
            <p className="prod-item-value">
              {lineData["JOB_NUMBER"] !== null ? lineData["JOB_NUMBER"] : "-"}
            </p>
          </div>
          <div className="bottom-item-container">
            <p className="prod-title">Item/SKU</p>
            <p className="prod-item-value">
              {lineData["ITEM"] !== null ? lineData["ITEM"] : "-"}
            </p>
          </div>
          <div className="bottom-item-container">
            <p className="prod-title">Cases Produced</p>
            <p className="prod-item-value">
              {lineData["CASES_PRODUCED"] !== null
                ? lineData["CASES_PRODUCED"]
                : null}
            </p>
          </div>
          <div className="bottom-item-container">
            <p className="prod-title">Cases Remaining</p>
            <p className="prod-item-value">
              {lineData["CASES_REMAINING"] !== null
                ? lineData["CASES_REMAINING"]
                : null}
            </p>
          </div>
          <div className="bottom-item-container">
            <p className="prod-title">Cases Scheduled</p>
            <p className="prod-item-value">
              {lineData["CASES_SCHEDULED"] !== null
                ? lineData["CASES_SCHEDULED"]
                : null}
            </p>
          </div>
          <div className="bottom-item-container">
            {lineData.STATION_1 === 0 && lineData.STATION_2 === 0 ? (
              <p className="prod-title">{`No Position Calling`}</p>
            ) : (
              <p className="prod-title">
                Position
                <span> </span>
                {lineData.STATION_1 === 1 ? 1 : ""}
                {lineData.STATION_1 === 1 && lineData.STATION_2 === 1 ? (
                  <span>,</span>
                ) : (
                  ""
                )}
                <span> </span>
                {lineData.STATION_2 === 1 ? 2 : ""}
                <span> </span>
                Calling
              </p>
            )}
            <p className="prod-item-value"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineProductionShowModal;
