import React, { useContext, useEffect, useState } from "react";
import '../../assets/css/Navbar/Navbar.css';

import logo from '../../assets/images/logo.png';
import Timer from '../../assets/icons/timer.png';
import Arrow from '../../assets/icons/arrow.png';
import Heat from '../../assets/icons/heat.png';

import Location from "../Reusables/Location/Location";
import Button from "../Reusables/Button/Button";
import TimeRangePopup from "../Modal/TimeRangePopup/TimeRangePopup";
import { AppContext } from "../../Context/Context";
import heatMapIcon from '../../assets/icons/fire.png';
import { reloadApp } from "../../util/helper/helperFunctions";

const Navbar = ({siteLocations}) => {
    const {timeRangePopup, setTimeRangePopup} = useContext(AppContext);
    const {timeMachineModeIsEnabled, setTimeMachineModeIsEnabled} = useContext(AppContext);
	const {additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen} = useContext(AppContext);
	const [location, setLocation] = useState("");
    const [isHeatmapModeEnabled, setIsHeatmapModeEnabled] = useState(false);

    useEffect(() => {
        let selectedLocation = localStorage.getItem('locationSelected');
        if(selectedLocation) {
            setLocation(selectedLocation);
        }
    },[localStorage.getItem('locationSelected')]);

    const {locationModal, setLocationModal} = useContext(AppContext);

    const openTimeMachineRange = () => {
        setTimeRangePopup(!timeRangePopup);
        localStorage.setItem('timeRangeType', JSON.stringify('timeMachine'));
    }

    const openHeatMapRange = () => {
        setTimeRangePopup(!timeRangePopup);
        localStorage.setItem('timeRangeType', JSON.stringify('heatmap'));
    }

    const openAdditionalDashboard = () => {
        setAdditionalDashboardModalIsOpen(!additionalDashboardModalIsOpen);
        document.body.classList.add('prevent-scroll');
    }

    return (<div className="navbar-container">
            <div className="left-section">
                <div className="logo">
                    <a href='/'><img src={logo} alt="Niagara LGV One"/></a>
                </div>
                <div className='location'>
                    <Location allLocations={siteLocations} location={location} onClick={() => setLocationModal(true)}/>
                </div>
            </div>
            <div className="right-section">
                <Button btnClass={timeMachineModeIsEnabled ? 'time-machine-selected' : ''} icon={Timer} onClick={openTimeMachineRange} placeholder='Time Machine'></Button>
                {!timeMachineModeIsEnabled ?
                    <>
                        <Button 
                            btnClass={isHeatmapModeEnabled ? 'heat-map-selected' : ''}
                            imgClass='heatmap-icon'
                            icon={heatMapIcon} 
                            onClick={openHeatMapRange} 
                            placeholder='Heatmap'>
                        </Button>
                        <Button 
                            onClick={() => openAdditionalDashboard()}
                            btnClass='reverse-content' 
                            imgClass='reverse-content-img'
                            placeholder='Additional Dashboards' 
                            icon={Arrow}
                        />
                    </>
                : null}
            </div>
            <TimeRangePopup/>
        </div>)
}

export default Navbar;